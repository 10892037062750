import {ClientProperty, ClientPropertyKey, DropdownOption, ValueTypes} from "@medispend/common/src/types";
import {clientHasAnyProperty, userHasRole} from "@medispend/common/src/utils";
import {RecordStatus, User} from "../types";
import {USER_ROLE} from "./user";
import {formatTextWithDate, getRolesFromUserData, getTranslationText} from "@medispend/em-common/src/utils";
import {Translation} from "../state/thunky/types";
import {TRANSLATION_KEYS} from "./translations";

export const getRecordsStatus = (languageTranslations: Translation = null): Record<string, RecordStatus>=> {
    return {
        DRAFT: {name: "DRAFT",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_RECORD_STATUS_DRAFT, languageTranslations, "Draft")},
        RELEASED: {name: "RELEASED",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_RECORD_STATUS_RELEASED, languageTranslations, "Released")},
        IN_REVIEW: {name: "IN_REVIEW",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_RECORD_STATUS_IN_REVIEW, languageTranslations, "In-Review")},
        SENT_TO_CONTRACT_SYSTEM: {name: "SENT_TO_CONTRACT_SYSTEM",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_RECORD_STATUS_SENT_TO_CONTRACT_SYSTEM, languageTranslations, "Sent to Contract System")},
        CONDITIONALLY_APPROVED: {name: "CONDITIONALLY_APPROVED",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_STATUS_CONDITIONALLY_APPROVED, languageTranslations, "Conditionally Approved")}
    };
};

export const getRecordAdditionalStatus = (languageTranslations: Translation = null): Record<string, RecordStatus>=> {
    return {
        CONTRACT_PENDING: {name: "CONTRACT_PENDING", uiLabel:
                getTranslationText(TRANSLATION_KEYS.DASHBOARD_ADDITIONAL_STATUS_CONTRACT_PENDING, languageTranslations, "Contract Pending")},
        CONTRACT_OPEN: {name: "CONTRACT_OPEN",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ADDITIONAL_STATUS_CONTRACT_OPEN, languageTranslations, "Contract Open")},
        CONTRACT_IN_REVIEW: {name: "CONTRACT_IN_REVIEW",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ADDITIONAL_STATUS_CONTRACT_IN_REVIEW, languageTranslations, "Contract In-Review")}
    };
};

export const PRODUCTS = {
    TS: {
        name: "Transparency Solution",
        link: ""
    },
    CFF: {
        name: "Custom Form Fields",
        link: ""
    },
    BR: {
        name: "Business Rules",
        link: ""
    },
    GLOBAL_ADMIN: {
        name: "Global Admin",
        link: ""
    }
};

export const getWorkflowTypeAlertsLib = (languageTranslations: Translation) => {
    return {
        NEEDS_ASSESSMENT: {uiName: "needs", getReason: (date: string): string =>
            formatTextWithDate(getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_EXPIRING_ON, languageTranslations, "Expiring on"), date)
        },
        HCPQ: {uiName: "qualifications", getReason: (date: string): string =>
            getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_EXPIRING_ON, languageTranslations, "Expiring on") + " " + date},
        CONTRACTS: {uiName: "contracts", getReason: (date: string): string =>
            formatTextWithDate(getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_EXPIRING_ON, languageTranslations, "Expiring on"), date)
        },
        ENGAGEMENTS: {uiName: "engagements", getReason: (date: string): string =>
            formatTextWithDate(getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_STARTING_ON, languageTranslations, "Starting on"), date)
        },
        ENGAGEMENT_HCP: {
            uiName: "engagements",
            getReason: (date: string): string => `${formatTextWithDate(getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_ENGAGEMENT_COMPLETED_ON, languageTranslations,
                "Engagement Completed on"), date)}, ${getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_NO_AR, languageTranslations, "No AR")}`
        },
        ACTIVITY_REPORTS: {uiName: "payments", getReason: (): string => ""},
        CREDIT_MEMO: {uiName: "payments", getReason: (): string => ""}
    };
};

export const WORKFLOW_TYPE_LIB = {
    NEEDS_ASSESSMENT: {uiName: "needs"},
    HCPQ: {uiName: "qualifications"},
    CONTRACTS: {uiName: "contracts"},
    ENGAGEMENTS: {uiName: "engagements"},
    ENGAGEMENT_HCP: {uiName: "engagements"},
    ACTIVITY_REPORTS: {uiName: "payments"},
    CREDIT_MEMO: {uiName: "payments"}
};

export const getSortOptions = (languageTranslations: Translation):DropdownOption[] => {
    return [
        {value: "asc", uiLabel: getTranslationText(TRANSLATION_KEYS.SORTING_ASCENDING, languageTranslations, "Ascending"), name: "sorting"},
        {value: "desc", uiLabel: getTranslationText(TRANSLATION_KEYS.SORTING_DESCENDING, languageTranslations, "Descending"), name: "sorting"}
    ];
};

export const getSearchOptions = (languageTranslations: Translation): DropdownOption[] => {
    return [
        {
            value: "searchKeyWords",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_TEXT_SEARCH, languageTranslations, "Text"),
            name: "search",
            valueType: ValueTypes.text
        },
        {
            value: "recordId",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ID_SEARCH, languageTranslations, "ID"),
            name: "search",
            valueType: ValueTypes.number}
    ];
};

export const getSearchParams = (languageTranslations: Translation) => {
    return {
        title: "Search By",
        name: "search",
        options: getSearchOptions(languageTranslations),
        defaultValue: {value: "searchKeyWords", uiLabel: "Text", name: "search", valueType: ValueTypes.text}
    }
};

export const getWorkflowTypeOptions = (user: User, clientProperties: ClientProperty[], languageTranslations: Translation): DropdownOption[] => {
    const userRoles = getRolesFromUserData(user);
    const workflowTypeOptions = [
        {id: 1, value: "NEEDS_ASSESSMENT",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_TYPE_EXPIRING_NEEDS_ASSESSMENT, languageTranslations, "Expiring Needs Assessments"),
            name: "workflowType", isActive: false,
            isVisible: userHasRole(USER_ROLE.NEEDS_ASSESSMENT_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.NEEDS_ASSESSMENT_ENABLED, propertyValue: "true"})},
        {id: 2, value: "HCPQ",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_TYPE_EXPIRING_HCPQ_QUALIFICATIONS, languageTranslations, "Expiring HCP Qualifications"),
            name: "workflowType", isActive: false,
            isVisible: userHasRole(USER_ROLE.HCPQ_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.HCPQ_ENABLED, propertyValue: "true"})},
        {id: 3, value: "CONTRACTS",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_TYPE_EXPIRING_CONTRACTS, languageTranslations, "Expiring Contracts"),
            name: "workflowType", isActive: false,
            isVisible: userHasRole(USER_ROLE.CONTRACTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.CONTRACTS_ENABLED, propertyValue: "true"})},
        {id: 4, value: "ENGAGEMENTS",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_TYPE_UPCOMING_ENGAGEMENTS, languageTranslations, "Upcoming Engagements"),
            name: "workflowType", isActive: false,
            isVisible: userHasRole(USER_ROLE.ENGAGEMENTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ENGAGEMENTS_ENABLED, propertyValue: "true"})},
        {id: 5, value: "ENGAGEMENT_HCP",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_ALERTS_TYPE_AGING_ENGAGED_HCPS, languageTranslations, "Aging Engaged HCPs"),
            name: "workflowType", isActive: false,
            isVisible: userHasRole(USER_ROLE.ENGAGEMENTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ENGAGEMENTS_ENABLED, propertyValue: "true"})}
    ];

    return workflowTypeOptions.filter(option => option.isVisible);
};

export const getModulesOptions = (user: User, clientProperties: ClientProperty[], languageTranslations: Translation): DropdownOption[] => {
    const userRoles = getRolesFromUserData(user);
    const moduleOptions = [
        {id: 1, value: "NEEDS_ASSESSMENT",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_NEEDS_ASSESSMENTS, languageTranslations, "Needs Assessments"),
            name: "Module", isActive: false,
            isVisible: userHasRole(USER_ROLE.NEEDS_ASSESSMENT_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.NEEDS_ASSESSMENT_ENABLED, propertyValue: "true"})},
        {id: 2, value: "HCPQ",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_HCP, languageTranslations, "HCP/HCO Qualifications"),
            name: "Module", isActive: false,
            isVisible: userHasRole(USER_ROLE.HCPQ_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.HCPQ_ENABLED, propertyValue: "true"})},
        {id: 3, value: "CONTRACTS",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_CONTRACTS, languageTranslations, "Contracts"),
            name: "Module", isActive: false,
            isVisible: userHasRole(USER_ROLE.CONTRACTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.CONTRACTS_ENABLED, propertyValue: "true"})},
        {id: 4, value: "ENGAGEMENTS",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_ENGAGEMENTS, languageTranslations, "Engagements"),
            name: "Module", isActive: false,
            isVisible: userHasRole(USER_ROLE.ENGAGEMENTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ENGAGEMENTS_ENABLED, propertyValue: "true"})},
        {id: 5, value: "ENGAGEMENT_HCP",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_ENGAGEMENTS_HCP, languageTranslations, "Engagements HCP"),
            name: "Module", isActive: false,
            isVisible: userHasRole(USER_ROLE.ENGAGEMENTS_READ, userRoles) && clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ENGAGEMENTS_ENABLED, propertyValue: "true"})},
        {id: 6, value: "ACTIVITY_REPORTS",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_ACTIVITY_AND_PAYMENTS, languageTranslations, "Activity & Payments"),
            name: "Module", isActive: false,
            isVisible: clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ACTIVITY_REPORTS_ENABLED, propertyValue: "true"}) && userHasRole(USER_ROLE.ACTIVITY_REPORTS_READ, userRoles)
        },
        {id: 7, value: "CREDIT_MEMO",
            uiLabel: getTranslationText(TRANSLATION_KEYS.DASHBOARD_MODULE_CREDIT_MEMO, languageTranslations, "Credit Memo"),
            name: "Module", isActive: false,
            isVisible: clientHasAnyProperty(clientProperties, {propertyKey: ClientPropertyKey.ACTIVITY_REPORTS_CREDIT_MEMO_ENABLED, propertyValue: "true"}) && userHasRole(USER_ROLE.ACTIVITY_REPORTS_READ, userRoles)
        }
    ];

    return moduleOptions.filter(option => option.isVisible);
};

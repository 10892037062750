import React, {FunctionComponent, PropsWithChildren, ReactChildren} from "react";
import classNames from "classnames";

import {MSIcon, IconTypes} from "../MSIcon";
import {LAYOUT_LOADING_CONTENT, LayoutLoadingPayload} from "./state";


export interface LayoutContentProps {
    children?: ReactChildren;
    layoutLoading?: LayoutLoadingPayload;
}

export const LayoutContent: FunctionComponent<PropsWithChildren<LayoutContentProps>> = (props) => {
    const {
        children,
        layoutLoading
    } = props;

    const loading = layoutLoading[LAYOUT_LOADING_CONTENT];

    return (
        <div className={classNames("ms-layout-content-container", {"ms-layout-has-loading": loading})}>
            {children}
            {loading && (
                <div data-testid="loading-spinner" className="ms-layout-content-loader-container">
                    <div className="ms-layout-content-loader">
                        <MSIcon icon="spinner" iconType={IconTypes.MS} />
                    </div>
                </div>
            )}
        </div>
    );
};

import React, {
    FunctionComponent,
    useState,
    useEffect,
    useMemo
} from "react";

import {ButtonSizes, ButtonColors, ButtonTypes} from "../MSButton/types";
import {SelectOption} from "./BaseSelect/types";
import {BaseSelect} from "./BaseSelect";
import {FilterValue} from "../../types";


export interface MSSelectProps {
    options: SelectOption[];
    title?: string;
    disabled?: boolean;
    placeholder?: string;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    defaultValue?: FilterValue;
    onChange?: (optionData: SelectOption) => void;
    className?: string;
    defaultTitle?: string;
    isLazy?: boolean;
}

export const MSSelect: FunctionComponent<MSSelectProps> = (props) => {
    const {
        disabled,
        options,
        title = "",
        placeholder = "",
        buttonType = ButtonTypes.default,
        variant = ButtonColors.green,
        activeVariant = null,
        size = ButtonSizes.md,
        defaultValue = null,
        onChange,
        className = "",
        defaultTitle,
        isLazy = true
    } = props;

    const DEFAULT_TITLE = defaultTitle || "All";
    const DEFAULT_OPTION: SelectOption = {uiLabel: DEFAULT_TITLE, value: null};

    const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION);

    useEffect(() => {
        const foundOption = options.find(o => o.value === defaultValue);
        setSelectedOption(foundOption || DEFAULT_OPTION);
    }, [defaultValue, options]);

    const dropdownTitle = useMemo(() => {
        const preparedTitle = title && selectedOption.uiLabel !== DEFAULT_TITLE
            ? `${title}: ${selectedOption.uiLabel}`
            : title;

        const preparedPlaceholder = placeholder && selectedOption.uiLabel !== DEFAULT_TITLE
            ? selectedOption.uiLabel
            : placeholder;

        return !title
            ? preparedPlaceholder
            : preparedTitle;
    }, [placeholder, selectedOption.uiLabel, title]);

    const filteredOptions = useMemo(() => (
        [...options].filter(option => (
            option.value !== selectedOption.value
        ))
    ), [options, selectedOption.value]);

    const handleClick = (optionData: SelectOption) => {
        setSelectedOption(optionData);
        onChange && onChange(optionData);
    };

    const activeSelectVariant = useMemo(() => (
        activeVariant || variant
    ), [activeVariant, variant]);

    return (
        <BaseSelect
            isLazy={isLazy}
            disabled={disabled}
            selectedOptions={[selectedOption]}
            activeVariant={selectedOption.uiLabel !== DEFAULT_TITLE && !placeholder
                ? activeSelectVariant
                : null}
            options={filteredOptions}
            dropdownTitle={dropdownTitle}
            buttonType={buttonType}
            variant={variant}
            size={size}
            onClick={handleClick}
            className={className}
        />
    );
};

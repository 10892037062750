import React from "react";

import {MSIcon} from "@medispend/common/src/components/MSIcon";

import "./scss/style.scss";

export interface ErrorContainerProps {
    errors: string[];
    clearError?: () => void;
}

export const ErrorContainer:React.FC<ErrorContainerProps> = ({errors, clearError = null}) => {
    return (<div className="error-container">
        { clearError && <div className="clear">
            <MSIcon icon="fas fa-times" onClick={clearError} />
        </div>}
        <ul>
            {errors.map((error, index) => <li key={`${index}-error-item`}>{error}</li>)}
        </ul>
    </div>);
};

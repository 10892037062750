import classNames from "classnames";
import React, {FunctionComponent, useState, ChangeEvent, FormEvent, useEffect} from "react";
import {Subject} from "rxjs";

import {MSIcon} from "../MSIcon";
import {ButtonColors} from "../MSButton/types";
import {MSSelect} from "../MSSelect";
import {DropdownOption, FilterParams, ValueTypes} from "../../types";


export interface TextSearchProps {
    onTextChange?: (search: string) => void
    textSearchPlaceholder?: string;
    textSearchByPlaceholder?: string
    showSearchSwitcher?: boolean;
    showSearchName?: boolean;
    searchParams?: Omit<FilterParams, "component">;
    searchType?: DropdownOption;
    onFilterChange: (filterValue: DropdownOption) => void;
    searchValue?: string;
    clearSearchWithFilters?: boolean;
}

export const TextSearch: FunctionComponent<TextSearchProps> = (props) => {
    const {
        onTextChange,
        searchParams,
        searchType,
        textSearchPlaceholder = "Type to search",
        textSearchByPlaceholder = "Search by",
        showSearchSwitcher,
        showSearchName,
        onFilterChange,
        searchValue = "",
        clearSearchWithFilters = false,
    } = props;
    const [searchText, setSearchText] = useState<string>(searchValue);
    const [inputType, setInputType] = useState<ValueTypes>(searchType?.valueType || ValueTypes.text);
    const [searchTypeValue, setSearchTypeValue] = useState<string>((searchType?.value as string) || "searchKeywords");
    const [searchPlaceholder, setSearchPlaceholder] = useState("");

    useEffect(() => {
        setSearchPlaceholder((showSearchSwitcher || showSearchName) ? `${textSearchByPlaceholder} ${searchType.uiLabel}` : textSearchPlaceholder);
    }, [searchType, setSearchTypeValue, showSearchName, showSearchSwitcher, textSearchByPlaceholder, textSearchPlaceholder]);

    // debouncing text search
    const searchSubject: Subject<ChangeEvent<HTMLElement>> = new Subject();
    const search$ = searchSubject.asObservable();
    search$
        // .pipe(debounceTime(150))
        .subscribe((event) => {
            const value = (event.target as HTMLInputElement).value || "";
            setSearchText(value);
            onTextChange && onTextChange(value);
        });

    useEffect(() => {
        if (searchValue === "" && clearSearchWithFilters) {
            setSearchText("");
        }
    }, [searchValue]);

    const handleTextSearch = (event: ChangeEvent<HTMLElement>) => searchSubject.next(event);

    // TODO: field-level search

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onFilterChange && onFilterChange({name: searchTypeValue, value: searchText});
    };

    return (
        <div className={classNames("ms-text-search", (showSearchSwitcher || showSearchName) && "ms-search-with-select")}>
            <form onSubmit={onSubmit} className="ms-text-search-form">
                <div className="input-group">
                    {showSearchSwitcher && (
                        <MSSelect
                            options={searchParams.options}
                            placeholder="Search By"
                            defaultValue={searchType.value}
                            size="md"
                            variant={ButtonColors.grey100}
                            onChange={(searchOption) => {
                                setSearchText("");
                                setInputType(searchParams.options.find(option => option.uiLabel === searchOption.uiLabel).valueType);
                                onFilterChange({name: searchTypeValue, value: ""});
                                setSearchTypeValue(searchOption.value as string);
                                setSearchPlaceholder(`${textSearchByPlaceholder} ${searchOption.uiLabel}`);
                            }}
                        />
                    )}
                    {showSearchName && (
                        <div className="ms-search-title">{searchType.name}</div>
                    )}
                    <button className="input-group-text" type="submit" data-testid="search-button">
                        <MSIcon icon="search" />
                    </button>
                    <input
                        data-testid="search-input"
                        type={inputType}
                        className="form-control"
                        placeholder={searchPlaceholder}
                        onChange={handleTextSearch}
                        value={searchText}
                    />
                    {!!searchText.length && (
                        <div className="ms-sort-clear" onClick={() => {
                            setSearchText("");
                            onFilterChange({name: searchTypeValue, value: ""});
                        }}>
                            <MSIcon icon="far fa-times" />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

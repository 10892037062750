export const getRequiredMessage = (fieldName: string): string => `${fieldName} is required`;

export const getRequiredMessagePlural = (fieldName: string): string => `${fieldName} are required`;

export const getAlphabeticMessage = (fieldName: string): string => `${fieldName} must be alphabetic`;

export const getAlphaNumericMessage = (fieldName: string): string => `${fieldName} must be alpha numeric`;

export const getAlphaNumericWithoutSpaceMessage = (fieldName: string): string => `${fieldName} must be alpha numeric without space`;

export const getNumericMessage = (fieldName: string): string => `${fieldName} must be numeric`;

export const getPositiveNumericMessage = (fieldName: string): string => `${fieldName} must be positive number`;

export const getStringMaxLengthMessage = (fieldName: string, max: number): string => `${fieldName} must be at most ${max} characters`;

export const getDecimalPlacesLengthMessage = (decimalPlaces?: number): string => `Number of characters after the decimal point should be no more than ${(decimalPlaces && decimalPlaces > 0) ? decimalPlaces : "Decimal Places"} `;

export const getIntegerPlacesLengthMessage = (intPlaces?: number): string => `Number of characters before the decimal point should be no more than ${(intPlaces && intPlaces > 0) ? intPlaces : "Integer Places"} `;

export const getUrlMessage = (fieldName: string): string => `${fieldName} is not valid`;

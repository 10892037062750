import React, {FunctionComponent} from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

export interface PageSizeProps {
    pageSizeOptions?: number[];
    pageSize?: number;
    showLabel?: string;
    rowsLabel?: string;
    onPageSizeChange: (size: number) => void;
}

/**
 * Convenient util to check if all PageSizeProps are valid
 *
 * @param pageSizeOpts {PageSizeProps}
 * @return {boolean}
 */
export const hasPageSize = (pageSizeOpts: PageSizeProps): boolean => {
    const {pageSize, onPageSizeChange} = pageSizeOpts;
    return Boolean(pageSize && onPageSizeChange);
};

export const PageSize: FunctionComponent<PageSizeProps> = (props) => {
    if (!hasPageSize(props)) {
        return null;
    }

    const {
        pageSizeOptions = [5, 10, 15, 20, 25, 35, 50, 100],
        pageSize,
        showLabel = "Show",
        rowsLabel = "rows",
        onPageSizeChange
    } = props;

    return (
        <div className="ms-table-page-size">
            {showLabel} <DropdownButton title={pageSize} variant="light">
                {pageSizeOptions.filter((size: number) => size !== pageSize).map((size: number) => (
                    <Dropdown.Item
                        key={size}
                        onClick={(): void => onPageSizeChange(size)}
                    >
                        {size}
                    </Dropdown.Item>
                ))}
            </DropdownButton> {rowsLabel}
        </div>
    );
};

export const DEFAULT_PAGE_SIZE_OPTS = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100]
};

export const DEFAULT_PAGINATION = {
    total: 0,
    pageSize: 10,
    page: 0
};

import { Version } from "../types";

export const mapActuatorJson = (json: any, appName: string): Version => {
    const gitCommit = json?.git?.commit;
    const buildAppName = json?.build?.name;

    return {
        appName: buildAppName ? buildAppName : appName,
        version: `${json?.git?.branch}-${gitCommit?.id?.abbrev}`,
        buildTime: gitCommit?.time,
        gitCommit: gitCommit?.id?.full
    };
};

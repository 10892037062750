export enum ButtonSizes {
    sm = "sm",
    md = "md",
    lg = "lg",
    wd = "wd"
}

export const ButtonColors = {
    green: "green",
    orange: "orange",
    yellow: "yellow",
    grey100: "grey-100",
    grey200: "grey-200",
    grey800: "grey-800",
    red: "red",
    white: "white"
} as const;


export enum ButtonTypes {
    default = "default",
    pill = "pill"
}

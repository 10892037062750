import React, {
    forwardRef,
    FunctionComponent,
    MouseEvent,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Scrollbars} from "react-custom-scrollbars-2";
import {Instance} from "@popperjs/core";

import {IconFolders, IconTypes, MSIcon} from "../../MSIcon";
import {MSCheckbox} from "../../MSCheckbox";
import {MSButton} from "../../MSButton";
import {ButtonColors, ButtonSizes, ButtonTypes} from "../../MSButton/types";
import {ClearButton} from "../../MSButton/ClearButton";
import {MenuTypes, SelectOption} from "./types";
import {FilterValue} from "../../../types";

import "./index.scss";
import {BaseCustomDropdownButtonProps} from "../../MSSelect/BaseSelect/types";
import { DropdownMenuProps } from "react-bootstrap/esm/DropdownMenu";

interface MSDropdownProps<T extends BaseCustomDropdownButtonProps = BaseCustomDropdownButtonProps> {
    disabled?: boolean;
    options: SelectOption[];
    selectedOptions: Array<SelectOption>;
    menuType?: keyof typeof MenuTypes;
    dropdownTitle: string | ReactNode;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    onClick?: (optionData: SelectOption) => void;
    className?: string;
    customDropdownButton?: React.ForwardRefExoticComponent<T>;
    shouldShowClear?: boolean;
    handleClearOptions?: () => void;
    showSwitcherIcon?: boolean;
    autoClose?: boolean | "outside" | "inside"
    showTypeToSearch?: boolean;
    error?: string;
    strategy?: DropdownMenuProps['popperConfig']['strategy'];
}

type EventType = MouseEvent<Element, globalThis.MouseEvent>

export const BaseSelectWithSearch: FunctionComponent<MSDropdownProps> = (props) => {
    const LAZY_LOADING_STEP = 20;
    const {
        disabled,
        options,
        dropdownTitle,
        activeVariant = null,
        selectedOptions,
        menuType = MenuTypes.list,
        buttonType = ButtonTypes.default,
        variant = ButtonColors.green,
        size = ButtonSizes.md,
        shouldShowClear = false,
        autoClose = true,
        showSwitcherIcon = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick = null,
        className = "",
        customDropdownButton = null,
        handleClearOptions = null,
        showTypeToSearch = false,
        error = "",
        strategy = 'absolute'
    } = props;
    const isMenuOpen = useRef(false);
    const clearButtonRef = useRef(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [popper, setPopper] = useState<Instance>(null);
    const activeOptions = useMemo(
        () =>
            options.filter((option) => {
                if (!option.inactive) return true;
                if (menuType !== MenuTypes.checkbox) return false;

                return !!selectedOptions?.find((selected) => selected.value === option.value);
            }),
        [options, menuType, selectedOptions]
    );
    const getActiveOption = useMemo(() => (optionValue: FilterValue) => (
        selectedOptions?.some(o => o?.value === optionValue)
    ), [selectedOptions]);

    const [visibleOptions, setVisibleOptions] = useState(activeOptions?.slice(0, LAZY_LOADING_STEP));
    const [searchValue, setSearchValue] = useState("");

    const getMoreOptions = () => {
        setVisibleOptions(activeOptions.slice(0, visibleOptions.length + LAZY_LOADING_STEP));
    };
    const resetOptions = () => {
        setSearchValue("");
        setVisibleOptions(activeOptions.slice(0, LAZY_LOADING_STEP));
    };

    const findMatchingOptions = (value: string) => {
        return activeOptions.filter(opt => opt.uiLabel.trim().toLowerCase().includes(value.trim().toLowerCase()));
    };

    const getMoreOptionsContains = (searchString: string) => {
        if (searchString) {
            const selectOptions = findMatchingOptions(searchString);
            setVisibleOptions(selectOptions.slice(0, LAZY_LOADING_STEP));
        } else {
            resetOptions();
        }
        if (popper) popper.update();
    };

    useEffect(() => {
        if(popper) popper.update();
    }, [strategy])

    useEffect(() => {
        if (showTypeToSearch && searchValue) {
            const matchingOptions = findMatchingOptions(searchValue);
            setVisibleOptions(matchingOptions);
        } else {
            setVisibleOptions(activeOptions?.slice(0, Math.max(visibleOptions.length || LAZY_LOADING_STEP, LAZY_LOADING_STEP)));
        }
    }, [activeOptions]);

    const isIconActive = useMemo(() => (
        !["grey-100", "grey-200", "grey-800", "white"].includes(variant)
            || ["grey-100", "grey-200", "grey-800", "white"].includes(activeVariant)
    ), [activeVariant, variant]);

    const SwitcherIcon = useCallback(() => <MSIcon
        icon="caret-down"
        iconType={IconTypes.MS}
        iconFolder={IconFolders.UTILS}
        className={classNames("ms-caret-down", {
            active: isIconActive,
            "is-menu-open": isMenuOpen.current
        })}
    />, [isIconActive]);

    const SelectButton = useMemo(
        () =>
            forwardRef(({onClick}: {onClick: (e: EventType) => void}, ref: Ref<HTMLDivElement>) => (
                <div ref={ref} className={className}>
                    <MSButton
                        disabled={disabled}
                        size={size}
                        type={buttonType}
                        variant={activeVariant || variant}
                        className="ms-select-button"
                        onClick={(e: EventType) => {
                            e.preventDefault();
                            e.target !== clearButtonRef?.current && onClick(e);
                        }}
                    >
                        <div className={classNames("ms-button-title", {"is-placeholder": !selectedOptions?.length})}>
                            <span className="dropdown-title">{dropdownTitle}</span>
                            {shouldShowClear && selectedOptions?.length ? (
                                <div className="control-buttons">
                                    <ClearButton
                                        onClick={(e: EventType) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleClearOptions && handleClearOptions();
                                        }}
                                        clearButtonRef={clearButtonRef}
                                    />
                                    {showSwitcherIcon && SwitcherIcon()}
                                </div>
                            ) : (
                                showSwitcherIcon && SwitcherIcon()
                            )}
                        </div>
                    </MSButton>
                </div>
            )),
        [
            disabled,
            SwitcherIcon,
            size,
            buttonType,
            activeVariant,
            variant,
            showSwitcherIcon,
            shouldShowClear,
            className,
            dropdownTitle,
            selectedOptions,
            handleClearOptions
        ]
    );

    const renderOptionItem = (option: SelectOption) => {
        if (menuType === MenuTypes.checkbox) {
            return (
                <div className="ms-select-checkbox-menu">
                    <MSCheckbox active={getActiveOption(option.value)} />
                    {option.uiLabel}
                </div>
            );
        }

        return option.uiLabel;
    };

    const handleClick = (optionData: SelectOption) => () => {
        onClick(optionData);
    };

    return (
        <div
            className={classNames("ms-select", {
                "has-error": error?.length,
                "strategy-abosulte": strategy === "absolute",
                "strategy-fixed": strategy === "fixed",
            })}
        >
            <Dropdown
                onToggle={(isOpen) => {
                    isMenuOpen.current = isOpen;
                    if(!isOpen) return;
                    if (searchInputRef.current) {
                        requestAnimationFrame(() => {
                            searchInputRef.current.focus();
                        });
                    }
                }}
                autoClose={autoClose}
            >
                <Dropdown.Toggle as={customDropdownButton || SelectButton} />

                <Dropdown.Menu
                    renderOnMount={strategy === 'absolute'}
                    popperConfig={{
                        strategy,
                        modifiers: [
                            {
                                name: "getPopperFromState",
                                enabled: true,
                                phase: "beforeWrite",
                                fn: (state) => {
                                    if (!popper) setPopper(state.instance);
                                },
                            },
                        ],
                    }}
                >
                    {showTypeToSearch && (
                        <div className="ms-select ms-dropdown">
                            <InputGroup>
                                <InputGroup.Text>
                                    <MSIcon icon="fas fa-search ms-search-icon" />
                                </InputGroup.Text>
                                <Form.Control
                                    className="ms-input"
                                    ref={searchInputRef}
                                    type="text"
                                    onChange={(e) => {
                                        getMoreOptionsContains(e.target.value);
                                        setSearchValue(e.target.value);
                                    }}
                                    value={searchValue}
                                ></Form.Control>
                                <div className="control-buttons">
                                    <ClearButton
                                        onClick={(e: EventType) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            resetOptions();
                                        }}
                                        clearButtonRef={clearButtonRef}
                                    />
                                </div>
                            </InputGroup>
                        </div>
                    )}
                    <Scrollbars
                        autoHeight
                        autoHeightMax={305}
                        onScrollFrame={(positionValues) => {
                            positionValues.top > 0.99 && getMoreOptions();
                        }}
                        renderView={(props) => (
                            <div
                                {...props}
                                style={{ ...props.style, overflowX: "unset", maxHeight: 305, marginBottom: 0 }}
                            />
                        )}
                        renderTrackHorizontal={() => <div style={{ display: "none" }} />}
                    >
                        {visibleOptions?.map((option) => (
                            <Dropdown.Item
                                href={option?.href || "#"}
                                key={option.value as string}
                                value={option.value}
                                active={menuType !== MenuTypes.checkbox && getActiveOption(option.value)}
                                onClick={handleClick(option)}
                            >
                                {renderOptionItem(option)}
                            </Dropdown.Item>
                        ))}
                    </Scrollbars>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

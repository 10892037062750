import React, {FunctionComponent} from "react";
import {HeaderGroup} from "react-table";

export interface HeaderProps {
    headerGroups: HeaderGroup[];
}

export const Header: FunctionComponent<HeaderProps> = (props) => {
    const {
        headerGroups
    } = props;
    return (
        <thead className="ms-table-header">
            {headerGroups.map((headerGroup: HeaderGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th key={index} {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

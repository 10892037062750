import {format, isValid, parseISO, differenceInMonths, differenceInCalendarMonths} from "date-fns";
import {DAYS_MAP, MONTH_MAP, SHORT_ISO_FORMAT, TODAY_TIMESTAMP} from "../constants";
import {DateData} from "../types";

export const DEFAULT_FORMAT = "MM/dd/yyyy, HH:mm:ss";
export const FULL_FORMAT = "yyyy-MM-dd";
export const SHORT_FORMAT = "MM/dd/yy";


export const dateToString = (date: Date, strFormat: string = DEFAULT_FORMAT): string => {
    return format(date, strFormat);
};

export const getDate = (timestamp: number) => timestamp ? dateToString(new Date(timestamp), SHORT_ISO_FORMAT) : null;

export const dateISOToString = (dateISO: string, strFormat: string = DEFAULT_FORMAT) => {
    return format(parseISO(dateISO), strFormat);
};

export const getEpochTime = (date?: Date | string | null): number => {
    return Math.floor((date ? new Date(date) : new Date()).getTime() / 1000);
};

export const getDayDetails = (dateArguments: {index: number, numberOfDays: number,
    firstDay: number, year: number, month: number}) => {
    const {index, firstDay, numberOfDays, year} = dateArguments;
    const date = index - firstDay;
    const day = index % 7;
    const prevMonth = dateArguments.month - 1 < 0 ? 11 : dateArguments.month - 1;
    const prevYear = dateArguments.month - 1 < 0 ? year - 1 : year;
    const prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    const _date = (date < 0 ? prevMonthNumberOfDays + date : date % numberOfDays) + 1;
    const month = date < 0 ? -1 : date >= numberOfDays ? 1 : 0;
    const timestamp = new Date(year, dateArguments.month + month, _date).getTime();
    return {
        date: _date,
        day,
        month,
        timestamp,
        dayString: DAYS_MAP[day]
    };
};

const getNumberOfDays = (year: number, month: number): number => {
    return 40 - new Date(year, month, 40).getDate();
};

export const getTodayInFullFormat = (): string => {
    return format(new Date(), FULL_FORMAT);
};

export const getMonthDetails = (year: number, month: number): DateData[]=> {
    const firstDay = (new Date(year, month)).getDay();
    const numberOfDays = getNumberOfDays(year, month);
    const monthArray = [];
    const rows = 6;
    const cols = 7;
    let currentDay = null;
    let index = 0;

    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            currentDay = getDayDetails({
                index,
                numberOfDays,
                firstDay,
                year,
                month
            });
            monthArray.push(currentDay);
            index++;
        }
    }
    return monthArray;
};

export const isCurrentDay = (day: DateData): boolean=> {
    return clearTime(new Date(day.timestamp)).getTime() === clearTime(new Date(Date.now())).getTime();
};

export const isSelectedDay = (day: DateData, selectedDay: number | string): boolean => {
    const selectedDayClear = clearTime(new Date(selectedDay));
    const dayClear = clearTime(new Date(day.timestamp));
    return selectedDayClear.getTime() === dayClear.getTime();
};

export const getMonthStr = (month: number): string => MONTH_MAP[Math.max(Math.min(11, month), 0)] || "Month";

export const getDateStringFromTimestamp = (timestamp: number, stringFormat = FULL_FORMAT): string => {
    if (timestamp) {
        return timestamp === TODAY_TIMESTAMP ? "Today" : format(new Date(timestamp), stringFormat);
    }
    return "";
};

export const getDateStringFromValue = (value: number | string, stringFormat = FULL_FORMAT): string => {
    if (!value) {
        return "";
    }
    const date = new Date(value);
    if (isValid(date)) {
        return value === TODAY_TIMESTAMP ? "Today" : format(new Date(value), stringFormat);
    } else {
        return "";
    }
};

export const clearTime = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
};  

// Returns exactly the Date with 00:00:00 time.
// Current timezone is ignored during conversion so that the date exactly matches the passed argument.
export const getDateTimestampFromString = (date: string) => {
    try {
        const dateStrWithoutTimeZone = new Date((new Date(date)).toISOString().slice(0, -1));
        return clearTime(dateStrWithoutTimeZone).getTime();
    } catch (e) {
        console.warn("Cannot get timestamp from string ", date);
        return null;
    }
};

export const getDateFromTimestamp = (timestamp: number, stringFormat: string = FULL_FORMAT): string =>
    timestamp ? format(new Date(timestamp), stringFormat) : "";

export const getYesterdayTimestamp = () => + new Date(Date.now() - 86400000);

export function getTimezoneOffsetInMs(date: Date): number {
    return date.getTimezoneOffset() * 60 * 1000;
}

//returns the number of Actual months between two dates
//01/31/2023-12/1/2023 == 11 months
export function diffFullMonths(fromDate:  Date |string| number, toDate: Date |string  | number) {
    return differenceInMonths(new Date(toDate), new Date(fromDate))+1;
}

//returns the number of Full months between two dates passed.
// 01/31/2023-12/1/2023 == 12 months
export function diffCalendarMonths(fromDate:  Date |string| number, toDate: Date |string  | number)  {
    return differenceInCalendarMonths(new Date(toDate), new Date(fromDate))+1;
}
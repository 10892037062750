import {Observable, of} from "rxjs";
import {ajax as rxjsAjax, AjaxConfig} from "rxjs/ajax";
import {mergeMap, catchError} from "rxjs/operators";

import {ResponseState, HTTPMethods} from "../types";


export interface IAjaxUser {
    token: string;
}

type getUserType = () => Observable<IAjaxUser>;

export interface IAjaxOptions {
    baseUrl?: string;
    headerAuthKey?: string;
    getUser?: getUserType;
}

export class Ajax {
    headerAuthKey?: string;
    getCurrentUser?: getUserType;
    restHeaders?: Record<string, string>;
    baseUrl?: string;
    withNotification?: (response: Observable<ResponseState>, showNotification: boolean, message?: string) => Observable<ResponseState>;

    constructor(ajaxOptions?: Record<string, string> | IAjaxOptions, withNotification?: (response: Observable<ResponseState>, showNotification: boolean, message?: string) => Observable<ResponseState>) {
        if (ajaxOptions) {
            const {baseUrl, getUser, headerAuthKey, ...restHeaders} = ajaxOptions;
            this.baseUrl = baseUrl as string;
            this.getCurrentUser = getUser as getUserType;
            this.headerAuthKey = headerAuthKey as string || "Authorization";
            this.restHeaders = restHeaders as Record<string, string>;
            this.withNotification = withNotification;
        }

    }

    private buildFullPath = (requestedURL:string) => {
        if (this.baseUrl && !this.isAbsoluteURL(requestedURL)) {
            return this.combineURLs(this.baseUrl, requestedURL);
        }
        return requestedURL;
    };

    private isAbsoluteURL = (url: string) => {
        // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
        // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
        // by any combination of letters, digits, plus, period, or hyphen.
        return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
    }

    private combineURLs = (baseURL: string, relativeURL: string) => {
        return relativeURL
            ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
            : baseURL;
    };

    private makeHeaders = (headers?: Record<string, string>): Observable<Record<string, string>> => {

        const defaultHeaders: Record<string, string> = {
            ...this.restHeaders
        };
        // inject current user info
        if (this.getCurrentUser) {
            return this.getCurrentUser().pipe(mergeMap((user: IAjaxUser) => {
                if (user && user.token) {
                    defaultHeaders[this.headerAuthKey] = user.token;
                }
                return of({
                    ...defaultHeaders,
                    ...(headers || {})
                });

            }));

        } else {
            return of({
                ...defaultHeaders,
                ...(headers || {})
            });

        }
    };

    private makeBody = (body?: Record<string, string>, files?: File[]): Record<string, string> | FormData => {
        if (!body) {
            return body;
        }

        if (files && files.length > 0) {
            const formData = new FormData();
            files.forEach(file => formData.append("file", file));
            Object.entries(body).forEach(([key, value]) => formData.append(key, new Blob([JSON.stringify(value)], {type: "application/json"})));
            return formData;
        }

        return body;
    }

    request = (method: HTTPMethods, url: string, headers: Record<string, string>, body?: Record<string, string>, responseHeaders?: Record<string, string>, files?: File[], responseType?: XMLHttpRequestResponseType): Observable<ResponseState> => {
        url = this.buildFullPath(url);
        const _body = this.makeBody(body, files);

        return this.makeHeaders(headers)
            .pipe<ResponseState>(mergeMap((_headers) => {
                const config: AjaxConfig = {
                    method: method.toUpperCase(),
                    url,
                    ...responseHeaders,
                    headers: _headers,
                    body: _body,
                    withCredentials: true,
                    crossDomain: true,
                };

                if (responseType) {
                    config.responseType = responseType;
                }
                return rxjsAjax(config).pipe(
                    mergeMap((originalResponse: any) => {
                        return of({
                            isLoading: false,
                            error: null,
                            data: originalResponse.response,
                            originalResponse: originalResponse,
                        });
                    }),
                    catchError((err) => {
                        return of({
                            isLoading: false,
                            error: err,
                            data: null,
                        });
                    })
                );
        })
        );
    };

    get = (url: string, headers?: Record<string, string>, responseHeaders?: Record<string, string>): Observable<ResponseState> => this.request("get", url, headers, null, responseHeaders);
    getBlob = (url: string, headers?: Record<string, string>, responseHeaders?: Record<string, string>): Observable<ResponseState<Blob>> => this.request("get", url, headers, null, responseHeaders, null, "blob")
    post = (url: string, headers?: Record<string, string>, body?: any | Record<string, string>, responseHeaders?: Record<string, string>, files?: File[]): Observable<ResponseState> => this.request("post", url, headers, body, responseHeaders, files);
    put = (url: string, headers?: Record<string, string>, body?: Record<string, string>, responseHeaders?: Record<string, string>, files?: File[]): Observable<ResponseState> => this.request("put", url, headers, body, responseHeaders, files);
    patch = (url: string, headers?: Record<string, string>, body?: Record<string, string>, responseHeaders?: Record<string, string>, files?: File[]): Observable<ResponseState> => this.request("patch", url, headers, body, responseHeaders, files);
    delete = (url: string, headers?: Record<string, string>, body?: Record<string, string>): Observable<ResponseState> => {
        return this.request("delete", url, headers, body);
    };
    getAjax = (url: string, showNotification = true, message?: string): Observable<ResponseState> => this.withNotification(this.get(url), showNotification, message);
    createUpdateAjax = (method: "patch" | "put" | "post", url: string, body?: Record<string, any>, showNotification = true, message?: string, headers?: Record<string, string>, responseHeaders?: Record<string, string>, files?: File[]): Observable<ResponseState> =>
        this.withNotification(this[method](url, headers || null, body, responseHeaders || null, files || null), showNotification, message);
    deleteAjax = (url: string, showNotification = true, message?: string, headers?: Record<string, string>): Observable<ResponseState> => this.withNotification(this.delete(url, headers), showNotification, message);
}

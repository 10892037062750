import {mergeMap, Observable, of} from "rxjs";

import {Ajax} from "@medispend/common/src/core/http";

import {ResponseState} from "@medispend/common/src/types";
import {store} from "@medispend/admin-common/src/state";
import notification, {notifyResponseError, MessagesType} from "@medispend/common/src/components/MSNotifications";
import {BASE_URL} from "./constants";

const withNotification = (response: Observable<ResponseState>, showNotification: boolean, message?: string) => {
    return response.pipe(
        mergeMap((response: ResponseState) => {
            if (response.error?.status === 401) {
                store.getState().authentication.logOutHandler();
            }
            if (showNotification && response.error) {
                if (response.error.status === 409) {
                    notification(response.error?.response?.message, MessagesType.WARNING);
                } else {
                    notifyResponseError(response, message)
                }
            }
            return of(response);
        }));
};


export const {getAjax, deleteAjax, createUpdateAjax} = new Ajax({baseUrl: BASE_URL, headerAuthKey: "Authorization",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUser: (): Observable<any> => {
        return of({token: sessionStorage.getItem("ACCESS_TOKEN")});
    }}, withNotification);

import React, {
    forwardRef,
    FunctionComponent,
    MouseEvent,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import {Scrollbars} from "react-custom-scrollbars-2";

import {IconFolders, IconTypes, MSIcon} from "../../MSIcon";
import {MSCheckbox} from "../../MSCheckbox";
import {MSButton} from "../../MSButton";
import {ButtonColors, ButtonSizes, ButtonTypes} from "../../MSButton/types";
import {ClearButton} from "../../MSButton/ClearButton";
import {BaseCustomDropdownButtonProps, MenuTypes, SelectOption} from "./types";
import {FilterValue} from "../../../types";

import "./index.scss";
import {Instance} from "@popperjs/core";

interface MSSelectProps<T extends BaseCustomDropdownButtonProps = BaseCustomDropdownButtonProps> {
    disabled?: boolean;
    options: SelectOption[];
    selectedOptions: Array<SelectOption>;
    menuType?: keyof typeof MenuTypes;
    dropdownTitle: string | ReactNode;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    onClick?: (optionData: SelectOption) => void;
    className?: string;
    customDropdownButton?: React.ForwardRefExoticComponent<T>;
    shouldShowClear?: boolean;
    handleClearOptions?: () => void;
    showSwitcherIcon?: boolean;
    autoClose?: boolean | "outside" | "inside"
    error?: string
    isLazy?: boolean
}

type EventType = MouseEvent<Element, globalThis.MouseEvent>

export const BaseSelect: FunctionComponent<MSSelectProps> = (props) => {
    const {
        disabled,
        options,
        dropdownTitle,
        activeVariant = null,
        selectedOptions,
        menuType = MenuTypes.list,
        buttonType = ButtonTypes.default,
        variant = ButtonColors.green,
        size = ButtonSizes.md,
        shouldShowClear = false,
        autoClose = true,
        showSwitcherIcon = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick = null,
        className = "",
        customDropdownButton = null,
        handleClearOptions = null,
        error = "",
        isLazy = true
    } = props;
    const LAZY_LOADING_STEP = isLazy ? 20 : Number.MAX_VALUE;
    const isMenuOpen = useRef(false);
    const clearButtonRef = useRef(null);
    const activeOptions = useMemo(
        () =>
            options.filter((option) => {
                if (!option.inactive) return true;
                if (menuType !== MenuTypes.checkbox) return false;

                return !!selectedOptions?.find((selected) => selected.value === option.value);
            }),
        [options, menuType, selectedOptions]
    );
    const getActiveOption = useMemo(() => (optionValue: FilterValue) => (
        selectedOptions?.some(o => o?.value === optionValue)
    ), [selectedOptions]);
    const [popper, setPopper] = useState<Instance>(null);

    const [visibleOptions, setVisibleOptions] = useState(activeOptions?.slice(0, LAZY_LOADING_STEP));

    const getMoreOptions = () => {
        // Load more options only if there are some options to load
        if (visibleOptions.length < activeOptions.length) {
            setVisibleOptions(activeOptions.slice(0, visibleOptions.length + LAZY_LOADING_STEP));
            if (popper) popper.update();
        }
    };

    useEffect(() => {
        setVisibleOptions(activeOptions?.slice(0, Math.max(visibleOptions.length || LAZY_LOADING_STEP, LAZY_LOADING_STEP)));
    }, [activeOptions]);

    const isIconActive = useMemo(() => (
        !["grey-100", "grey-200", "grey-800", "white"].includes(variant)
            || ["grey-100", "grey-200", "grey-800", "white"].includes(activeVariant)
    ), [activeVariant, variant]);

    const SwitcherIcon = useCallback(() => <MSIcon
        icon="caret-down"
        iconType={IconTypes.MS}
        iconFolder={IconFolders.UTILS}
        className={classNames("ms-caret-down", {
            active: isIconActive,
            "is-menu-open": isMenuOpen.current
        })}
    />, [isIconActive]);

    // TODO that's a really bad solution, in general component shouldn't even be there, it should be as separate function outside
    const SelectButton = useMemo(
        () =>
            forwardRef(({onClick}: {onClick: (e: EventType) => void}, ref: Ref<HTMLDivElement>) => (
                <div ref={ref} className={className}>
                    <MSButton
                        disabled={disabled}
                        size={size}
                        type={buttonType}
                        variant={activeVariant || variant}
                        className="ms-select-button"
                        onClick={(e: EventType) => {
                            e.preventDefault();
                            e.target !== clearButtonRef?.current && onClick(e);
                        }}
                    >
                        <div className={classNames("ms-button-title", {"is-placeholder": !selectedOptions?.length})}>
                            <span className="dropdown-title">{dropdownTitle}</span>
                            {shouldShowClear && selectedOptions?.length ? (
                                <div className="control-buttons">
                                    <ClearButton
                                        onClick={(e: EventType) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleClearOptions && handleClearOptions();
                                        }}
                                        clearButtonRef={clearButtonRef}
                                    />
                                    {showSwitcherIcon && SwitcherIcon()}
                                </div>
                            ) : (
                                showSwitcherIcon && SwitcherIcon()
                            )}
                        </div>
                    </MSButton>
                </div>
            )),
        [
            disabled,
            SwitcherIcon,
            size,
            buttonType,
            activeVariant,
            variant,
            showSwitcherIcon,
            shouldShowClear,
            className,
            dropdownTitle,
            selectedOptions,
            handleClearOptions
        ]
    );

    const renderOptionItem = (option: SelectOption) => {
        if (menuType === MenuTypes.checkbox) {
            return (
                <div className="ms-select-checkbox-menu">
                    <MSCheckbox active={getActiveOption(option.value)} />
                    {option.uiLabel}
                </div>
            );
        }

        return option.uiLabel;
    };

    const handleClick = (optionData: SelectOption) => () => {
        onClick(optionData);
    };

    return (
        <div className={classNames("ms-select", {"has-error": error?.length})}>
            <Dropdown
                onToggle={(isOpen) => {
                    isMenuOpen.current = isOpen;
                }}
                autoClose={autoClose}
            >
                <Dropdown.Toggle disabled as={customDropdownButton || SelectButton} />

                <Dropdown.Menu
                    popperConfig={{
                        modifiers: [{
                            name: "getPopperFromState",
                            enabled: true,
                            phase: "beforeWrite",
                            fn: (state) => {
                                if (!popper) setPopper(state.instance);
                            }
                        }]
                    }}
                >
                    <Scrollbars
                        autoHeight
                        autoHeightMax={305}
                        onScrollFrame={(positionValues) => {
                            positionValues.top > 0.99 && getMoreOptions();
                        }}
                        renderView={props => <div
                            {...props}
                            style={{...props.style, overflowX: "unset", maxHeight: 305, marginBottom: 0}} />
                        }
                        renderTrackHorizontal={() => <div style={{display: "none"}} />}
                    >
                        {visibleOptions?.map((option) => (
                            <Dropdown.Item
                                href={option?.href || "#"}
                                key={option.value as string}
                                value={option.value}
                                active={menuType !== MenuTypes.checkbox && getActiveOption(option.value)}
                                onClick={handleClick(option)}
                            >
                                {renderOptionItem(option)}
                            </Dropdown.Item>
                        ))}
                    </Scrollbars>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

/**
 * Creating a range
 *
 * @param {number} _start
 * @param {number} _end
 * @returns {Array<number>}
 */
export const createRange = (_start: number, _end?: number): Array<number> => {
    const range: Array<number> = [];
    let start;
    let end;

    if (typeof (_start) === "number" && typeof (_end) === "number") {
        start = _start;
        end = _end;
    } else if (!_end && _start) {
        start = 0;
        end = _start;
    }

    for (let idx = start; idx <= end; idx++) {
        range.push(idx);
    }

    return range;
};

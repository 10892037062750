import {AUDIENCE, CLIENT_ID} from "@medispend/common/src/constants/authentication";
import {getAuth0Config} from "@medispend/common/src/utils/getAuth0Config";
import {isProductionMode, PORT} from "@medispend/admin-common/src/constants";

const redirectUri = isProductionMode ? `${window.location.origin}/#/` : `http://${window.location.hostname}:${PORT}/#/`;

export const Auth0Config = getAuth0Config(CLIENT_ID, redirectUri);

export const Auth0options = {
    audience: AUDIENCE,
    returnTo: redirectUri
};

export enum USER_ROLE {
    BR_MEDISPEND_ADMIN = "ROLE_BR_MediSpend_admin",
    BR_CLIENT_ADMIN = "ROLE_BR_admin_admin"
}

export const BR_ADMIN_ROLES = [USER_ROLE.BR_MEDISPEND_ADMIN, USER_ROLE.BR_CLIENT_ADMIN];

export const ACCESS_DENIED = "You have no permission to view Business Rules Admin application. Please contact your MediSpend administrator.";

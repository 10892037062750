import React, {FC} from "react";
import Dropdown from "react-bootstrap/Dropdown";

import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {EditorTableCell} from "./EditorTableCell";
import {Direction, Rule} from "../../../../types";

import "./scss/_EditerTableRow.scss";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";
import {useGlobalModalContext} from "@medispend/common/src/components/MSGlobalModal";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";


export interface EditorTableRowProps {
    ruleData: Rule[];
    onRemoveRow?: (index: number) => void;
    count: number;
    onChangeRow: (index: number, newValue: string | number) => void;
    index: number;
    disabled: boolean;
    onPasteRow: (index: number) => void;
    onCopyRow: (rule: Rule[]) => void;
    onMoveRow: (index: number, direction: Direction) => void;
    buffer: Rule[] | null;
}

export const EditorTableRow: FC<EditorTableRowProps> = ({ruleData,
    count, onRemoveRow = () => {/**/}, onChangeRow = () => {/**/}, index, disabled, onCopyRow, onPasteRow, buffer, onMoveRow}: EditorTableRowProps) => {

    const {showModal} = useGlobalModalContext();
    const onDeleteRow = () => {
        showModal(MODAL_TYPES.CONFIRM_MODAL, {
            title: "Are you sure you want to remove this row?",
            onConfirm: () => onRemoveRow(index)
        });
    };

    return (<tr className="rule-row">
        <td className="add-col">
            <div className="remove-row-button">
                {!disabled && <Dropdown className="ellipsis-dropdown" drop="up">
                    <Dropdown.Toggle><div className="row-options">
                        <MSIcon icon="fas fa-ellipsis-v" />
                    </div></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => onCopyRow(ruleData)}>Copy</Dropdown.Item>
                        {count > 1 && <>
                            <Dropdown.Item onClick={() => onDeleteRow()}>Delete</Dropdown.Item>
                            <Dropdown.Item onClick={() => onMoveRow(index, Direction.UP)} disabled={index === 0}>Move Up</Dropdown.Item>
                            <Dropdown.Item onClick={() => onMoveRow(index, Direction.DOWN)} disabled={index + 1 >= count}>Move Down</Dropdown.Item>
                        </>}
                        <Dropdown.Item key={`dropdown-${index}`} onClick={() => onPasteRow(index)} disabled={!buffer}>Paste</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
                <div className="counter">{index + 1}</div>
            </div>
        </td>
        {ruleData.map((rule, i) => {
            return (<EditorTableCell
                disabled={isMedispendSubDom ? false : disabled}
                rule={rule}
                key={`${index}-${i}-rule-cell`}
                handleChangeValue={(newValue) => onChangeRow(i, newValue.value)}
            />);
        })}
    </tr>);
};

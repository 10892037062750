import {ConnectedRouter} from "connected-react-router";
import React, {useEffect, FunctionComponent} from "react";
import {Provider} from "react-redux";

import {store, history} from "./state";
import {AppsList} from "./constants";
import {App} from "./App";


interface AdminLayoutProps {
    render: () => JSX.Element,
    app: AppsList,
    auth0options: {audience: string, returnTo: string}
}

export const AdminLayout: FunctionComponent<AdminLayoutProps> = ({render, app, auth0options}: AdminLayoutProps) => {
    useEffect(() => {
        document.title = "Admin | MediSpend";
    }, []);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App render={render} app={app} auth0options={auth0options} />
            </ConnectedRouter>
        </Provider>
    );
};

import * as Yup from "yup";

import {FilterParams} from "@medispend/common/src/types";


export type User = {
    firstName: string;
    lastName: string;
    roles?: string[];
    username?: string;
    email?: string;
}

export type BusinessRule = {
    uuid: string;
    client: {
        name: string;
        displayName: string;
        isDefault: boolean;
    }
    id: number;
    name: string;
    description: string;
    ruleType: string;
    application: {
        id: number;
        name: string;
        _links: LinksObject
    }
    process: {
        id: number;
        name: string;
        _links: LinksObject;
    }
    contextId: number;
    status: string;
    effectiveDate?: string;
    expiryDate?: string;
    version: string | number;
    _links: LinksObject
}

export type Trigger = {
    id?: number;
    name?: string;
    contextType?: string;
    inputObjects?: {
        id: number;
        name: string;
        application: {
            id: number;
            name: string;
            _links: LinksObject;
        };
        process: {
            id: number;
            name: string;
            _links: LinksObject;
        },
        _links: LinksObject;
    }[],
    actions?: ActionsObject[],
    _links?: LinksObject
}

export type LinksObject = {
    self: {
        href: string
    }
}

export type PaginationResponse = {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number
}

export type BusinessRulesListResponse = {
    _embedded?: {
        rules?: BusinessRule[],
    }
    _links?: LinksObject,
    page?: PaginationResponse
}

export type TriggerResponseData = {
    _embedded?: {
        contexts?: Trigger[],
    },
    _links?: LinksObject,
    page?: PaginationResponse
}

export interface DateFilter {
    [name: string]: FilterParams
}

export type BusinessRulesMetadata = {
    clients: Client[]
    applications: Application[]
    processes: Process[]
    _links: LinksObject
}

export type Process = {
    id: number;
    name: string;
    _links?: LinksObject;
}

export type Application = {
    id: number;
    name: string;
    _links?: LinksObject;
}

export type Client = {
    name: string;
    displayName: string;
    active?: boolean;
    tenantId?: string;
}

export type Rule = {
    name: string,
    description?: string,
    condition?: "When" | "Then" | "And" | null,
    value?: string | number | null,
    dataType?: string,
    isLast?: boolean,
    length?: number,
    id?: string | number,
    type?: ColumnType,
    isPlaceholder?: boolean;
    isFirst?: boolean;
}

export type YupObject = Record<string, Yup.AnySchema>;

export type DropdownWithHeadersOption = {name: string, options: Rule[] | [], id?: number};

export type Output = {
    name: string,
    dataType: string,
    length?: number,
    actionType?: string
}

export type Input = {
    id: string | number
    name: string,
    description: string,
    dataType: string,
    value?: string
}

export type DMNObject = {
    hitPolicy: string,
    outputs?: Output[],
    dtRules?: {
        inputs: (string | number)[],
        outputs: (string | number)[],
        comment: string | number
    }[],
    inputs?: Input[],
    actionIds?: (number | string)[],
    additionalInputIds?: string[],
    actions?: {name: string, data: ActionType[], id?: string, actionType: string}[]
}

export type ActionType = {
    id: number;
    actionType: string;
}

export type RuleObject = {
    rule: {name: string,
        ruleType: string,
        applicationId: number,
        processId: number,
        contextId?: string,
        client: string,
        status: string,
        description: string,
        effectiveDate?: string,
        expiryDate?: string,
        version: number,
        minorVersion: number,
        uuid?: string
    },
    dmnObject?: DMNObject
}

export interface BusinessRuleParams {
    id?: string,
    clientName?: string
    ruleType?: RuleType
}

export type SaveData = {
    ruleRows: Rule[][],
    policy: string,
    trigger: string,
    rules: Rule[],
    additionalInputs: Input[]
}

export type TriggerData = {
    inputObjects: InputObjects[],
    actions: ActionsObject[]
}

export type InputObjects = {
    id: number,
    name: string,
    application: {
        id: number,
        name: string
    },
    process: {
        id: number,
        name: string,
    },
    sections: Section[]
}

export type Section = {
    name: string,
    inputs: TriggerInputs[]
}

export type ActionsObject = {
    id: number,
    name: string,
    actionType: string,
    data: Action[],
    _links: LinksObject
}

export type Action = {
    id: number,
    keyName: string,
    keyDataType?: string,
    predefinedValues?: string[],
}

export type TriggerInputs = {
    id: string,
    name: string,
    description: string,
    dataType: string
}

export type ErrorPosition = "top" | "bot";

export enum HitPolicy {
    UNIQUE = "UNIQUE",
    ANY = "ANY",
    PRIORITY= "PRIORITY",
    COLLECT = "COLLECT",
    RULE_ORDER = "RULE_ORDER",
    OUTPUT_ORDER ="OUTPUT_ORDER"
}

export enum ColumnType {
    PROPERTY = "Property",
    INPUT = "Input",
    OUTPUT = "Output",
    COMMENTS = "Comments"
}

export enum Status {
    DRAFT = "DRAFT",
    TEST = "TEST",
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

export enum RuleType {
    STANDARD = "STANDARD",
    CUSTOM = "CUSTOM",
}

export enum Position {
    TOP= "top",
    BOT= "bot"
}

export enum Condition {
    WHEN = "When",
    THEN = "Then",
    AND = "And"
}

export enum Direction {
    LEFT = "left",
    RIGHT = "right",
    UP = "up",
    DOWN = "down"
}

export enum WorkflowType {
    NEEDS_ASSESSMENT = "NEEDS_ASSESSMENT",
    CONTRACTS = "CONTRACTS",
    HCPQ = "HCPQ",
    ENGAGEMENTS = "ENGAGEMENTS",
    ENGAGEMENT_HCP = "ENGAGEMENT_HCP",
    BULK_ENGAGEMENT_HCP = "BULK_ENGAGEMENT_HCP",
    GRANTS = "GRANTS",
    ACTIVITY_REPORTS = "ACTIVITY_REPORTS",
    CREDIT_MEMO = "CREDIT_MEMO"
}


export const userHasRole = <UserRoleType>(role: UserRoleType, userDataRoles: UserRoleType[]): boolean => {
    return (userDataRoles.includes(role));
};

export const userHasAnyRole = <UserRoleType>(roles: UserRoleType[], userDataRoles: UserRoleType[]): boolean => {
    return roles.some((role) => userHasRole(role, userDataRoles));
};

export const userHasRoles = <UserRoleType>(roles: UserRoleType[], userDataRoles: UserRoleType[]): boolean => {
    return roles.every((role) => userHasRole(role, userDataRoles));
};

export const userHasOnlySpecifiedRoles = <UserRoleType>(roles: UserRoleType[],
    userDataRoles: UserRoleType[]): boolean => {
    return userDataRoles.every((role: UserRoleType) => roles.includes(role));
};

import React, {FC, MouseEvent, forwardRef, useCallback} from "react";
import classNames from "classnames";

import {MSMultiSelect} from "@medispend/common/src/components/MSMultiSelect";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {MultiSelectOption, Values} from "@medispend/common/src/components/MSSelect/BaseSelect/types";

import "./scss/style.scss";


export interface AdditionalInputProps {
    inputData: MultiSelectOption[];
    checkTrigger: () => void;
    disabled: boolean;
    selectedOptions: MultiSelectOption[];
    setSelectedOptions: (options: MultiSelectOption[]) => void;
}


type EventType = MouseEvent<Element, globalThis.MouseEvent>

const AddButton = forwardRef<HTMLDivElement, {onClick: (e: EventType) => void}>(({onClick},
    ref) => {
    return (<div ref={ref}>
        <MSButton
            disabled={false}
            variant="white"
            className="ms-select-button"
            onClick={(e: EventType) => {
                e.preventDefault();
                onClick(e);
            }}>
            <div className="ms-table-filter-add">
                <MSIcon icon="fas fa-plus-circle" />
                <span>Additional Input</span>
            </div>
        </MSButton>
    </div>);
});

export const AdditionalInput: FC<AdditionalInputProps> = (props: AdditionalInputProps) => {
    const {inputData, checkTrigger = () => {/**/}, disabled, selectedOptions, setSelectedOptions} = props;

    const getDefaultValues = useCallback(() => selectedOptions.map(option => option.value),
        [selectedOptions, inputData]);

    const onDeleteInput = (value: Values) => {
        const newOptions = selectedOptions.filter(option => option.value !== value);
        setSelectedOptions(newOptions);
    };

    const renderInputTags = useCallback(() => {
        return (selectedOptions.map((input, index) => {
            return input?.value ? (<div key={`${input.value}-${index}`} className="additional-input-item">
                {input.uiLabel}
                <MSIcon icon="fas fa-times" onClick={() => onDeleteInput(input.value)} />
            </div>) : null;
        }));
    }, [selectedOptions]);

    return (<div className="additional-input-container">
        {renderInputTags()}
        {(inputData.length === 0 || disabled) && <MSButton
            disabled={disabled}
            variant="white"
            className="ms-select-button-custom"
            onClick={(e: EventType) => {
                checkTrigger();
                e.preventDefault();
            }}>
            <div className={classNames("ms-table-filter-add", disabled ? "disabled-button" : "")}>
                <MSIcon icon="fas fa-plus-circle" />
                <span>Additional Input</span>
            </div>
        </MSButton>}
        {(inputData.length > 0 && !disabled) && <MSMultiSelect onChange={(optionData) => setSelectedOptions(optionData)}
            options={inputData}
            placeholder="+ Additional Input"
            menuType="checkbox"
            customDropdownButton={AddButton}
            defaultValues={getDefaultValues()}
        />}
    </div>);
};

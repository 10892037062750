

export enum USER_ROLE {
    BR_MEDISPEND_ADMIN = "ROLE_BR_MediSpend_admin",
    BR_CLIENT_ADMIN = "ROLE_BR_admin_admin",

    CFF_MEDISPEND_ADMIN = "ROLE_CFF_MediSpend_admin",
    CFF_CLIENT_ADMIN = "ROLE_CFF_admin_admin",

    TS_SYSTEM_ADMIN = "ROLE_SystemAdmin",
    EM_SYSTEM_ADMIN = "ROLE_EM_SystemAdmin",

    EM_USER = "ROLE_EM_EngagementManager",
    TS_USER = "ROLE_MedispendUser",

    GLOBAL_MEDISPEND_ADMIN = "ROLE_GlobalAdmin_MediSpend_admin",
    GLOBAL_CURRENCY_ADMIN = "ROLE_GlobalAdmin_CurrencyConversion_admin"
}

export const BR_ADMIN_ROLES = [USER_ROLE.BR_MEDISPEND_ADMIN, USER_ROLE.BR_CLIENT_ADMIN];
export const CFF_ADMIN_ROLES = [USER_ROLE.CFF_MEDISPEND_ADMIN, USER_ROLE.CFF_CLIENT_ADMIN];
export const GLOBAL_ADMIN_ROLES = [USER_ROLE.GLOBAL_MEDISPEND_ADMIN, USER_ROLE.GLOBAL_CURRENCY_ADMIN];

export const PRODUCTS = {
    TS: {
        name: "Transparency Solution",
        link: ""
    },
    CFF: {
        name: "Custom Form Fields",
        link: ""
    },
    BR: {
        name: "Business Rules",
        link: ""
    },
    EM: {
        name: "Engagement Manager",
        link: ""
    },
    USER_ADMIN: {
        name: "Global Admin",
        link: ""
    }
};

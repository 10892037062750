import classNames from "classnames";
import React, {FunctionComponent, PropsWithChildren, useEffect} from "react";
import {connect} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import {Location} from "history";

import {LayoutContent, LayoutContentProps} from "./Content";
import {LayoutHeader, LayoutHeaderProps} from "./Header";
import {LayoutSideNav, LayoutSideNavProps} from "./SideNav";
import * as LayoutState from "./state";
import {UserToggleDropdownBlock} from "./types";
import {User} from "../../types";

import "./scss/_MSLayout.scss";
import "react-toastify/dist/ReactToastify.css";
import {MSFooter} from "../MSFooter";
import {MSPrompt} from "../MSPrompt";

interface LayoutStateProps {
    children?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    hasHeader?: boolean;
    hasSideNav?: boolean;
    header?: LayoutHeaderProps;
    sideNav?: LayoutSideNavProps;
    content?: LayoutContentProps;
    onViewModeFull?: () => void;
    onViewModeCompact?: () => void;
    user?: User;

    compactView?: boolean;
    layoutAlertClear?: (aId: string) => void;
    layoutAlerts?: LayoutState.LayoutAlerts;
    layoutLoading?: LayoutState.LayoutLoadingPayload;
    location?: Location;
    [LayoutState.LAYOUT_PROPS_DYNAMIC]?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    userToggleBlocks?: UserToggleDropdownBlock[]
}

interface LayoutDispatchProps {
    toggleViewMode?: () => void;
    viewModeFull?: () => void;
    viewModeCompact?: () => void;
    updatePropsDynamic?: () => void;
}

interface LayoutProps extends LayoutStateProps, LayoutDispatchProps {
}

const Layout: FunctionComponent<PropsWithChildren<LayoutProps>> = (props) => {
    const {
        children,
        compactView,
        content,
        hasHeader,
        hasSideNav,
        layoutAlerts,
        layoutLoading,
        location,
        onViewModeCompact,
        onViewModeFull,
        header,
        sideNav,
        user,
        viewModeCompact,
        viewModeFull,
        userToggleBlocks
    } = props;

    const contentLoading = layoutLoading[LayoutState.LAYOUT_LOADING_CONTENT];
    const sideNavLoading = layoutLoading[LayoutState.LAYOUT_LOADING_CONTENT];
    const styleClasses = classNames("ms-layout", {
        "ms-layout-loading": contentLoading || sideNavLoading,
        "ms-layout-loading-content": contentLoading,
        "ms-layout-loading-side-nav": sideNavLoading,
        "ms-layout-compact-view": compactView,
        "ms-layout-has-header": hasHeader,
        "ms-layout-has-side-nav": hasSideNav
    });

    const commonProps: LayoutProps = {
        user,
        compactView,
        layoutLoading,
        location,
        onViewModeCompact,
        onViewModeFull,
        viewModeCompact,
        viewModeFull,
        userToggleBlocks
    };

    const headerProps: LayoutProps & LayoutSideNavProps = {
        ...commonProps,
        ...header,
        ...(props[LayoutState.LAYOUT_PROPS_DYNAMIC] || {header: {}}).header
    };

    const sideNavProps: LayoutProps & LayoutSideNavProps = {
        ...commonProps,
        ...sideNav,
        ...(props[LayoutState.LAYOUT_PROPS_DYNAMIC] || {sideNav: {}}).sideNav
    };

    useEffect(() => {
        Object.keys(layoutAlerts).map((alertId: string): void => {
            const {id, message, level, options} = layoutAlerts[alertId];
            (toast as any)[level](message, {customId: id, ...(options ?? {})});
        });
    });

    return (
        <div className={styleClasses}>
            {hasHeader && <LayoutHeader {...headerProps} />}
            <div>
                <div className="ms-layout-container">
                    <LayoutContent {...{...commonProps, ...content}}>{children}</LayoutContent>
                </div>
                {hasSideNav && <LayoutSideNav {...sideNavProps} />}
            </div>

            <MSFooter />

            <ToastContainer />
            <MSPrompt />
        </div>
    );
};

export * from "./hooks";
export * from "./state";
export {LayoutContentProps} from "./Content";
export {LayoutSideNavProps} from "./SideNav";
export {LayoutBreadcrumbs} from "./Breadcrumbs";
export {UserToggleDropdownBlock, UserToggleDropdownOption} from "./types";
export const MSLayout = connect(LayoutState.layoutStateToProps, LayoutState.layoutDispatchToProps)(Layout);

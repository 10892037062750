import {User} from "@auth0/auth0-react";
import {AUTH0_APP_METADATA_CLAIM} from "../constants";

export function getRolesFromAuth0User(user: User): Array<string> {
    let roles: Array<string> = [];

    const metadata = getMetadata(user, AUTH0_APP_METADATA_CLAIM);
    if (metadata) {
        roles = metadata.roles;
    }
    return roles;
}

export function getPrimarySubdomainFromAuth0User(user: User): string {
    let primarySubdomain = "";

    const metadata = getMetadata(user, AUTH0_APP_METADATA_CLAIM);
    if (metadata) {
        primarySubdomain = metadata.primarySubdomain;

    }
    return primarySubdomain;
}

export function getAllowedSubdomainsFromAuth0User(user: User): Array<string> {
    let subdomains: Array<string> = [];

    const metadata = getMetadata(user, AUTH0_APP_METADATA_CLAIM);
    if (metadata) {
        subdomains = metadata.subdomains;
    }
    return subdomains;
}

function getMetadata(user: User, metDataPath: string): any {
    if (user) {
        return user[metDataPath];
    } else {
        return "";
    }
}

import React, {useEffect, useState, FC, useRef} from "react";

import {Rule} from "../../../../types";
import {MSSelect} from "@medispend/common/src/components/MSSelect";
import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {BOOLEAN_OPTIONS} from "./constants";


export interface EditorTableCellProps {
    rule: Rule;
    handleChangeValue: (data: any) => void;
    disabled: boolean;
}

export const EditorTableCell:FC<EditorTableCellProps> = ({rule, handleChangeValue, disabled}: EditorTableCellProps) => {
    const {isPlaceholder, name} = rule;
    const [value, setValue] = useState(rule?.value || "");
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);
    useEffect(() => {
        setValue(rule.value || "");
    }, [rule.value]);

    useEffect(() => {
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleInputChange = (event: React.SyntheticEvent<HTMLDivElement, Event>) => {
        const target = event.target as HTMLInputElement;
        setCursor(target.selectionStart);
        handleChangeValue && handleChangeValue(target);
    };

    const renderInput = (type: string) => {
        if (isPlaceholder) return;
        switch (type) {
            case "boolean": {
                return <div><MSSelect defaultValue={value || "-"}
                    options={BOOLEAN_OPTIONS}
                    variant={ButtonColors.white}
                    size={ButtonSizes.wd}
                    className="cell-dropdown"
                    onChange={handleChangeValue}
                    placeholder={`${value}` || "-"}
                    disabled={disabled}
                /></div>;
            }
            case "number": {
                return <input ref={ref} value={value} type="text" onChange={handleInputChange} disabled={disabled} />;
            }
            default: {
                return <input ref={ref} value={value} type="text" onChange={handleInputChange} disabled={disabled} />;
            }
        }
    };

    return (name === "Comments" ? <td className="blank-col-footer-last">
        <input ref={ref} value={value} type="text" onChange={handleInputChange} disabled={disabled} />
    </td> : <td className="rule-cell">{renderInput(rule.dataType)}</td>);
};

import {Dispatch} from "redux";


import {
    layoutSetLoading,
    layoutAlertAdd,
    layoutAlertClear,
    layoutAlertClearAll,
    layoutPropsUpdateDynamic,
    layoutViewCompact,
    layoutViewFull,
    layoutViewToggle,
    LAYOUT_ALERT,
    LAYOUT_LOADING,
    LAYOUT_VIEW_COMPACT,
    LayoutLoadingPayload,
    LayoutAlertType,
    LayoutAlerts
} from "./actions";
import {
    LAYOUT_LOADING_CONTENT,
    LAYOUT_LOADING_SIDENAV,
    LAYOUT_PROPS_DYNAMIC
} from "./constants";
import {LayoutState} from "./reducers";

export * from "./actions";
export * from "./constants";
export * from "./reducers";

export const REDUCER_NAMESPACE_LAYOUT = "sdLayout";

interface LayoutStateToPropsReturn {
    compactView: boolean;
    layoutLoading: LayoutLoadingPayload;
    layoutAlerts: LayoutAlerts;
    [LAYOUT_PROPS_DYNAMIC]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    location: string;
}

export const layoutStateToProps = (state: {
    [REDUCER_NAMESPACE_LAYOUT]?: LayoutState;
    router?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}): LayoutStateToPropsReturn => {
    return {
        compactView: state[REDUCER_NAMESPACE_LAYOUT][LAYOUT_VIEW_COMPACT],
        layoutLoading: state[REDUCER_NAMESPACE_LAYOUT][LAYOUT_LOADING],
        layoutAlerts: state[REDUCER_NAMESPACE_LAYOUT][LAYOUT_ALERT],
        [LAYOUT_PROPS_DYNAMIC]: state[REDUCER_NAMESPACE_LAYOUT][LAYOUT_PROPS_DYNAMIC],
        location: state["router"].location
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const layoutDispatchToProps: any = (dispatch: Dispatch) => {
    return {
        layoutAlertClearAll: (): void => {
            dispatch(layoutAlertClearAll());
        },
        layoutAlertClear: (alertId: string): void => {
            dispatch(layoutAlertClear(alertId));
        },
        layoutAlertAdd: (payload: LayoutAlertType): void => {
            dispatch(layoutAlertAdd(payload));
        },
        layoutLoadingSet: (payload: {
            [LAYOUT_LOADING_CONTENT]?: boolean;
            [LAYOUT_LOADING_SIDENAV]?: boolean;
        }): void => {
            dispatch(layoutSetLoading(payload));
        },
        layoutLoadingSetContent: (payload: boolean): void => {
            dispatch(layoutSetLoading({[LAYOUT_LOADING_CONTENT]: payload}));
        },
        layoutLoadingSetSideNav: (payload: boolean): void => {
            dispatch(layoutSetLoading({[LAYOUT_LOADING_SIDENAV]: payload}));
        },
        toggleViewMode: (): void => {
            dispatch(layoutViewToggle());
        },
        viewModeCompact: (): void => {
            dispatch(layoutViewCompact());
        },
        viewModeFull: (): void => {
            dispatch(layoutViewFull());
        },
        updatePropsDynamic: (payload: any): void => { // eslint-disable-line @typescript-eslint/no-explicit-any
            dispatch(layoutPropsUpdateDynamic(payload));
        }
    };
};

const hasCommonKeys = (arrays: Array<Array<string>>): boolean => {
    const combined: any = {};
    let totalKeys = 0;
    arrays.map((arr: Array<string>) => {
        arr.map((keyItem: string) => {
            combined[keyItem] = true;
            ++totalKeys;
        });
    });

    return Object.keys(combined).length < totalKeys;
};

const areSame = (array1: Array<string>, array2: Array<string>): boolean => {
    return array1.length == array2.length && array1.every(function(element, index) {
        return element === array2[index];
    });
}

export {areSame,hasCommonKeys};

import thunk from "redux-thunk";

import {createLayoutStore} from "@medispend/common/src/components/MSLayout/utils";
import {
    LAYOUT_LOADING_CONTENT,
    LAYOUT_LOADING_SIDENAV,
    layoutAlertAdd,
    LayoutAlertType,
    layoutSetLoading
} from "@medispend/common/src/components/MSLayout";
import authentication from "@medispend/common/src/authentication/auth0/state/reducers";


const {history, store} = createLayoutStore(authentication, [thunk]);

const layoutAlert = (payload: LayoutAlertType): void => {
    store.dispatch(layoutAlertAdd(payload));
};

const setContentLoading = (loading = true): void => {
    store.dispatch(layoutSetLoading({[LAYOUT_LOADING_CONTENT]: loading}));
};

const setSideNavLoading = (loading = true): void => {
    store.dispatch(layoutSetLoading({[LAYOUT_LOADING_SIDENAV]: loading}));
};

export {
    history,
    store,
    layoutAlert,
    setContentLoading,
    setSideNavLoading
};

export const maskString = (
    value: string,
    shown: number = 3,
    shownTail: number = 1,
    join: string = "",
    maskChar: string = "*",
    maskCount: number = 4
): string => {

    try {
        const _val = [value.slice(0, shown), join, new Array(maskCount).join(maskChar)].join("");
        let lastChars = value.slice(value.length - shownTail);

        // make sure we're not negating the masking by passing in faulting params
        if ((shown + shownTail) >= value.length) {
            lastChars = value[value.length - 1];
        }

        return `${_val}${lastChars}`;
    } catch (error) {
        return value;
    }
};

import React, {useRef, useEffect} from "react";

import {MSButton} from "../../MSButton";
import {MSModal} from "../../MSModal";
import {useGlobalModalContext} from "../index";

export const MSConfirmationModal = () => {
    const {hideModal, store} = useGlobalModalContext();
    const cancelButtonRef = useRef<HTMLButtonElement>();
    const {modalProps} = store || {};
    const {title, confirmBtn, text = "", onConfirm, onCancel, showFooter = true} = modalProps || {};
    const handleModalToggle = () => {
        onCancel && onCancel();
        hideModal();
    };
    const handleApprove = () => {
        onConfirm();
        hideModal();
    };

    useEffect(() => {
        cancelButtonRef.current && cancelButtonRef.current.focus();
    }, []);

    return (<MSModal backdropClassName="back-drop-fixed" show footer={showFooter ? <>
        <MSButton ref={cancelButtonRef} key="cancel" onClick={handleModalToggle} variant="grey-100" size="lg">Cancel</MSButton>
        <MSButton key="approve" onClick={handleApprove} variant="green" size="lg">{confirmBtn || "Ok"}</MSButton>
    </> : null}
    onClose={handleModalToggle}
    headerTitle={!showFooter && title}
    body={<div>
        <h4>{showFooter && title}</h4>
        <div>{text}</div>
    </div>}
    />);
};

import React, {FC, useEffect, useRef} from "react";
import classNames from "classnames";

import {FieldHandleAction, FormFieldHint, Mode} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";

import "./scss/index.scss";


export interface MSInputProps {
    value: any;
    fieldLabel?: string;
    isRequired: boolean;
    isDisabled: boolean;
    error?: string;
    maxFieldLength?: number;
    inputType?: "string" | "number";
    name?: string;
    handleChange?: (value: string, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
    onBlur?: () => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    variant?: "form" | "grid";
    className?: string;
    mode?: Mode;
    isPencilDisplayed?: boolean;
    hint?: FormFieldHint;
}

const MSInput: FC<MSInputProps> = ({value, name, fieldLabel, maxFieldLength, isRequired, isDisabled, error, inputType = "string", handleChange, isHandleAction, handleAction, variant = "form", className, mode, isPencilDisplayed, onBlur, hint}) => {
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current && (inputRef.current.value = value || "");
    }, [value]);
    const inputContent = <>
        {fieldLabel && <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={isPencilDisplayed} error={error} />}
        <div className={classNames({"has-error": error?.length})}>
            {mode === "READ" ? <div className="value">{value || "-"}</div> :
                <input
                    className={classNames("ms-input", {"has-error": error?.length})}
                    ref={inputRef}
                    name={name}
                    defaultValue={(value === undefined || value === null) ? "" : value}
                    onChange={(e) => {
                        handleChange && handleChange(e.target.value, name, isHandleAction, handleAction);
                    }}
                    onBlur={onBlur}
                    disabled={isDisabled}
                    type={inputType}
                    maxLength={maxFieldLength}
                />
            }
            {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
            {!!error?.length && <div className="error-message">{error}</div>}
        </div>
    </>;

    return (
        <>
            {variant === "form" && (
                <div className={`ms-form-group ms-form-input ${className}`}>
                    {inputContent}
                </div>
            )}
            {variant === "grid" && (
                <>
                    {inputContent}
                </>
            )}
        </>);
};

export default MSInput;

import classNames from "classnames";
import React, {FunctionComponent} from "react";

import {Pagination, PaginationProps} from "./Pagination";
import {hasPageSize, PageSizeProps} from "./PageSize";

export interface FooterProps {
    pageSizeOpts?: PageSizeProps;
    pagination: PaginationProps;
    showPagination?: boolean;
}

export const Footer: FunctionComponent<FooterProps> = (props) => {
    const {
        pageSizeOpts,
        pagination,
        showPagination
    } = props;

    return (
        <div className={classNames("ms-table-footer", {"has-page-size-opts": hasPageSize(pageSizeOpts)})}>
            {(showPagination !== false) && <Pagination {...pagination} pageSizeOpts={pageSizeOpts} />}
        </div>
    );
};

export const ERROR_COLUMN_NOT_SELECTED = "There is an empty column. Please complete or remove this column";
export const ERROR_UNEXPECTED = "The record was not saved. Please try again or contact your system administrator.";
export const ERROR_INPUT_UNAVAILABLE = "One or more input values are no longer used and will need to be changed in order to save";
export const SUCCESS_RULE_CREATED = "Rule successfully created.";
export const SUCCESS_RULE_PREFIX = "The business rule has been successfully ";
export const SUCCESS_RULE_EDITED = SUCCESS_RULE_PREFIX + "modified.";
export const SUCCESS_RULE_EDITED_NOT_DRAFT = "New version of rule has been successfully created";
export const CONFIRMATION_EDIT_HEADER = "The business rule has been modified.";
export const CONFIRMATION_EDIT_LABEL = "Are you sure you want to save your changes?";
export const CONFIRMATION_EDIT_NOT_DRAFT_HEADER = "The business rule has been modified and a new version will be created.";
export const CONFIRMATION_EDIT_NOT_DRAFT_LABEL = "Are you sure you want to create a new version?";
export const CONFIRMATION_COPY_TO_CUSTOM_RULE_TITLE = "A new custom rule has been created.";
export const CONFIRMATION_COPY_TO_CUSTOM_RULE_LABEL = "Are you sure you want to save your changes?";

import React, {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import {FormWrapper} from "../FormWrapper";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {BusinessRuleEditorTable} from "./BusinessRuleEditorTable";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {useGlobalModalContext} from "@medispend/common/src/components/MSGlobalModal";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";
import {FormValues} from "../BusinessRuleCreate";
import {getTriggersContextData, getTriggersData} from "../../../services/businessRules";
import {
    getTriggerActionsContext,
    getTriggerInputContext,
    getTriggerInputsWithoutHeaders,
    getTriggersDataContent,
    isDisabled
} from "../../../helpers/businessRules";
import {AdditionalInput} from "./AdditionalInput";
import {
    BusinessRuleParams,
    ColumnType,
    ErrorPosition,
    HitPolicy,
    Rule,
    RuleType,
    SaveData,
    Status
} from "../../../types";
import {ResponseState} from "@medispend/common/src/types";
import {ErrorContainer} from "./ErrorContainer/ErrorContainer";
import {CSSTransition} from "react-transition-group";

import "./scss/style.scss";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";
import {MultiSelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";
import {
    CONFIRMATION_COPY_TO_CUSTOM_RULE_LABEL,
    CONFIRMATION_COPY_TO_CUSTOM_RULE_TITLE,
    CONFIRMATION_EDIT_HEADER,
    CONFIRMATION_EDIT_LABEL,
    CONFIRMATION_EDIT_NOT_DRAFT_HEADER,
    CONFIRMATION_EDIT_NOT_DRAFT_LABEL
} from "./constants";
import {useParams} from "react-router-dom";
import * as _ from "lodash";
import {getRolesFromAuth0User} from "@medispend/admin-common/src/utils/auth0Util";
import {useAuth0} from "@auth0/auth0-react";
import {userHasAnyRole} from "@medispend/common/src/utils";
import {USER_ROLE} from "../../../constants";


export interface BusinessRuleEditProps {
    onCancel: () => void;
    onGoBack: (rules: Rule[], ruleRows: Rule[][], hitPolicy: string, trigger: string) => void;
    onSave: (data: SaveData) => void;
    businessRuleCreateData: FormValues;
    isEdit: boolean;
    isCopy: boolean;
    topError: string[];
    botError: string[];
    setErrorMessage: (position: ErrorPosition, error: string) => void;
    removeErrorMessage: (position: ErrorPosition, error: string) => void;
    clearError: (position: ErrorPosition) => void;
    isSaveTriggered: boolean;
    isReadOnly?: boolean
}

export const BusinessRuleEdit: FC<BusinessRuleEditProps> = (props: BusinessRuleEditProps) => {
    const initWhen: Rule = {
        name: "Select Input",
        isLast: true,
        length: 1,
        type: ColumnType.INPUT,
        isPlaceholder: true
    };
    const initThen: Rule = {
        name: "Select Action",
        isLast: true,
        length: 1,
        type: ColumnType.OUTPUT,
        isPlaceholder: true
    };
    const initComments: Rule = {name: "Comments", value: "", type: ColumnType.COMMENTS};
    const initRule: Rule[] = [
        initWhen,
        initThen,
        initComments];
    const initialRules = [
        [initWhen, initThen, initComments]
    ];

    const {onCancel, onGoBack, businessRuleCreateData, onSave, isEdit = true, isCopy, topError, botError,
        isSaveTriggered, setErrorMessage, removeErrorMessage, clearError, isReadOnly} = props;
    const {ruleName, ruleHeaders, rulesData, status} = businessRuleCreateData;
    const {showModal} = useGlobalModalContext();
    const [triggers, setTriggers] = useState([]);
    const [selectedTrigger, setSelectedTrigger] = useState(businessRuleCreateData?.contextId || null);
    const [inputs, setInputs] = useState([]);
    const [outputs, setOutputs] = useState([]);
    const [rules, setRules] = useState(ruleHeaders || initRule);
    const [hitPolicy, setHitPolicy] = useState(businessRuleCreateData?.hitPolicy || HitPolicy.UNIQUE);
    const [ruleRows, setRuleRows] = useState(rulesData || initialRules);
    const [loading, setLoading] = useState(false);
    const [selectedAdditionalInputs, setSelectedAdditionalInputs] = useState([]);
    const {id} = useParams<BusinessRuleParams>();
    const {user} = useAuth0();
    const userRoles = getRolesFromAuth0User(user);

    const cancelConfirmModal = () => {
        if (!isEdit) {
            onCancel();
        } else {
            showModal(MODAL_TYPES.CONFIRM_MODAL, {
                title: "You have unsaved changes",
                text: "Are you sure you want to continue?",
                onConfirm: onCancel
            });
        }
    };

    useEffect(() => {
        setLoading(true);
        getTriggersData(businessRuleCreateData?.process).subscribe((data) => {
            const triggerData = data?.data;
            if (triggerData) {
                setTriggers(getTriggersDataContent(triggerData));
            }
            setLoading(false);
        });
    }, []);

    useEffect( () => {
        if (selectedTrigger) {
            setLoading(true);
            const client = businessRuleCreateData.ruleType === RuleType.CUSTOM ? businessRuleCreateData.client : null;
            getTriggersContextData(+selectedTrigger, client).subscribe((data: ResponseState) => {
                formik.setFieldValue("trigger", selectedTrigger);
                const triggerContext = data?.data;
                if (triggerContext) {
                    const newInputs = getTriggerInputContext(triggerContext);
                    const filteredInputs = getTriggerInputsWithoutHeaders(newInputs, rules);
                    const additionalInputsIds = businessRuleCreateData.additionalInputIds;
                    const initialSelectedAdditionalInputs = additionalInputsIds?.map(inputId => filteredInputs.find((element: any) => element.id === inputId));
                    setInputs(newInputs);
                    setOutputs(getTriggerActionsContext(triggerContext));
                    if (!selectedAdditionalInputs.length) {
                        setSelectedAdditionalInputs(initialSelectedAdditionalInputs || []);
                    }
                }
                setLoading(false);
            });
        }
    }, [selectedTrigger]);

    useEffect(() => {
        const newSelectedOptions = selectedAdditionalInputs.filter(option => getTriggerInputsWithoutHeaders(inputs, rules)?.some((element: any) => element.value === option.value));
        setSelectedAdditionalInputs(newSelectedOptions);
    }, [inputs, rules]);

    const onHitPolicyChange = (policy: string) => {
        setHitPolicy(policy);
    };

    const defaultValidationRules = {
        trigger: Yup.string().required(getRequiredMessage("Trigger"))
    };

    const defaultValue = {
        trigger: ""
    };

    const selectAdditionalInputs = (inputs: MultiSelectOption[]) => {
        setSelectedAdditionalInputs(inputs);
    };
    const validationSchema = Yup.object().shape(defaultValidationRules);

    const formik = useFormik({
        initialValues: defaultValue,
        onSubmit: (data) => {
            /**/
        },
        validationSchema: validationSchema
    });

    const {errors, touched, values, setFieldTouched} = formik;

    const checkTrigger = () => {
        setFieldTouched("trigger");
    };

    const onRulesSet = (rules: Rule[]) => {
        setRules(rules);
    };

    const onRuleRowsChange = (rules: Rule[][]) => {
        setRuleRows(rules);
    };

    const resolveConfirmModalTitle = () => {
        if (isCopy) {
            return CONFIRMATION_COPY_TO_CUSTOM_RULE_TITLE;
        }
        return _.includes([Status.TEST, Status.DRAFT], status) ? CONFIRMATION_EDIT_HEADER : CONFIRMATION_EDIT_NOT_DRAFT_HEADER;
    };

    const resolveConfirmModalLabel = () => {
        if (isCopy) {
            return CONFIRMATION_COPY_TO_CUSTOM_RULE_LABEL;
        }
        return _.includes([Status.TEST, Status.DRAFT], status) ? CONFIRMATION_EDIT_LABEL : CONFIRMATION_EDIT_NOT_DRAFT_LABEL;
    };

    const isSaveButtonVisible = () => {
        return isMedispendSubDom && userHasAnyRole([USER_ROLE.BR_MEDISPEND_ADMIN], userRoles) && !isReadOnly;
    };

    return (<FormWrapper title={`Rule Editor - ${ruleName || ""}`} controls={<div className="button-group">
        <MSButton onClick={() => onGoBack(rules, ruleRows, hitPolicy, selectedTrigger)} variant="grey-100" size="lg">Go Back</MSButton>
        <MSButton onClick={cancelConfirmModal} variant="grey-100" size="lg">Cancel</MSButton>
        {isSaveButtonVisible() && <MSButton onClick={() => {
            formik.setFieldTouched("trigger");
            if (id) {
                showModal(MODAL_TYPES.CONFIRM_MODAL, {
                    title: resolveConfirmModalTitle(),
                    text: resolveConfirmModalLabel(),
                    onConfirm: () => onSave({ruleRows, policy: hitPolicy, trigger: selectedTrigger, rules, additionalInputs: selectedAdditionalInputs})
                });
            }
            else {
                onSave({ruleRows, policy: hitPolicy, trigger: selectedTrigger, rules, additionalInputs: selectedAdditionalInputs});
            }
        }} variant="green" size="lg">Save</MSButton>}
    </div>}>
        <form onSubmit={formik.handleSubmit}>
            <div className="rule-edit-container">
                <h3>Create the business rule by defining the trigger, inputs and actions.</h3>
                <div className="trigger-selector">
                    <MSFormSelect name="trigger"
                        fieldLabel="Trigger"
                        isRequired
                        error={(touched.trigger && errors.trigger) || ""}
                        options={triggers}
                        placeholder="Select trigger"
                        value={values.trigger}
                        isDisabled={isDisabled(isCopy, isEdit)}
                        handleChange={(value: string) => {
                            setSelectedTrigger(value);
                        }} />
                    <AdditionalInput setSelectedOptions={selectAdditionalInputs}
                        selectedOptions={selectedAdditionalInputs}
                        inputData={getTriggerInputsWithoutHeaders(inputs, rules)}
                        checkTrigger={checkTrigger}
                        disabled={isDisabled(isCopy, isEdit)}
                    />
                </div>
                <CSSTransition in={!!topError.length} timeout={300} unmountOnExit
                    appear classNames="error-list-transition">
                    <ErrorContainer errors={topError} clearError={() => clearError("top")} />
                </CSSTransition>
                <BusinessRuleEditorTable inputs={inputs} outputs={outputs}
                    checkTrigger={checkTrigger} rules={rules}
                    onRulesSet={onRulesSet}
                    hitPolicy={hitPolicy} onHitPolicyChange={onHitPolicyChange}
                    ruleRows={ruleRows} onRuleRowsChange={onRuleRowsChange}
                    isEdit={isEdit}
                    isCopy={isCopy}
                    loading={loading}
                    isSaveTriggered={isSaveTriggered}
                    topError={topError}
                    botError={botError}
                    setErrorMessage={setErrorMessage}
                    removeErrorMessage={removeErrorMessage}
                    clearError={clearError}
                    status={status}
                />
                <CSSTransition in={!!botError.length} timeout={300} unmountOnExit
                    appear classNames="error-list-transition">
                    <ErrorContainer errors={botError} />
                </CSSTransition>
            </div>
        </form>
    </FormWrapper>);
};

import {
    Column,
    FieldValue, FormField,
    InitialValues, ResponseData
} from "@medispend/common/src/formBuilder/types";
import * as _ from "lodash";
import {SelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";
import {getFieldById} from "@medispend/common/src/formBuilder/utils";
import {ResponseState} from "@medispend/common/src/types";
import {CacheablePicklist, PickListResponse} from "../types";

export const hasFieldsChange = (initialValues: InitialValues, currentValues: InitialValues): boolean => {
    return _.isEqualWith(initialValues, currentValues, (a, b) => {
        if ((a === null || a === "") && (b === null || b === "")) {
            return true;
        }
        if (_.isNumber(a) && !_.isNumber(b) ) {
            return a === Number(b);
        }
        if (_.isNumber(b) && !_.isNumber(a)) {
            return b === Number(a);
        }
    });
};

export const extractSectionAndField = (name: string): [string, string] => {
    const sectionAndField: string[] = name.split(".");
    if (sectionAndField.length !== 2) {
        throw Error("Section and field name can not be extracted");
    }

    return [sectionAndField[0], sectionAndField[1]];
};

export const filterSelectOptions = (omitValues: FieldValue, field: FormField, selectOptions: SelectOption[]): boolean => {
    if (omitValues && field?.options) {
        field.options = selectOptions.filter(option => {
            return !(omitValues as string[]).includes(option.value as string);
        });

        return true;
    }

    return false;
};

export const filterRequesterAndCollaboratorOptions = (columns: Column[], requesterFieldId: string, collaboratorFieldId: string,
    setInitialSelectOptions: (options: Record<string, SelectOption[]>) => void) => {
    const requesterField = getFieldById(requesterFieldId, columns);
    const collaboratorField = getFieldById(collaboratorFieldId, columns);

    setInitialSelectOptions({
        [requesterFieldId]: requesterField?.options as SelectOption[],
        [collaboratorFieldId]: collaboratorField?.options as SelectOption[]
    });

    const requesterFieldValue = requesterField?.value || requesterField?.defaultValue;
    if (requesterFieldValue && collaboratorField?.options) {
        filterSelectOptions(requesterFieldValue, collaboratorField, collaboratorField.options as SelectOption[]);
    }
    if (collaboratorField?.value && requesterField?.options) {
        filterSelectOptions(collaboratorField.value, requesterField, requesterField.options as SelectOption[]);
    }
};

export const getOptionsFromPicklistResponse = (cacheablePicklist: CacheablePicklist): {[key: string]: SelectOption[]} => {
    const result: {[key: string]: SelectOption[]} = {};
    for (const key in cacheablePicklist) {
        result[key] = cacheablePicklist[key].map(list => ({
            name: list.itemCode,
            value: list.id,
            uiLabel: list.uiLabel
        }));
    }

    return result;
};

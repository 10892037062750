import {SortSearchParams} from "@medispend/common/src/types";
import {getSortOptions, getSearchOptions, TRANSLATION_KEYS} from "../constants";
import {Translation} from "../state/thunky/types";
import {getTranslationText} from "./translationUtil";

export const getDefaultSort = (value: string, languageTranslations: Translation): SortSearchParams => ({
    title: getTranslationText(TRANSLATION_KEYS.SORTING_SORT_BY, languageTranslations, "Sort By"),
    name: "sort",
    options: getSortOptions(languageTranslations),
    defaultValue: getSortOptions(languageTranslations).find(option => option.value === value)
});

export const getDefaultSearch = (value: string, languageTranslations: Translation): SortSearchParams => ({
    title: getTranslationText(TRANSLATION_KEYS.DASHBOARD_SEARCH_BY, languageTranslations, "Search By"),
    name: "search",
    options: getSearchOptions(languageTranslations),
    defaultValue: getSearchOptions(languageTranslations).find(option => option.value === value)
});

import {DropdownOption} from "@medispend/common/src/types";
import {ColumnType, Rule} from "../../../../types";


export const POLICY = [
    {
        uiLabel: "Unique",
        value: "UNIQUE"
    },
    {
        uiLabel: "Any",
        value: "ANY"
    },
    {
        uiLabel: "Priority",
        value: "PRIORITY"
    },
    {
        uiLabel: "First",
        value: "FIRST"
    },
    {
        uiLabel: "Collect",
        value: "COLLECT"
    },
    {
        uiLabel: "Rule Order",
        value: "RULE_ORDER"
    },
    {
        uiLabel: "Output Order",
        value: "OUTPUT_ORDER"
    }
];

export const BOOLEAN_OPTIONS: DropdownOption[] = [
    {value: "true", uiLabel: "Yes", name: "Yes"},
    {value: "false", uiLabel: "No", name: "No"},
    {value: "-", uiLabel: "-", name: "-"}
];

export const initWhen: Rule = {
    name: "Select Input",
    value: "",
    isLast: true,
    length: 1,
    type: ColumnType.INPUT,
    isPlaceholder: true
};

export const initThen: Rule = {
    name: "Select Action",
    value: "",
    isLast: true,
    length: 1,
    type: ColumnType.OUTPUT,
    isPlaceholder: true
};

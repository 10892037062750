import shortUUID from "short-uuid";

/**
 * Return a short UUID or UUIDv4
 *
 * @param {Boolean} short=false: whether to return a short uuid such as "mhvXdrZT4jP5T8vBxuvm75" or not.
 * @return {String}
 */
const uuid = (short: boolean = false): string => (shortUUID())[short ? "new" : "uuid"]();
const uuidv4 = uuid;
const short = () => uuid(true);

export {
    short, uuid, uuidv4
};

import React, {FunctionComponent} from "react";
import {Cell, Row, RowPropGetter} from "react-table";

export interface BodyProps {
    getRowProps?: (row: Row) => RowPropGetter<any>;
    getTableBodyProps: () => any;
    prepareRow: (row: Row) => void;
    rows: Row[];
    scrollable?: boolean;
}

export const Body: FunctionComponent<BodyProps> = (props) => {
    const {
        getTableBodyProps,
        getRowProps,
        prepareRow,
        rows,
        scrollable = false
    } = props;
    return (
        <tbody className="ms-table-body" {...getTableBodyProps()}>
            {rows.map((row: Row, index) => {
                prepareRow(row);
                const trProps = getRowProps ? row.getRowProps(getRowProps(row)) : row.getRowProps();
                return (
                    <tr key={index} {...trProps}>
                        {row.cells.map((cell: Cell, index) => {
                            const minWidth = scrollable ? (cell.column.minWidth || cell.column.width) : cell.column.minWidth;
                            return (
                                <td key={index} {...cell.getCellProps({
                                    style: {
                                        minWidth,
                                        width: cell.column.width
                                    }
                                })}>
                                    {cell.render("Cell")}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

import React, {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import {getAlphaNumericMessage, getRequiredMessage, getStringMaxLengthMessage} from "@medispend/common/src/constants";
import MSInput from "@medispend/common/src/components/MSInput";
import MSTextArea from "@medispend/common/src/components/MSTextArea";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {MSDatepicker} from "@medispend/common/src/components/MSDatepicker";
import {RULE_TYPE_OPTIONS, STATUS_OPTIONS} from "../../constants";
import {SelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";
import {Rule, RuleType, Status} from "../../../types";
import {useGlobalModalContext} from "@medispend/common/src/components/MSGlobalModal";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";
import {getYesterdayTimestamp} from "@medispend/common/src/utils";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";

import "./scss/style.scss";
import {isClientDropdownDisabled, isDisabled} from "../../../helpers/businessRules";


export interface BusinessRuleCreateProps {
    onCancel: () => void;
    onApprove: (data: FormValues) => void;
    processes: SelectOption[],
    applications: SelectOption[],
    clients: SelectOption[],
    businessRuleCreateData: FormValues | null
    ruleId?: number;
    isEdit?: boolean;
    isCopy?: boolean;
    isReadOnly?: boolean;
}

export interface FormValues {
    ruleName?: string;
    ruleDescription?: string;
    ruleType?: string;
    application?: string | number;
    process?: string | number;
    client?: string;
    effectiveDate?: number | null;
    expiryDate?: number | null;
    status?: Status;
    ruleHeaders?: Rule[];
    rulesData?: Rule[][];
    contextId?: string;
    hitPolicy?: string;
    additionalInputIds?: string[];
    version?: number;
    minorVersion?: number;
}

export const BusinessRuleCreate: FC<BusinessRuleCreateProps> = (props: BusinessRuleCreateProps) => {
    const {onCancel, onApprove, processes = [], applications = [], clients = [], businessRuleCreateData, isEdit = true,
        isCopy, isReadOnly} = props;

    const defaultVal: FormValues = {
        ruleName: businessRuleCreateData?.ruleName || "",
        ruleDescription: businessRuleCreateData?.ruleDescription || "",
        ruleType: businessRuleCreateData?.ruleType || "",
        application: businessRuleCreateData?.application || 1,
        process: businessRuleCreateData?.process || 1,
        client: businessRuleCreateData?.client || "",
        effectiveDate: businessRuleCreateData?.effectiveDate || null,
        expiryDate: businessRuleCreateData?.expiryDate || null,
        status: businessRuleCreateData?.status || Status.DRAFT
    };
    const [defaultValue, setDefaultValue] = useState(defaultVal);
    useEffect(() => {

        if (businessRuleCreateData) {
            setDefaultValue({...defaultVal, ...businessRuleCreateData});
        }
    }, [businessRuleCreateData, isCopy]);
    const {showModal} = useGlobalModalContext();

    const cancelConfirmModal = () => {
        if (!isEdit) {
            onCancel();
        }
        else {
            showModal(MODAL_TYPES.CONFIRM_MODAL, {
                title: "You have unsaved changes",
                text: "Are you sure you want to continue?",
                onConfirm: onCancel
            });
        }
    };

    const defaultValidationRules = {
        ruleName: Yup.string().trim().max(255, getStringMaxLengthMessage("Rule Name", 255))
            .matches(/[a-zA-Z0-9 ]/, getAlphaNumericMessage("Rule Name")).required(getRequiredMessage("Rule Name")),
        ruleDescription: Yup.string().trim().matches(/[a-zA-Z0-9 ]/, getAlphaNumericMessage("Description"))
            .required(getRequiredMessage("Description")),
        ruleType: Yup.string().required(getRequiredMessage("Rule Type")),
        application: Yup.mixed().required(getRequiredMessage("Application")),
        process: Yup.mixed().required(getRequiredMessage("Process")),
        status: Yup.mixed().required(getRequiredMessage("Status")),
        client: Yup.string().when(["ruleType"], {
            is: (ruleType: string) => ruleType === "CUSTOM",
            then: Yup.string().required(getRequiredMessage("Client"))
        })
    };
    const validationSchema = Yup.object().shape(defaultValidationRules);
    const [schema, updateSchema] = useState(validationSchema);

    const formik = useFormik({
        initialValues: defaultValue,
        onSubmit: (data) => {
            onApprove(data);
        },
        validationSchema: schema,
        enableReinitialize: true
    });

    const {errors, touched, values} = formik;

    return (<>
        <form onSubmit={formik.handleSubmit}>
            <div className="create-rule-container">
                <div className="form-container">
                    <div className="create-rule-header">
                        <h2>Rule Details</h2>
                    </div>
                    <div className="create-rule-form-container">
                        <div className="create-rule-form">
                            <h3>Name and describe the rule you want to create.</h3>
                            <MSInput
                                name="ruleName"
                                fieldLabel="Rule Name"
                                isRequired
                                error={(touched.ruleName && errors.ruleName) || ""}
                                value={formik.values.ruleName}
                                handleChange={(value: string) => {
                                    formik.setFieldValue("ruleName", value);
                                }}
                                variant="form"
                                isDisabled={!(isEdit && isMedispendSubDom)}
                            />
                            <MSTextArea
                                name="ruleDescription"
                                value={formik.values.ruleDescription}
                                fieldLabel="Rule Description"
                                isRequired
                                isDisabled={!isMedispendSubDom || isReadOnly}
                                error={(touched.ruleDescription && errors.ruleDescription) || ""}
                                handleChange={(value: string) => {
                                    formik.setFieldValue("ruleDescription", value);
                                }}
                                maxFieldLength={2500} />
                            <div className="field-group-container">
                                <div className="business-rule-type">
                                    <MSFormSelect
                                        name="ruleType"
                                        activeVariant={null}
                                        options={RULE_TYPE_OPTIONS}
                                        value={values.ruleType}
                                        fieldLabel="Rule Type"
                                        placeholder="Select Rule Type"
                                        isRequired
                                        isDisabled={isCopy || isDisabled(isCopy, isEdit)}
                                        error={(touched.ruleType && errors.ruleType) || ""}
                                        placeVariant="form"
                                        handleChange={(value: string) => {
                                            formik.setFieldValue("ruleType", value);
                                            formik.setFieldValue("client", "");
                                        }}
                                        variant={ButtonColors.grey200}
                                    />
                                </div>
                                { (formik.values.ruleType === RuleType.CUSTOM) && <div className="business-rule-client">
                                    <MSFormSelect
                                        name="client"
                                        activeVariant={null}
                                        options={clients}
                                        value={values.client}
                                        fieldLabel="Client"
                                        placeholder="Select Client"
                                        isRequired
                                        isDisabled={isClientDropdownDisabled(values.client, values.status, isCopy)}
                                        error={(touched.client && errors.client) || ""}
                                        placeVariant="form"
                                        handleChange={(value: string) => {
                                            formik.setFieldValue("client", value);
                                        }}
                                        variant={ButtonColors.grey200}
                                    />
                                </div>}
                            </div>
                            <h3>Where will this rule be used?</h3>
                            <div className="field-group-container">
                                <div className="business-rule-process">
                                    <MSFormSelect
                                        name="process"
                                        activeVariant={null}
                                        options={processes}
                                        value={values.process}
                                        fieldLabel="Process"
                                        placeholder="Select Process"
                                        isRequired
                                        isDisabled={isDisabled(isCopy, isEdit)}
                                        error={(touched.process && errors.process) || ""}
                                        placeVariant="form"
                                        handleChange={(value) => {formik.setFieldValue("process", value);}}
                                        variant={ButtonColors.grey200}
                                    />
                                </div>
                                <div className="business-rule-application">
                                    <MSFormSelect
                                        name="application"
                                        activeVariant={null}
                                        options={applications}
                                        value={values.application}
                                        fieldLabel="Application"
                                        placeholder="Select Application"
                                        isRequired
                                        isDisabled={isDisabled(isCopy, isEdit)}
                                        error={(touched.application && errors.application) || ""}
                                        placeVariant="form"
                                        handleChange={(value) => formik.setFieldValue("application", value)}
                                        variant={ButtonColors.grey200}
                                    />
                                </div>
                            </div>
                            <h4>Define the status, effective date, and expiration date for this rule.</h4>
                            <div className="field-group-container">
                                <div className="business-rule-status">
                                    <MSFormSelect
                                        name="status"
                                        activeVariant={null}
                                        options={STATUS_OPTIONS}
                                        value={isCopy ? "DRAFT" : values.status}
                                        fieldLabel="Default Status"
                                        placeholder={values.status || "Select Status"}
                                        isRequired
                                        isDisabled={isDisabled(isCopy, isEdit)}
                                        error={(touched.status && errors.status) || ""}
                                        placeVariant="form"
                                        handleChange={(value) => formik.setFieldValue("status", value)}
                                        variant={ButtonColors.grey200}
                                    />
                                </div>
                                <div>
                                    <MSDatepicker onChange={(value) => {
                                        formik.setFieldValue("effectiveDate", value);
                                    }}
                                    options={{value: formik.values.effectiveDate, name: "effectiveDate"}}
                                    label="Effective Date" isOpenUp isDropdown placeholder="/ /"
                                    startLimit={getYesterdayTimestamp()} endLimit={+formik.values.expiryDate || null}
                                    isDisabled={!isMedispendSubDom || isReadOnly} />
                                </div>
                                <div>
                                    <MSDatepicker onChange={(value) => {formik.setFieldValue("expiryDate", value); }}
                                        options={{value: formik.values.expiryDate, name: "expirationDate"}}
                                        label="Expiration Date" isOpenUp isDropdown placeholder="/ /" startLimit={+formik.values.effectiveDate || getYesterdayTimestamp()}
                                        isDisabled={!isMedispendSubDom || isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group">
                    <MSButton onClick={cancelConfirmModal} variant="grey-100" size="lg">Cancel</MSButton>
                    <MSButton onClick={formik.handleSubmit} variant="green" size="lg">Continue</MSButton>
                </div>
            </div>
        </form>
    </>
    );
};

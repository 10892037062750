import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import * as QueryString from "query-string";

import {AuthenticationActionType} from "@medispend/common/src/constants/authentication";


/**
 * Run Auth0 service, which returns authenticated info
 *
 *  @param options:
 *      returnTo - link, where you can redirect users to a specific URL after they logout
 *      audience - the resource server that a client would like to access
 *
 *  @returns accessToken, user - object with user's info, logOutHandler - will run when users logout
 */
export const useAuth0AccessToken = (options: {returnTo: string}) => {
    const {user, getAccessTokenSilently, logout, error, loginWithRedirect, isLoading, isAuthenticated} = useAuth0();
    const dispatch = useDispatch();
    const removeCacheData = async() => {
        try {
            await sessionStorage.removeItem("ACCESS_TOKEN");
        } catch {
            return true;
        }
    };

    const logOutHandler = async() => {
        await removeCacheData();
        logout({returnTo: options.returnTo});
    };

    /**
     * saves user's info from auth0 to store
     */
    useEffect(() => {
        user && dispatch({type: AuthenticationActionType.SET_AUTH_INFO, payload: {user, logOutHandler}});
    }, [user, logOutHandler]);

    const [accessToken, setAccessToken] = useState(null);

    const getAccessToken = async() => {
        try {
            const accessToken = await getAccessTokenSilently(options);
            await sessionStorage.setItem("ACCESS_TOKEN", `Bearer ${accessToken}`);
            setAccessToken(`Bearer ${accessToken}`);
        } catch (err) {
            const error_description = String(QueryString.parse(window.location.href).error_description || "Error : Contact Administrator");
            !error && loginWithRedirect();
            error && loginWithRedirect({
                login_error: "1",
                error_description : error_description
            });
        }
    };

    /**
     * gets accessToken and sets to state
     */
    useEffect(() => {
        const cacheAccessToken = sessionStorage.getItem("ACCESS_TOKEN");
        (!cacheAccessToken || (!isLoading && !isAuthenticated)) && getAccessToken();
        cacheAccessToken && setAccessToken(cacheAccessToken);
    }, [isLoading, isAuthenticated]);

    return {accessToken, user, logOutHandler};
};

import React, {
    FC,
    useState,
    useEffect,
    useMemo
} from "react";

import {ButtonSizes, ButtonColors, ButtonTypes} from "../MSButton/types";
import {MultiSelectOption, MenuTypes, Values, BaseCustomDropdownButtonProps} from "../MSSelect/BaseSelect/types";
import {BaseSelect} from "../MSSelect/BaseSelect";
import {cropString} from "../../utils/string/stringLimit";


export interface MSMultiSelectProps<T extends BaseCustomDropdownButtonProps = BaseCustomDropdownButtonProps> {
    options: MultiSelectOption[];
    title?: string;
    disabled?: boolean;
    highlighted?: boolean;
    placeholder?: string;
    size?: keyof typeof ButtonSizes;
    menuType?: keyof typeof MenuTypes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    defaultValues?: Values[];
    onChange: (optionData: MultiSelectOption[]) => void;
    customDropdownButton?: React.ForwardRefExoticComponent<T>
    isLazy?: boolean
}

export const MSMultiSelect: FC<MSMultiSelectProps> = (props) => {
    const {
        options,
        title,
        disabled,
        placeholder = "",
        buttonType = ButtonTypes.default,
        variant = ButtonColors.green,
        activeVariant = null,
        size = ButtonSizes.md,
        menuType = MenuTypes.list,
        defaultValues,
        onChange,
        customDropdownButton = null,
        isLazy = true
    } = props;

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (defaultValues?.length) {
            const foundOptions = options.filter(o => defaultValues.includes(o.value));
            setSelectedOptions(foundOptions);
        } else {
            setSelectedOptions([]);
        }
    }, [defaultValues, options]);

    const dropdownTitle = useMemo(() => {
        const optionsTitle = selectedOptions.map(o => o.uiLabel).join(", ");

        const preparedTitle = title && selectedOptions.length
            ? `${title}: ${optionsTitle}`
            : title;

        const preparedPlaceholder = placeholder && selectedOptions.length
            ? optionsTitle
            : placeholder;

        return cropString(!title
            ? preparedPlaceholder
            : preparedTitle, 20);
    }, [placeholder, selectedOptions, title]);

    const handleClick = (optionData: MultiSelectOption) => {
        const optionsExists = selectedOptions.some(o => (
            o.value === optionData.value
        ));

        const nextOptions = optionsExists
            ? selectedOptions.filter(o => o.value !== optionData.value)
            : [...selectedOptions, optionData];
        setSelectedOptions(nextOptions);
        onChange(nextOptions);
    };

    const activeSelectVariant = useMemo(() => (
        activeVariant || variant
    ), [activeVariant, variant]);
    return (
        <BaseSelect
            disabled={disabled}
            selectedOptions={selectedOptions}
            options={options}
            activeVariant={selectedOptions.length ? activeSelectVariant : null}
            dropdownTitle={dropdownTitle}
            buttonType={buttonType}
            menuType={menuType}
            variant={variant}
            size={size}
            onClick={handleClick}
            customDropdownButton={customDropdownButton}
            isLazy={isLazy}
        />
    );
};

import React, {FunctionComponent} from "react";
import {Route, Switch} from "react-router-dom";

import {Dashboard} from "./Dashboard";
import {BusinessRule} from "./Dashboard/BusinessRule";
import {getAllowedSubdomainsFromAuth0User, getRolesFromAuth0User} from "@medispend/admin-common/src/utils/auth0Util";
import {userHasRole} from "@medispend/common/src/utils";
import {ACCESS_DENIED, USER_ROLE} from "./constants";
import {useAuth0} from "@auth0/auth0-react";
import {isMedispendSubDom, subdomain} from "@medispend/admin-common/src/env";


export const App: FunctionComponent = () => {

    const {user} = useAuth0();
    const userRoles = getRolesFromAuth0User(user);
    const subdomains = getAllowedSubdomainsFromAuth0User(user);

    if (userHasRole(USER_ROLE.BR_MEDISPEND_ADMIN, userRoles) ||
        (!isMedispendSubDom && userHasRole(USER_ROLE.BR_CLIENT_ADMIN, userRoles) && subdomains.includes(subdomain))) {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={() => <Dashboard />} />
                    <Route exact path="/rules" component={BusinessRule} />
                    <Route exact path="/rules/:id/:clientName/:ruleType" component={BusinessRule} />
                    <Route exact path="/rules/:id/copy" component={BusinessRule} />
                </Switch>
            </div>
        );
    } else {
        return (<div>{ACCESS_DENIED}</div>);
    }
};

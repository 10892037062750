import React from "react";
import {FilterValue} from "../../../types";

export interface SelectOption {
    uiLabel?: string;
    value: FilterValue;
    href?: string;
    route?: string;
    inactive?: boolean;
}

export type Values = string | number | boolean;

export interface MultiSelectOption {
    uiLabel?: string;
    value: Values;
    href?: string;
    name?: string;
}

export interface BaseCustomDropdownButtonProps {
    onClick: (e: React.MouseEvent) => void
}

export enum MenuTypes {
    list = "list",
    checkbox = "checkbox",
}

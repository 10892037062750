import React, {FunctionComponent, memo, useMemo} from "react";

import {DropdownOption, FilterItem, FilterParams, FilterValue} from "../../types";
import {MultiSelectOption, SelectOption} from "../MSSelect/BaseSelect/types";
import {MSMultiSelectProps} from "../MSMultiSelect";
import {MSSelectProps} from "../MSSelect";
import {MSIcon} from "../MSIcon";
import {AddFilter} from "./AddFilter";


interface FilterProps {
    availableFilters?: Array<FilterParams>;
    filtersValues?: Array<FilterItem<FilterValue>>;
    onFilterChange?: (filterValue: any) => void;
    onFilterAdd?: (optionData: MultiSelectOption[]) => void;
    onFilterClear?: () => void,
    additionalFilters?: any;
}

type PickedSelectProps = Pick<MSSelectProps, "defaultValue" | "onChange" | "isLazy">
type PickedMultiSelectProps = Pick<MSMultiSelectProps, "defaultValues" | "onChange" | "menuType" | "isLazy">

export const FiltersList: FunctionComponent<FilterProps> = memo((props) => {
    const {
        availableFilters,
        filtersValues,
        onFilterChange,
        onFilterAdd = null,
        onFilterClear = null,
        additionalFilters = null
    } = props;

    const addFilterLabel = "Add Filter";
    const clearFilterLabel = "Clear All";

    const options = useMemo(() => (filterOptions: DropdownOption[]) => (
        filterOptions.map(o => ({uiLabel: o.uiLabel, value: o.value, name: o.name}))
    ), []);

    const defaultOptions = useMemo(() => (filterName: string) => {
        const matchedOption = filtersValues.find((filter) => filter?.name === filterName);

        return matchedOption?.value;
    }, [filtersValues]);

    const renderFilter = (filterParam: FilterParams) => {
        const FilterComponent = filterParam.component;

        const handleMultiFilterChange = (optionData: SelectOption[]) => {
            onFilterChange({
                name: filterParam.name,
                value: optionData.map(o => o.value as string)
            });
        };

        const handleFilterChange = (optionData: SelectOption) => {
            const data = filterParam.isDate ? optionData : optionData.value;
            onFilterChange({
                name: filterParam.name,
                value: data
            });
        };

        const filterProps = filterParam.isMulti
            ? ({
                menuType: "checkbox",
                defaultValues: defaultOptions(filterParam.name) as FilterValue[],
                onChange: handleMultiFilterChange,
                isLazy: false
            } as PickedMultiSelectProps)
            : ({
                defaultValue: defaultOptions(filterParam.name) as FilterValue,
                onChange: handleFilterChange,
                isLazy: false
            } as PickedSelectProps);

        return (
            <FilterComponent
                disabled={filterParam.isDisabled}
                key={filterParam.title}
                buttonType="pill"
                variant="grey-200"
                activeVariant="grey-800"
                title={filterParam.title}
                defaultTitle={filterParam.defaultTitle}
                options={options(filterParam.options)}
                {...filterProps}
            />
        );
    };

    return (
        <div className="ms-table-filters-list">
            {availableFilters.map(renderFilter)}
            {(onFilterAdd && additionalFilters) && <AddFilter uiLabel={addFilterLabel}
                availableFilters={availableFilters}
                additionalFilters={additionalFilters} onFilterAdd={onFilterAdd} />}
            {onFilterClear && (<div className="ms-table-filter-clear" data-testid="filter-clear" onClick={onFilterClear}>
                <MSIcon icon="far fa-times-circle" />
                <span>{clearFilterLabel}</span>
            </div>)}
        </div>
    );
}, (prevProps, nextProps) => (
    prevProps.onFilterChange === nextProps.onFilterChange
        && prevProps.filtersValues === nextProps.filtersValues
        && prevProps.availableFilters === nextProps.availableFilters
));
FiltersList.displayName = "FiltersList";

import React from "react";
import {toast, Slide, ToastContentProps} from "react-toastify";
import {ResponseState} from "../../types";


export enum MessagesType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error"
}

interface NotificationBodyProps {
    type: MessagesType,
    message: string;
    closeToast: () => void;
}

const NotificationBody = ({type, message, closeToast}: NotificationBodyProps) => {
    return (
        <div className="notification-body">
            <div className="notification-message">{message}</div>
            {type !== MessagesType.SUCCESS && (<button className="notification-button" onClick={closeToast}>Ok</button>)}
        </div>
    );
};

const getMessageComponent = (message: string, type: MessagesType) => function getNotificationBody({closeToast}: ToastContentProps) {
    return (
        <NotificationBody type={type} message={message} closeToast={closeToast} />
    );
};

const notification = (message: string, type: MessagesType): void => {
    toast(getMessageComponent(message, type), {
        position: "top-center",
        delay: 500,
        autoClose: type === MessagesType.SUCCESS ? 4000 : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide,
        className: `notification-type-${type}`
    });
};

export const notifyResponseError = (response: ResponseState, message?: string): void => {
    const errorMessage = message
        || response.error?.response?.message
        || 'Something went wrong while loading the data. Please refresh the page and try again.';
    notification(errorMessage, MessagesType.ERROR);
}

export default notification;

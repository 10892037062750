import {applyMiddleware, combineReducers, createStore, Reducer, Store, Middleware} from "redux";
import {createLogger} from "redux-logger";
import {connectRouter, push, routerMiddleware} from "connected-react-router";
import {createBrowserHistory, createHashHistory, History} from "history";

import * as LayoutState from "../state";

interface LayoutStore {
    pushTo: (dest: string) => void;
    history: History;
    store: Store;
}

export const createLayoutStore = (
    reducers?: Record<string, Reducer>,
    externalMiddlewares: Middleware[] = [],
    hashHistory = true
): LayoutStore => {
    const history: History = hashHistory
        ? createHashHistory()
        : createBrowserHistory();

    const middlewares = [
        ...externalMiddlewares,
        routerMiddleware(history)
    ];

    if (process.env.NODE_ENV === "development") {
        middlewares.push(createLogger());
    }

    const store: Store = createStore(
        combineReducers({
            router: connectRouter(history),
            [LayoutState.REDUCER_NAMESPACE_LAYOUT]: LayoutState.layoutReducer,
            ...reducers
        }),
        applyMiddleware(...middlewares)
    );

    const pushTo = (destination: string): void => {
        store.dispatch(push(destination));
    };

    return {pushTo, history, store};
};

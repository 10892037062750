import React, {FunctionComponent, useMemo, useState} from "react";
import {Cell, useExpanded, useTable, UseTableOptions, Row, RowPropGetter} from "react-table";
import {Scrollbars} from "react-custom-scrollbars-2";
import {Body} from "./Body";
import {TableTools} from "./TableTools";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {PaginationProps} from "./Pagination";
import {PageSizeProps} from "./PageSize";
import {FiltersList} from "./FiltersList";
import {MSLoader} from "../MSLoader";
import {
    DropdownOption,
    FilterParams,
    FilterItem,
    FilterValue,
    SortSearchParams
} from "../../types";

import "./scss/_Table.scss";
import {MultiSelectOption} from "../MSSelect/BaseSelect/types";
import {MSCheckbox} from "../MSCheckbox";


export interface MSTableProps extends UseTableOptions<Record<string, unknown>> {
    showFooter?: boolean;
    showHeader?: boolean;
    showSearchFilter?: boolean;
    showFilters?: boolean;
    showSorting?: boolean;
    availableFilters?: Array<FilterParams>;
    sortParams?: SortSearchParams;
    searchParams?: SortSearchParams;
    searchType?: FilterItem<string>;
    filtersValues?: Array<FilterItem<FilterValue>>;
    sort?: FilterItem<string>;
    pagination?: PaginationProps;
    pageSize?: number;
    pageSizeOpts?: PageSizeProps;
    showPagination?: boolean;
    onFilterChange?: (filterValue: DropdownOption) => void;
    onToggleSort?: (sortValue: DropdownOption) => void;
    onSearchTextChange?: (search: string) => void;
    showSearchSwitcher?: boolean;
    showSearchName?: boolean;
    textSearchPlaceholder?: string;
    textSearchByPlaceholder?: string;
    loading?: boolean;
    onFilterAdd?: (optionData: MultiSelectOption[]) => void;
    onFilterClear?: () => void;
    additionalFilters?: DropdownOption[];
    clearSearchWithFilters?: boolean;
    searchValue?: string;
    id?: string;
    showRowCheckboxes?: boolean;
    showSelectAllRowsCheckbox?: boolean;
    omitCheckboxRowIndices?: Array<number>;
    onRowCheckboxClick?: (active: boolean, rowIndex: number) => void;
    onSelectAllRowsCheckboxClick?: (active: boolean) => void;
    getRowProps?: (row: Row) => RowPropGetter<any>;
    scrollable?: boolean;
    filterButtons?: React.ReactNode;
}

export const MSTable: FunctionComponent<MSTableProps> = (props) => {
    const {
        showFooter = true,
        showHeader = true,
        showSearchFilter = true,
        showSearchSwitcher,
        textSearchByPlaceholder,
        showSearchName,
        textSearchPlaceholder,
        showFilters = true,
        availableFilters,
        filtersValues,
        columns,
        data,
        onFilterChange,
        pageSizeOpts,
        pagination = {} as PaginationProps,
        showPagination,
        showSorting,
        sort,
        searchType,
        sortParams,
        searchParams,
        onToggleSort,
        onSearchTextChange,
        loading,
        onFilterAdd = null,
        onFilterClear = null,
        additionalFilters = null,
        clearSearchWithFilters = false,
        searchValue = "",
        id = "",
        showRowCheckboxes = false,
        showSelectAllRowsCheckbox = false,
        omitCheckboxRowIndices = null,
        onRowCheckboxClick,
        onSelectAllRowsCheckboxClick,
        getRowProps,
        scrollable = false,
        filterButtons
    } = props;

    const [selectedRowIndices, setSelectedRowIndices] = useState([]);

    const handleRowCheckboxClick = (active: boolean, rowIndex: number) => {
        onRowCheckboxClick && onRowCheckboxClick(active, rowIndex);
        let updatedRowIndices: number[];
        if (active) {
            updatedRowIndices = [...selectedRowIndices, rowIndex];
        } else {
            updatedRowIndices = selectedRowIndices.filter(id => id !== rowIndex);
        }
        setSelectedRowIndices(updatedRowIndices);
    };

    const handleSelectAllRowsCheckboxClicked = (active: boolean) => {
        onSelectAllRowsCheckboxClick && onSelectAllRowsCheckboxClick(active);
        let updatedRowIndices: number[];
        if (active) {
            updatedRowIndices = Array.from(Array( data.length).keys());
        } else {
            updatedRowIndices = [];
        }

        setSelectedRowIndices(updatedRowIndices);
    };

    const updatedColumns = useMemo(() => {
        if (showRowCheckboxes) {
            return [{
                id: "row-checkbox",
                Header: showSelectAllRowsCheckbox && <MSCheckbox onClick={handleSelectAllRowsCheckboxClicked} />,
                width: 5,
                Cell: function getCheckbox(cell: Cell): JSX.Element {
                    const rowIndex = cell.row.index;
                    const shouldShowCheckbox = omitCheckboxRowIndices ? !omitCheckboxRowIndices.includes(rowIndex) : true;
                    return shouldShowCheckbox ? <MSCheckbox
                        key={rowIndex}
                        active={selectedRowIndices.includes(rowIndex)}
                        onClick={(active: boolean) => handleRowCheckboxClick(active, rowIndex)}
                    /> : null;
                }
            }, ...columns];
        } else {
            return [...columns];
        }
    }, [columns, selectedRowIndices]);

    const {
        rows,
        headerGroups,
        getTableProps,
        getTableBodyProps,
        prepareRow
    } = useTable({columns: updatedColumns, data}, useExpanded);

    const preparedPageSizeOpts = useMemo(() => ({
        ...pageSizeOpts,
        pageSize: !pageSizeOpts?.pageSize
            ? pagination?.pageSize
            : pageSizeOpts?.pageSize
    }), [pageSizeOpts, pagination?.pageSize]);

    const table = <table {...getTableProps()}>
        {showHeader && <Header headerGroups={headerGroups} />}
        <Body
            getRowProps={getRowProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            scrollable={scrollable}
            rows={rows}
        />
    </table>;

    return (
        <div className="ms-table" id={id}>
            {loading && <MSLoader />}
            {showSearchFilter && (
                <TableTools
                    pageSizeOpts={preparedPageSizeOpts}
                    showSorting={showSorting}
                    sort={sort}
                    searchType={searchType}
                    sortParams={sortParams}
                    searchParams={searchParams}
                    showSearchSwitcher={showSearchSwitcher}
                    textSearchByPlaceholder={textSearchByPlaceholder}
                    showSearchName={showSearchName}
                    textSearchPlaceholder={textSearchPlaceholder}
                    onToggleSort={onToggleSort}
                    onSearchTextChange={onSearchTextChange}
                    onFilterChange={onFilterChange}
                    clearSearchWithFilters={clearSearchWithFilters}
                    searchValue={searchValue}
                />
            )}
            <div className="ms-table-filters-wrapper">
                {showFilters && (
                    <FiltersList
                        availableFilters={availableFilters}
                        filtersValues={filtersValues}
                        onFilterChange={onFilterChange}
                        onFilterAdd={onFilterAdd}
                        onFilterClear={onFilterClear}
                        additionalFilters={additionalFilters}
                    />
                )}
                {filterButtons && <div className="ms-table-filters-buttons">{filterButtons}</div>}
            </div>
            <div className="ms-table-wrapper">
                {scrollable ? (
                    <Scrollbars autoHeight autoHeightMax={Number.MAX_SAFE_INTEGER}>
                        {table}
                    </Scrollbars>
                ) : (
                    table
                )}
            </div>
            {showFooter && (
                <Footer pagination={pagination} showPagination={showPagination} pageSizeOpts={pageSizeOpts} />
            )}
        </div>
    );
};

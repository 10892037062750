import {createReducer} from "@reduxjs/toolkit";

import {AuthenticationActionType} from "@medispend/common/src/constants/authentication";
import {UserProfileStateData} from "@medispend/common/src/authentication/auth0/types";


interface AuthState {
    user: UserProfileStateData,
    logOutHandler: () => void
}

export const initialState: AuthState = {
    user: null,
    logOutHandler: () => {}
};

const authReducer = createReducer(initialState, {
    [AuthenticationActionType.SET_AUTH_INFO]: (state, {payload}: {payload: AuthState}) => {
        state = {...state, ...payload};
        return state;
    }
});

export default authReducer;

import React, {FunctionComponent} from "react";
import classNames from "classnames";

import {IconTypes, MSIcon} from "../MSIcon";

import "./scss/_LoadingIndicator.scss";

export interface MSLoaderProps {
    className?: string;
    size?: "lg" | "2x" | "3x" | "4x" | "5x";
}

export const MSLoader: FunctionComponent<MSLoaderProps> = ({className, size = "3x"}) => {
    return (
        <div className="ms-loading-indicator-container">
            <div className={classNames("ms-loading-indicator", className)}>
                <MSIcon data-testid="ms-loader" icon="spinner" iconType={IconTypes.FA} className={`fa-pulse fa-${size} fa-fw`} />
            </div>
        </div>
    );
};

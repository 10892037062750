import classNames from "classnames";
import React, {FunctionComponent, MouseEvent, ReactNode, useEffect} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Location} from "history";

import {User} from "../../types";
import {IconTypes, MSIcon} from "../MSIcon";
import {LAYOUT_LOADING_SIDENAV, LayoutLoadingPayload} from "./state";
import {LayoutSideMenu, LayoutSideMenuProps} from "./SideMenu";


export interface LayoutSideNavProps {
    compactView?: boolean;
    layoutLoading?: LayoutLoadingPayload;
    location?: Location;
    user?: User;
    sideTop?: string | ReactNode;
    sideBottom?: string | ReactNode;
    menus?: LayoutSideMenuProps[];
    expandTooltipText?: string;
    collapseTooltipText?: string;
    onViewModeCompact?: () => void;
    onViewModeFull?: () => void;
    viewModeCompact?: () => void;
    viewModeFull?: () => void;
}

export interface LayoutViewModeToggleProps {
    compactView: boolean;
    sidebarExpanded?: boolean;
    expandTooltipText?: string;
    collapseTooltipText?: string;
    viewModeFull: () => void;
    viewModeCompact: () => void;
    onViewModeFull?: () => void;
    onViewModeCompact?: () => void;
}

const viewModeToggle = (props: LayoutViewModeToggleProps): void => {
    const {compactView, viewModeFull, viewModeCompact, onViewModeFull, onViewModeCompact} = props;
    if (compactView) {
        viewModeFull();
        onViewModeFull && onViewModeFull();
    } else {
        viewModeCompact();
        onViewModeCompact && onViewModeCompact();
    }
};

const LayoutSideNavViewModeTrigger: FunctionComponent<LayoutViewModeToggleProps> = (props) => {
    const {
        compactView,
        sidebarExpanded,
        expandTooltipText,
        collapseTooltipText,
        viewModeFull,
        viewModeCompact
    } = props;

    useEffect(() => {
        if (sidebarExpanded) {
            viewModeFull && viewModeFull();
        } else {
            viewModeCompact && viewModeCompact();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarExpanded]);


    const onViewModeChange = (event: MouseEvent): void => {
        event && event.preventDefault();
        viewModeToggle(props);
    };

    const expandText = expandTooltipText || "Expand sidebar";
    const collapseText = collapseTooltipText || "Collapse sidebar";
    const tip = compactView ? `${expandText} (])` : `${collapseText} ([)`;
    return (
        <div className="action-trigger-container">
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="about-window-trigger">{tip}</Tooltip>}
            >
                <a className="action-trigger view-mode-trigger link" href="#" onClick={onViewModeChange}>
                    {compactView ? (
                        <MSIcon data-testid="right-arrow-icon" icon="angle-double-right" />
                    ) : (
                        <MSIcon data-testid="left-arrow-icon" icon="angle-double-left" />
                    )}
                </a>
            </OverlayTrigger>
        </div>
    );
};

type LayoutSideNavMenuType = {
    menus: LayoutSideMenuProps[];
    compactView: boolean;
    location?: Location;
}

const LayoutSideNavMenu: FunctionComponent<LayoutSideNavMenuType> = ({menus, compactView, location}: LayoutSideNavMenuType) => {
    const allMenus = menus.map((menu: LayoutSideMenuProps, idx: number) => {
        return (
            <LayoutSideMenu
                key={idx}
                compactView={compactView}
                location={location}
                {...menu}
            />
        );
    });

    return <>{allMenus}</>;
};

export const LayoutSideNav: FunctionComponent<LayoutSideNavProps> = (props: LayoutSideNavProps) => {
    const {
        menus = [
            {
                items: [
                    {
                        icon: (<MSIcon icon="fmv" iconType={IconTypes.MS} label="FMV Calculator" />),
                        name: "FMV Calculator",
                        route: "/fmv"
                    },
                    {
                        icon: (<MSIcon icon="needs" iconType={IconTypes.MS} label="Needs Assessment" />),
                        name: "Needs Assessment",
                        route: "/needs"
                    },
                    {
                        icon: (<MSIcon icon="qualifications" iconType={IconTypes.MS} label="HCP/HCO Qualifications" />),
                        name: "HCP/HCO Qualifications",
                        route: "/qualifications"
                    },
                    {
                        icon: (<MSIcon icon="contracts" iconType={IconTypes.MS} label="Contracts" />),
                        name: "Contracts",
                        route: "/contracts"
                    },
                    {
                        icon: (<MSIcon icon="engagements" iconType={IconTypes.MS} label="Engagements" />),
                        name: "Engagements",
                        route: "/engagements/editor"
                    },
                    {
                        icon: (<MSIcon icon="payments" iconType={IconTypes.MS} label="Activity & Payments" />),
                        name: "Activity & Payments",
                        route: "/act-payments"
                    },
                    {
                        icon: (<MSIcon icon="insights" iconType={IconTypes.MS} label="Insights" />),
                        name: "Insights",
                        route: "/insights"
                    }
                ]
            }
        ],
        compactView,
        layoutLoading,
        location,
        sideBottom,
        sideTop,
        user,
        expandTooltipText,
        collapseTooltipText,
        onViewModeCompact,
        onViewModeFull,
        viewModeCompact,
        viewModeFull
    } = props;
    const loading: boolean = layoutLoading[LAYOUT_LOADING_SIDENAV];

    const handleViewModeKeyboardChange = (event: KeyboardEvent): void => {
        if (!event || (event && [219, 221].indexOf(event.keyCode)) === -1) {
            return;
        }

        const target = event.target || event.srcElement;
        if (["INPUT", "TEXTAREA"].indexOf((target as Element).tagName.toUpperCase()) !== -1) {
            return;
        }

        viewModeToggle({compactView, viewModeFull, viewModeCompact, onViewModeFull, onViewModeCompact});
    };

    useEffect(() => {
        window.addEventListener("keyup", handleViewModeKeyboardChange);
        return () => {
            window.removeEventListener("keyup", handleViewModeKeyboardChange);
        };
    });

    return (
        <div data-testid="layout-sidenav" className={classNames("ms-layout-side-nav", {"ms-layout-has-loading": loading})}>
            {sideTop && <div className="ms-layout-side-nav-top">{sideTop}</div>}
            <LayoutSideNavMenu
                menus={menus}
                location={location}
                compactView={compactView}
            />
            {sideBottom && <div className="ms-layout-side-nav-bottom">{sideBottom}</div>}
            <LayoutSideNavViewModeTrigger
                compactView={compactView}
                sidebarExpanded={user?.sidebarExpanded}
                expandTooltipText={expandTooltipText}
                collapseTooltipText={collapseTooltipText}
                onViewModeCompact={onViewModeCompact}
                onViewModeFull={onViewModeFull}
                viewModeCompact={viewModeCompact}
                viewModeFull={viewModeFull}
            />
            {loading && (
                <div data-testid="spinner" className="ms-layout-side-nav-loader-container">
                    <div className="ms-layout-side-nav-loader">
                        <MSIcon icon="spinner" iconType={IconTypes.MS} />
                    </div>
                </div>
            )}
        </div>
    );
};

export const REGULAR_EXP = {
    alphaNumericRegExpWithSpace: /^[a-z0-9]+([\s][a-z0-9]+)*$/i,
    alphaNumericRegExp: /^[a-zA-Z0-9]*$/,
    idRegExp: /^[a-z0-9]+([:_][a-z0-9]+)*$/i,
    alphabeticRegExp: /^[A-Za-z]+([\s][A-Za-z]+)*$/i,
    specialCharactersRegExp: /[!@#$%^&*]/,
    numbersRegExp: /[0-9]/,
    numberWithDotComma: /^[0-9,.]*$/,
    urlRegExp: new RegExp(
        "^(https?|ftp)://?(www\\.)?([a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})(/\\S*)?$|^mailto:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        "i"
    )
};

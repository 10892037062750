export enum USER_ROLE {
    ADMIN_APPROVE = "ROLE_EM_admin_approve",
    ADMIN = "ROLE_EM_admin_admin",
    SYSTEM_ADMIN = "ROLE_EM_SystemAdmin",
    TS_SYSTEM_ADMIN = "ROLE_SystemAdmin",
    PREVIOUS_ADMINISTRATOR = "ROLE_PREVIOUS_ADMINISTRATOR",

    CFF_ADMIN = "ROLE_CFF_admin_admin",
    CFF_MEDISPEND_ADMIN = "ROLE_CFF_MediSpend_admin",

    BR_ADMIN = "ROLE_BR_admin_admin",
    BR_MEDISPEND_ADMIN = "ROLE_BR_MediSpend_admin",

    GLOBAL_MEDISPEND_ADMIN = "ROLE_GlobalAdmin_MediSpend_admin",
    GLOBAL_CURRENCY_ADMIN = "ROLE_GlobalAdmin_CurrencyConversion_admin",

    MEDISPEND_USER = "ROLE_MedispendUser",
    COMPLIANCE_ADMIN_MODIFY = "ROLE_EM_compliance_admin_modifySubmitted",

    GRANTS_ADMIN = "ROLE_EM_grants_admin",
    GRANTS_READ = "ROLE_EM_grants_read",
    GRANTS_UPDATE = "ROLE_EM_grants_update",
    GRANTS_CREATE = "ROLE_EM_grants_create",
    GRANTS_APPROVE = "ROLE_EM_grants_approve",
    GRANTS_REVIEW = "ROLE_EM_grants_review",

    GRANTS_EXTERNAL_UPDATE = "ROLE_EM_grants_external_update",
    GRANTS_EXTERNAL_CREATE = "ROLE_EM_grants_external_create",
    GRANTS_EXTERNAL_READ = "ROLE_EM_grants_external_read",
    GRANTS_FINANCE_UPDATE = "ROLE_EM_grants_finance_update",

    NEEDS_READ = "ROLE_EM_needs_read",
    NEEDS_ASSESSMENT_CREATE = "ROLE_EM_needs_create",
    NEEDS_ACTIVITIES_APPROVE = "ROLE_EM_needs_activities_approve",
    NEEDS_ACTIVITIES_REVIEW = "ROLE_EM_needs_activities_review",
    NEEDS_ACTIVITIES_CREATE = "ROLE_EM_needs_activities_create",
    NEEDS_ASSESSMENT_APPROVE = "ROLE_EM_needs_approve",
    NEEDS_ACTIVITIES_UPDATE = "ROLE_EM_needs_activities_update",
    NEEDS_ACTIVITIES_ADMIN = "ROLE_EM_needs_activities_admin",
    NEEDS_ACTIVITIES_READ = "ROLE_EM_needs_activities_read",
    NEEDS_ASSESSMENT_ADMIN = "ROLE_EM_needs_admin",
    NEEDS_ASSESSMENT_UPDATE = "ROLE_EM_needs_update",
    NEEDS_ASSESSMENT_READ = "ROLE_EM_needs_read",
    NEEDS_ASSESSMENT_REVIEW = "ROLE_EM_needs_review",

    HCPQ_READ = "ROLE_EM_hcpq_read",
    HCPQ_CREATE = "ROLE_EM_hcpq_create",
    HCPQ_UPDATE = "ROLE_EM_hcpq_update",
    HCPQ_REVIEW = "ROLE_EM_hcpq_review",
    HCPQ_APPROVE = "ROLE_EM_hcpq_approve",
    HCPQ_ADMIN = "ROLE_EM_hcpq_admin",

    CONTRACTS_READ = "ROLE_EM_contracts_read",
    CONTRACTS_CREATE = "ROLE_EM_contracts_create",
    CONTRACTS_UPDATE = "ROLE_EM_contracts_update",
    CONTRACTS_APPROVE = "ROLE_EM_contracts_approve",
    CONTRACTS_REVIEW = "ROLE_EM_contracts_review",
    CONTRACTS_ADMIN = "ROLE_EM_contracts_admin",

    ENGAGEMENTS_READ = "ROLE_EM_engagements_read",
    ENGAGEMENTS_CREATE = "ROLE_EM_engagements_create",
    ENGAGEMENTS_ADMIN = "ROLE_EM_engagements_admin",
    ENGAGEMENTS_UPDATE = "ROLE_EM_engagements_update",
    ENGAGEMENTS_REVIEW = "ROLE_EM_engagements_review",
    ENGAGEMENTS_APPROVE = "ROLE_EM_engagements_approve",

    EM_USER = "ROLE_EM_EngagementManager",

    ACTIVITY_REPORTS_READ = "ROLE_EM_activity_reports_read",
    ACTIVITY_REPORTS_APPROVE = "ROLE_EM_activity_reports_approve",
    ACTIVITY_REPORTS_ADMIN = "ROLE_EM_activity_reports_admin",
    ACTIVITY_REPORTS_REVIEW = "ROLE_EM_activity_reports_review",
    ACTIVITY_REPORTS_CREATE = "ROLE_EM_activity_reports_create",
    ACTIVITY_REPORTS_UPDATE = "ROLE_EM_activity_reports_update",

    DASHBOARD_INSIGHT_ADMIN = "ROLE_dashboardInsight_admin",
    DASHBOARD_INSIGHT_CREATE = "ROLE_dashboardInsight_create",
    DASHBOARD_INSIGHT_READ = "ROLE_dashboardInsight_read",
    DASHBOARD_INSIGHT_UPDATE = "ROLE_dashboardInsight_update",
    DASHBOARD_INSIGHT_DELETE = "ROLE_dashboardInsight_delete",
    DASHBOARD_INSIGHT_OVERRIDE = "ROLE_dashboardInsight_override",
    EM_INSIGHT_ADMIN = "ROLE_EM_insight_admin_admin",
    EM_INSIGHT_ADVANCED = "ROLE_EM_insight_advanced_read",
    EM_INSIGHT_BASIC = "ROLE_EM_insight_basic_read",
    EM_INSIGHT_SELF_SERVICE_DESIGNER = "ROLE_EM_self_service_designer_read",
    EM_INSIGHT_SELF_SERVICE_DASHBOARD = "ROLE_EM_self_service_dashboard_read",

    FMV_REVIEW = "ROLE_EM_fmv_review",
    FMV_CREATE = "ROLE_EM_fmv_create",
    FMV_CALCULATOR_READ = "ROLE_EM_fmv_calculator_read",
    FMV_CALCULATOR_CREATE = "ROLE_EM_fmv_calculator_create",
    FMV_READ = "ROLE_EM_fmv_read",
    FMV_CALCULATOR_ADMIN = "ROLE_EM_fmv_calculator_admin",
    FMV_CALCULATOR_APPROVE = "ROLE_EM_fmv_calculator_approve",
    FMV_CALCULATOR_REVIEW = "ROLE_EM_fmv_calculator_review",
    FMV_ADMIN = "ROLE_EM_fmv_admin",
    FMV_UPDATE = "ROLE_EM_fmv_update",
    FMV_CALCULATOR_UPDATE = "ROLE_EM_fmv_calculator_update",
    FMV_APPROVE = "ROLE_EM_fmv_approve",

    ADMIN_PAYMENT_INFO_MAINTENANCE_ADMIN = "ROLE_EM_admin_payment_info_maintenance_admin",
    ADMIN_PAYMENT_INFO_MAINTENANCE_APPROVE = "ROLE_EM_admin_payment_info_maintenance_approve",
    ADMIN_PAYMENT_INFO_MAINTENANCE_CREATE = "ROLE_EM_admin_payment_info_maintenance_create",
    ADMIN_PAYMENT_INFO_MAINTENANCE_MODIFYSUBMITTED = "ROLE_EM_admin_payment_info_maintenance_modifySubmitted",
    ADMIN_PAYMENT_INFO_MAINTENANCE_READ = "ROLE_EM_admin_payment_info_maintenance_read",
    ADMIN_PAYMENT_INFO_MAINTENANCE_REVIEW = "ROLE_EM_admin_payment_info_maintenance_review"
}


//This is the Role that we need to check if they has this we need to allow them for each screens
export const GLOBAL_ADMIN_ACCESS_ROLE_LIST = [USER_ROLE.GLOBAL_MEDISPEND_ADMIN, USER_ROLE.GLOBAL_CURRENCY_ADMIN]

export const OWNER_ROLES = [
    USER_ROLE.NEEDS_ASSESSMENT_CREATE, USER_ROLE.HCPQ_CREATE, USER_ROLE.CONTRACTS_CREATE, USER_ROLE.ENGAGEMENTS_CREATE, USER_ROLE.FMV_CREATE
];


export const EM_CLIENT_ADMIN = [
    USER_ROLE.NEEDS_ACTIVITIES_APPROVE,
    USER_ROLE.FMV_REVIEW,
    USER_ROLE.FMV_CALCULATOR_CREATE,
    USER_ROLE.ACTIVITY_REPORTS_READ,
    USER_ROLE.FMV_CALCULATOR_READ,
    USER_ROLE.CONTRACTS_UPDATE,
    USER_ROLE.HCPQ_UPDATE,
    USER_ROLE.ENGAGEMENTS_ADMIN,
    USER_ROLE.NEEDS_ASSESSMENT_CREATE,
    USER_ROLE.FMV_CREATE,
    USER_ROLE.GRANTS_ADMIN,
    USER_ROLE.ADMIN_APPROVE,
    USER_ROLE.ACTIVITY_REPORTS_APPROVE,
    USER_ROLE.GRANTS_READ,
    USER_ROLE.NEEDS_ACTIVITIES_REVIEW,
    USER_ROLE.CONTRACTS_READ,
    USER_ROLE.NEEDS_ACTIVITIES_CREATE,
    USER_ROLE.FMV_READ,
    USER_ROLE.CONTRACTS_APPROVE,
    USER_ROLE.GRANTS_UPDATE,
    USER_ROLE.FMV_CALCULATOR_ADMIN,
    USER_ROLE.ENGAGEMENTS_CREATE,
    USER_ROLE.NEEDS_ASSESSMENT_APPROVE,
    USER_ROLE.ENGAGEMENTS_UPDATE,
    USER_ROLE.FMV_CALCULATOR_APPROVE,
    USER_ROLE.GRANTS_CREATE,
    USER_ROLE.FMV_CALCULATOR_REVIEW,
    USER_ROLE.NEEDS_ACTIVITIES_UPDATE,
    USER_ROLE.NEEDS_ACTIVITIES_ADMIN,
    USER_ROLE.FMV_ADMIN,
    USER_ROLE.NEEDS_ACTIVITIES_READ,
    USER_ROLE.FMV_UPDATE,
    USER_ROLE.HCPQ_READ,
    USER_ROLE.CONTRACTS_CREATE,
    USER_ROLE.HCPQ_REVIEW,
    USER_ROLE.CONTRACTS_REVIEW,
    USER_ROLE.ACTIVITY_REPORTS_ADMIN,
    USER_ROLE.NEEDS_ASSESSMENT_ADMIN,
    USER_ROLE.ACTIVITY_REPORTS_REVIEW,
    USER_ROLE.ENGAGEMENTS_READ,
    USER_ROLE.FMV_CALCULATOR_UPDATE,
    USER_ROLE.ACTIVITY_REPORTS_CREATE,
    USER_ROLE.GRANTS_APPROVE,
    USER_ROLE.NEEDS_ASSESSMENT_UPDATE,
    USER_ROLE.HCPQ_APPROVE,
    USER_ROLE.NEEDS_ASSESSMENT_READ,
    USER_ROLE.HCPQ_CREATE,
    USER_ROLE.HCPQ_ADMIN,
    USER_ROLE.ENGAGEMENTS_REVIEW,
    USER_ROLE.NEEDS_ASSESSMENT_REVIEW,
    USER_ROLE.ACTIVITY_REPORTS_UPDATE,
    USER_ROLE.FMV_APPROVE,
    USER_ROLE.ADMIN,
    USER_ROLE.ENGAGEMENTS_APPROVE,
];

export const GRANTS_INTERNAL_ROLES = [
    USER_ROLE.GRANTS_READ,
    USER_ROLE.GRANTS_CREATE,
    USER_ROLE.GRANTS_UPDATE,
    USER_ROLE.GRANTS_REVIEW,
    USER_ROLE.GRANTS_APPROVE,
    USER_ROLE.GRANTS_FINANCE_UPDATE
];

export const GRANTS_EXTERNAL_ROLES = [
    USER_ROLE.EM_USER,
    USER_ROLE.GRANTS_EXTERNAL_UPDATE,
    USER_ROLE.GRANTS_EXTERNAL_CREATE,
    USER_ROLE.GRANTS_EXTERNAL_READ
];

export const GRANTS_ROLES = [
    USER_ROLE.GRANTS_READ,
    USER_ROLE.GRANTS_CREATE,
    USER_ROLE.GRANTS_UPDATE,
    USER_ROLE.GRANTS_REVIEW,
    USER_ROLE.GRANTS_APPROVE,
    USER_ROLE.GRANTS_FINANCE_UPDATE,
    USER_ROLE.GRANTS_EXTERNAL_UPDATE,
    USER_ROLE.GRANTS_EXTERNAL_CREATE,
    USER_ROLE.GRANTS_EXTERNAL_READ
];

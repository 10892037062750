import React, {FC, useEffect, useRef} from "react";
import classNames from "classnames";

import {FieldHandleAction, FormFieldHint, Mode} from "../../formBuilder/types";
import {FilterValue} from "../../types";
import {MSLabel} from "../MSLabel";

import "./scss/index.scss";


interface MSTextAreaProps {
    value: any;
    fieldLabel: string;
    isRequired: boolean;
    isDisabled: boolean;
    error: string;
    maxFieldLength: number;
    name?: string;
    handleChange?: (optionData: FilterValue, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    mode?: Mode;
    isPencilDisplayed?: boolean;
    hint?: FormFieldHint;
}


const MSTextArea: FC<MSTextAreaProps> = ({value, name, fieldLabel, maxFieldLength, isRequired, isDisabled, error, handleChange, isHandleAction, handleAction, mode, isPencilDisplayed, hint}: MSTextAreaProps) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current && (ref.current.value = value || "");
    }, [value]);

    return (
        <div className="ms-form-group">
            {fieldLabel && <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={isPencilDisplayed} error={error} />}
            <div className={classNames("textarea-wrapper", {"has-error": error?.length})}>
                {mode === "READ" ? <div className="value">{value || "-"}</div> : (
                    <>
                        <textarea
                            className={classNames("ms-textarea", {"has-error": error?.length})}
                            name={name}
                            defaultValue={(value === undefined || value === null) ? "" : value}
                            onChange={(e) => {
                                handleChange && handleChange(e.target.value, name, isHandleAction, handleAction);
                            }}
                            ref={ref}
                            disabled={isDisabled}
                            maxLength={maxFieldLength}
                        />

                        <div className="character-counter"><span>{value?.length || 0}</span>/<span>{maxFieldLength}</span></div>
                    </>
                )}
                {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
                {!!error.length && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default MSTextArea;

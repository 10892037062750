import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";

import {
    clearTime,
    getDateStringFromTimestamp,
    getDateTimestampFromString,
    getMonthDetails,
    isCurrentDay,
    isSelectedDay
} from "../../utils";
import {DROPDOWN_DATE_FORMAT, MONTH_MAP, SHORT_DAYS_MAP, YEAR_RANGE_LIST_OPTIONS} from "../../constants";
import {MSSelect} from "../MSSelect";
import {useOnClickOutside} from "../hooks/clickOutside";
import {ButtonColors, ButtonTypes} from "../MSButton/types";
import {DateData, DatepickerOptions} from "../../types";
import {DateField} from "./DateField";
import {IconFolders, IconTypes, MSIcon} from "../MSIcon";
import {MSButton} from "../MSButton";
import {FormFieldHint, Mode} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";

import * as _ from "lodash";

import "./scss/_MSDatepicker.scss";

export interface MSDatepickerProps {
    onChange?: (value: number) => void;
    options?: DatepickerOptions,
    startLimit?: number | string,
    endLimit?: number | string,
    // Selecting the date outside the soft limit is possible but the warning message will be shown
    startSoftLimit?: number | string,
    endSoftLimit?: number | string,
    startSoftLimitWarnMsg?: string,
    endSoftLimitWarnMsg?: string,
    label?: string,
    dateFormat?: string,
    icon?: any,
    isRequired?: boolean,
    isOpenUp?: boolean,
    placeholder?: string,
    isDropdown?: boolean,
    error?: string,
    name?: string,
    mode?: Mode,
    isDisabled?: boolean,
    isPencilDisplayed?: boolean,
    hint?: FormFieldHint,
}

export const MSDatepicker = (props: MSDatepickerProps): JSX.Element => {
    const {
        onChange, options,
        startSoftLimitWarnMsg = null, endSoftLimitWarnMsg = null, isRequired = false, label = "", isOpenUp = false,
        placeholder = "", isDropdown = true, error = "", name, mode, isDisabled = false, isPencilDisplayed, hint
    } = props;
    const startSoftLimit: number = _.isString(props.startSoftLimit) ? getDateTimestampFromString(props.startSoftLimit) : props.startSoftLimit;
    const endSoftLimit: number = _.isString(props.endSoftLimit) ? getDateTimestampFromString(props.endSoftLimit) : props.endSoftLimit;
    const startLimit = _.isString(props.startLimit) ? getDateTimestampFromString(props.startLimit) : props.startLimit;
    const endLimit = _.isString(props.endLimit) ? getDateTimestampFromString(props.endLimit) : props.endLimit;
    const modalRef: React.RefObject<HTMLDivElement> = useRef();
    const [show, setShow] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [softError, setSoftError] = useState(null as string);
    const parseDateFromProps = () => {
        // Figured out that value can be string or number (timestamp)
        const value = options?.value;
        if (_.isNumber(value)) {
            return value;
        } else if (_.isString(value)) {
            return getDateTimestampFromString(value);
        }
        return null;
    };
    const [selectedDay, setSelectedDay] = useState(parseDateFromProps());
    const [monthDetails, setMonthDetails] = useState(getMonthDetails(year, month));

    useOnClickOutside(modalRef, () => closeModal());

    //Needed because we need to refresh selected date on getting new props
    useEffect(() => {
        setSelectedDay(parseDateFromProps());
    }, [options?.value]);


    const closeModal = useCallback(() => {
        setShow(false);
    }, []);

    const onDateClick = (day: DateData) => {
        const {timestamp} = day;
        setSelectedDay(timestamp);
        selectMonth(day.month);

        if (onChange) {
            onChange(timestamp);
        }
        setShow(false);
    };

    const selectYear = (yearOption: DatepickerOptions) => {
        const {value} = yearOption;
        setYear(value);
        setMonthDetails(getMonthDetails(value, month));
    };

    const selectMonth = (offset: number) => {
        let offsetYear = year;
        let offsetMonth = month + offset;
        if (offsetMonth === -1) {
            offsetMonth = 11;
            offsetYear--;
        } else if (offsetMonth === 12) {
            offsetMonth = 0;
            offsetYear++;
        }
        setYear(offsetYear);
        setMonth(offsetMonth);
        setMonthDetails(getMonthDetails(offsetYear, offsetMonth));
    };

    const selectSpecificMonth = useCallback((monthData: DatepickerOptions) => {
        const {value} = monthData;
        setMonth(value);
        setMonthDetails(getMonthDetails(year, value));
    }, []);

    useEffect(() => {
        if (!startLimit) return;

        const startLimitDate = new Date(startLimit);
        const month = startLimitDate.getMonth();
        const year = startLimitDate.getFullYear();
        setMonth(month);
        setMonthDetails(getMonthDetails(year, month));
        setYear(year);
    }, [startLimit, selectSpecificMonth]);

    //true if check passed
    const startDayLimitCheck = (day: number) => {
        return startLimit ? day >= startLimit : true;
    };

    //true if check passed
    const endDayLimitCheck = (day: number) => {
        return endLimit ? day <= endLimit : true;
    };

    useEffect(() => {
        if (selectedDay && endSoftLimit && selectedDay > endSoftLimit) {
            setSoftError(endSoftLimitWarnMsg);
        } else if (selectedDay && startSoftLimit && selectedDay < startSoftLimit) {
            setSoftError(startSoftLimitWarnMsg);
        } else {
            setSoftError(null);
        }
    }, [selectedDay]);

    const renderCalendar = () => {
        const days = monthDetails.map((day: DateData, index: number) => {
            return (
                <div
                    className={"calendar-day-container " + ((!startDayLimitCheck(day.timestamp) || !endDayLimitCheck(day.timestamp)) ? " disabled" : "") +
                        (isCurrentDay(day) ? " highlight" : "") + (isSelectedDay(day, selectedDay) ? " highlight-green" : "") +
                        (day.month < 0 ? " past" : "") +
                        (day.month > 0 ? " future" : "")
                    }
                    key={index}>
                    <div className="calendar-day">
                        <span onClick={() => onDateClick(day)}>
                            {day.date}
                        </span>
                    </div>
                </div>
            );
        });

        return (
            <div className="calendar-container">
                <div className="calendar-head">
                    {SHORT_DAYS_MAP.map((d, i) => <div key={i} className="calendar-name">{d}</div>)}
                </div>
                <div className="calendar-body">
                    {days}
                </div>
            </div>
        );
    };

    const setSelectedDayFromField = () => {
        return ({});
    };

    const formMothListOptions = useMemo(() => {
        return MONTH_MAP.map((month, index) => ({uiLabel: month, value: index}));
    }, []);

    const softErrorLine = !!softError && <div className="error-message error-message-soft">{softError}</div>;

    if (mode === "READ") {
        return (
            <>
                <div className="ms-form-group">
                    <MSLabel isRequired={isRequired} fieldLabel={label} isPencilDisplayed={isPencilDisplayed} />
                    <div className="value">
                        {options.value || "-"}
                    </div>
                    {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
                    {softErrorLine}
                </div>
            </>
        );
    }

    return (<>
        {label && <MSLabel isRequired={isRequired} fieldLabel={label} isPencilDisplayed={isPencilDisplayed} error={error} />}
        <div ref={modalRef} className={classNames("textarea-wrapper", "MyDatePicker")}>
            {isDropdown ? <MSButton
                disabled={isDisabled}
                size="md"
                type={ButtonTypes.default}
                variant={ButtonColors.grey200}
                className={classNames("ms-select-button", "ms-datepicker-button", {"has-error": error?.length})}
                onClick={() => setShow(true)}>
                <>
                    <div className="ms-button-title">
                        <div className="datepicker-title">
                            <img
                                className="calendar-icon"
                                src="../../../media/img/icons/calendar.svg"
                            />
                            {selectedDay ? getDateStringFromTimestamp(selectedDay, DROPDOWN_DATE_FORMAT) : placeholder}
                        </div>
                        <MSIcon
                            icon="caret-down"
                            iconType={IconTypes.MS}
                            iconFolder={IconFolders.UTILS}
                            className="ms-caret-down"
                        />
                    </div>
                </>
            </MSButton> : <DateField onClick={() => setShow(true)}
                name={name}
                value={selectedDay}
                onChangeValue={setSelectedDayFromField}
                onClear={() => {
                    setSelectedDay(null);
                    onChange && onChange(null);
                }}
                uiLabel={options?.uiLabel}
                isDisabled={isDisabled}
            />}
            {show ? (
                <div className={classNames("ms-datepicker", isOpenUp ? "ms-offset" : "")}>
                    <div className="header-container">
                        <div className="button-content" onClick={() => selectMonth(-1)}>
                            <img src="../../../media/img/icons/backward.svg" width={15} height={15} alt="Previous" />
                        </div>
                        <div className="middle-buttons-container">
                            <MSSelect options={formMothListOptions}
                                onChange={selectSpecificMonth} placeholder={MONTH_MAP[month]}
                                variant={ButtonColors.grey100}
                                buttonType={ButtonTypes.pill}
                            />
                            <MSSelect options={YEAR_RANGE_LIST_OPTIONS}
                                onChange={selectYear} placeholder={year.toString()}
                                variant={ButtonColors.grey100}
                                buttonType={ButtonTypes.pill} />
                        </div>
                        <div className="button-content" onClick={() => selectMonth(1)}>
                            <img src="../../../media/img/icons/forward.svg" width={15} height={15} alt="Previous" />
                        </div>
                    </div>
                    <div className="ms-datepicker-body">
                        {renderCalendar()}
                    </div>
                </div>
            ) : ""}
            {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
            {!!error.length && <div className="error-message">{error}</div>}
            {softErrorLine}
        </div>
    </>
    );
};

import classNames from "classnames";
import React, {FunctionComponent, MouseEvent, ReactNode, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Link} from "react-router-dom";
import {Location} from "history";

import {IconFolders, IconTypes, MSIcon} from "../MSIcon";


export interface LayoutSideMenuItemProps {
    compactView?: boolean;
    name: string;
    active?: boolean;
    action?: (event: MouseEvent) => void;
    href?: string;
    icon?: string | ReactNode;
    route?: string;
    tip?: string | ReactNode;
    externalLink?: string;
    subMenu?: LayoutSideMenuItemProps[];
}


export interface LayoutSideMenuProps {
    compactView?: boolean;
    location?: Location;
    name?: string;
    previousRoute?: string;
    items?: LayoutSideMenuItemProps[];
    externalLink?: string;
}

type CreateLinkProps = LayoutSideMenuItemProps & {
    index: number;
    props: LayoutSideMenuProps;

}

interface SubMenuProps {
    menu: LayoutSideMenuItemProps["subMenu"];
    sideMenuProps: LayoutSideMenuProps;
    expanded?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({menu, sideMenuProps, expanded}) => {
    return (
        <div className={`ms-layout-sub-menu-wrapper ${expanded ? "expanded" : ""}`}>
            <ul className="ms-layout-sub-menu">
                {menu.map((item, index) => {
                    return <MenuLink key={index} props={sideMenuProps} index={index} {...item} />;
                })}
            </ul>
        </div>
    );
};

const MenuLink = ({props, action, active, name, icon, route, tip, index, externalLink, subMenu}: CreateLinkProps) => {
    const {compactView, location} = props;
    const isActive = active || route === location.pathname;
    const [expanded, setIsExpanded] = useState(isActive);
    let iconElem = null;
    if (icon && typeof icon === "string") {
        iconElem = <MSIcon icon={icon} label={name} />;
    } else if (icon) {
        iconElem = icon;
    }
    let carretIcon = null;

    if (subMenu?.length) {
        carretIcon = <MSIcon
            icon="caret-down"
            iconType={IconTypes.MS}
            iconFolder={IconFolders.UTILS}
            onClick={handleClick}
            className={`${expanded ? "caret-up" : "caret-down"}`}
        />;
    }

    const linkItemContent = isActive ? (
        <span className="link">
            {iconElem} <span className="name">{name}</span> {carretIcon}
        </span>
    ) : externalLink ? (
        <a href={externalLink} className="link">
            {iconElem} <span className="name">{name}</span> {carretIcon}
        </a>
    ) : (
        <Link to={route || "/"} onClick={action} className="link">
            {iconElem} <span className="name">{name}</span> {carretIcon}
        </Link>
    );
    const linkItemWithTooltip: ReactNode = (
        <OverlayTrigger overlay={<Tooltip id={`tt-${index}`}>{tip || name}</Tooltip>} placement="right">
            {linkItemContent}
        </OverlayTrigger>
    );

    function handleClick() {
        setIsExpanded(prev => !prev);
    }

    return (
        <li className={classNames("ms-layout-menu-item", {active: isActive})}>
            {compactView ? linkItemWithTooltip : linkItemContent}
            {!!subMenu?.length && <SubMenu menu={subMenu} sideMenuProps={props} expanded={expanded} />}
        </li>
    );
};

export const LayoutSideMenu: FunctionComponent<LayoutSideMenuProps> = (props) => {
    const {previousRoute, items} = props;

    const prevLink = previousRoute ? (
        <Link to={previousRoute} className="previous-route">
            <MSIcon icon="level-up" />
        </Link>
    ) : null;

    return (
        <div className="ms-layout-menu-container">
            {props.name ? <h2>{props.name} {prevLink}</h2> : prevLink}
            <ul className="ms-layout-menu">
                {(items || []).map((item: LayoutSideMenuItemProps, index: number) => {
                    return <MenuLink key={index} props={props} index={index} {...item} />;
                })}
            </ul>
        </div>
    );
};

import {ComponentsTypes} from "../constantsLib";
import {FormField, Column, Section, InitialValues, SectionStatic} from "../types";


export const getUpdatedSection = (sectionName: string, entityState: Column[]) => {
    const sectionsArr: Section[] = [];
    entityState.forEach((column: Column) => sectionsArr.push(...column.sections.filter(isDynamicSection)));
    return sectionsArr.find((section: Section) => section.sectionId === sectionName);
};

export const getSectionById = (id: string, entityState: Column[]) => {
    return getUpdatedSection(id, entityState);
};

export const getFieldByIdAndSection = (sectionName: string, id: string, entityState: Column[]) => {
    const updatedSection = getUpdatedSection(sectionName, entityState);
    return updatedSection.fields.find((field: FormField) => field.fieldId === id);
};

export const getSectionByFieldId = (fieldId: string, entityState: Column[]): Section | null => {
    let result = null;
    entityState.forEach(column => column.sections.filter(isDynamicSection).forEach(section => section.fields.forEach(field => {
        if (field.fieldId === fieldId) {
            result = section;
        }
    })));

    return result;
};

function getFields(columns: Column[]): FormField[] {
    const fields: FormField[] = [];
    columns.forEach(column => column.sections.filter(isDynamicSection).forEach(section => fields.push(...section.fields)));
    return fields;
}

export function getFieldsByTypes(columns: Column[], types: ComponentsTypes[]): FormField[] {
    const fields: FormField[] = [];
    columns.forEach(column => column.sections.filter(isDynamicSection).forEach(section => {
        section.fields.forEach(field => {
            if (types.includes(field.fieldType)) {
                fields.push(field);
            }
        });
    }));
    return fields;
}

export function getFieldsAsObject(columns: Column[]): {[key: string]: FormField} {
    const fields: {[key: string]: FormField} = {};
    columns.forEach((column) =>
        column.sections
            .filter(isDynamicSection)
            .forEach((section) => section.fields.forEach((field) => (fields[field.fieldId] = field)))
    );
    return fields;
}

export const getFieldById = (id: string, columns: Column[]): FormField | undefined => {
    const fields = getFields(columns);
    return fields.find(field => field.fieldId === id);
};

export const hideField = (id: string, columns: Column[]): void => {
    const field = getFieldById(id, columns);
    if (field) {
        field.isHidden = true;
    }
};

export const hideFields = (ids: string[], columns: Column[]): void => {
    ids.forEach(id => hideField(id, columns));
};

export const setFieldDisabled = (disabled: boolean, id: string, columns: Column[]): void => {
    const field = getFieldById(id, columns);
    if (field) {
        field.isDisabled = disabled;
    }
};

export const updateFieldProperties = (props: Partial<FormField>, id: string, columns: Column[]): void => {
    const field = getFieldById(id, columns);
    if (!field) return;

    Object.entries(props).forEach(([key, value]: [keyof FormField, never]) => {
        field[key] = value;
    });
};

export const showField = (id: string, columns: Column[]): void => {
    const field = getFieldById(id, columns);
    if (field) {
        field.isHidden = false;
    }
};

export const showFields = (ids: string[], columns: Column[]): void => {
    ids.forEach(id => showField(id, columns));
};

export const getFieldsByPrefix = (prefix: string, entityState: Column[]): FormField[] => {
    const fields: FormField[] = [];
    entityState.forEach(
        column => column.sections.filter(isDynamicSection).forEach(
            section => fields.push(...section.fields.filter(
                field => field.fieldId.startsWith(prefix)))
        )
    );

    return fields;
};

export function getFlatFormikValues(values: {[key: string]: {[key: string]: any}}): {[key: string]: any} {
    let flatValues = {};
    Object.values(values).forEach((value) => flatValues = {...value});

    return flatValues;
}

export const getFieldByIdFromFormikValues = (id: string, values: InitialValues): string | number | number[] | null => {
    let result = null;
    Object.values(values).forEach(section => Object.entries(section).forEach(([fieldId, fieldValue]) => {
        if (fieldId === id) {
            result = fieldValue;
        }
    }));

    return result;
};

export function isStaticSection(section: Section | SectionStatic): section is SectionStatic {
    return "render" in section;
}

export function isDynamicSection(section: Section | SectionStatic): section is Section {
    return !("render" in section);
}

export function isStaticColumn(column: Column): boolean {
    return !!column.static;
}

export function isDynamicColumn(column: Column): boolean {
    return !column.static;
}

import React, {FC} from "react";
import {Auth0Provider} from "@auth0/auth0-react";

import {Auth0Config} from "../../../types";


interface AppWithAuth0Props {
    auth0Config: Auth0Config,
    children: JSX.Element
}

/**
 * Auth0Provider - allows to work with auth0 service
 *
 *  @param options:
 *      auth0Config - params for auth0 service
 *      children - your app root component
 *
 *  @returns your app root component with access to the auth0 service
 */
export const AppWithAuth0: FC<AppWithAuth0Props> = ({auth0Config, children}) => {
    return (
        <Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            redirectUri={auth0Config.redirectUri}
            audience={auth0Config.audience}
        >
            {children}
        </Auth0Provider>
    );
};

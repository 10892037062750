import React, {FunctionComponent} from "react";
import {useLocation} from "react-router";

import {MSLayout} from "@medispend/common/src/components/MSLayout";
import {IconTypes, MSIcon} from "@medispend/common/src/components/MSIcon";
import {AppsList} from "./constants";
import {BR_LINK, CFF_LINK, GA_CLIENT_MAPPING_PATHNAME, GA_CURRENCY_CONVERSION_PATHNAME, GLOBAL_ADMIN_CLIENT_MAPPING_LINK, GLOBAL_ADMIN_CURRENCY_CONVERSION_RATE_LINK, GLOBAL_ADMIN_LINK} from "./env";
import {useAuth0AccessToken} from "@medispend/common/src/authentication/auth0/hooks/useAuth0AccessToken";

import {MSLoader} from "@medispend/common/src/components/MSLoader";
import {userToggleBlocks} from "./services/userToggleBlocks";
import {getAdminProducts} from "./utils/commonUtil";
import {userHasAnyRole, userHasRole} from "@medispend/common/src/utils";
import {GLOBAL_ADMIN_ACCESS_ROLE_LIST, USER_ROLE} from "@medispend/em-common/src/constants";
import {getRolesFromAuth0User} from "@medispend/admin-common/src/utils/auth0Util";

interface AdminLayoutProps {
    render: () => JSX.Element,
    app: AppsList,
    auth0options: {audience: string, returnTo: string}
}

export const App: FunctionComponent<AdminLayoutProps> = ({render, app, auth0options}: AdminLayoutProps) => {
    const {user, logOutHandler, accessToken} = useAuth0AccessToken(auth0options);
    const {pathname} = useLocation();

    if (!accessToken || !user) {
        return <MSLoader />;
    }

    const menuItems: Array<any> = [
        {
            icon: (<MSIcon icon="br" iconType={IconTypes.MS} label="Business Rules" />),
            name: "Business Rules",
            active: app === AppsList.BR,
            externalLink: BR_LINK
        },
        {
            icon: (<MSIcon icon="cff" iconType={IconTypes.MS} label="Custom Form Fields" />),
            name: "Custom Form Fields",
            active: app === AppsList.CFF,
            externalLink: CFF_LINK
        }
    ];

    const userRoles = getRolesFromAuth0User(user);
    const isGlobalUIAdmin: boolean = userHasAnyRole(GLOBAL_ADMIN_ACCESS_ROLE_LIST, userRoles);
    const isMedispendAdmin = userHasRole(USER_ROLE.GLOBAL_MEDISPEND_ADMIN, userRoles);
    if (isGlobalUIAdmin) {
        menuItems.push({
            icon: <MSIcon icon="global-admin" iconType={IconTypes.MS} label="Global Admin" />,
            name: "Global Admin",
            active: app === AppsList.GLOBAL_ADMIN,
            subMenu: [
                {
                    name: "Currency Conversion Rates",
                    externalLink: GLOBAL_ADMIN_CURRENCY_CONVERSION_RATE_LINK,
                    active: pathname.includes(GA_CURRENCY_CONVERSION_PATHNAME)
                },
                ...(isMedispendAdmin && [
                    {
                        name: "Currency Conversion Client Mapping",
                        externalLink: GLOBAL_ADMIN_CLIENT_MAPPING_LINK,
                        active: pathname.includes(GA_CLIENT_MAPPING_PATHNAME)
                    }
                ])
            ]
        });
    }

    const menus = [
        {
            items: menuItems
        }
    ];

    return (
        <MSLayout
            hasSideNav
            sideNav={{menus}}
            hasHeader
            header={{
                brand: "Medispend Admin",
                logo: "../../../media/img/logos/medispend-admin.svg",
                products:getAdminProducts(user)
            }}
            user={user ? {...user["http://medispend/user_metadata"], sidebarExpanded: true} : {sidebarExpanded: true}}
            userToggleBlocks={userToggleBlocks(logOutHandler)}
        >
            <div className="ms-layout-content">
                {render()}
            </div>
        </MSLayout>
    );
};

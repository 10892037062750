import classNames from "classnames";
import React, {FunctionComponent} from "react";

import {MSIcon, IconTypes} from "@medispend/common/src/components/MSIcon";

interface AlertProps {
    alertType: string;
    link: string;
    reason: string;
    title: string;
}

export const Alert: FunctionComponent<AlertProps> = (props) => {
    const {title, reason, alertType, link} = props;

    return (
        <a className="alert" href={link}>
            <div className={classNames("alert-type", alertType)}>
                <MSIcon icon={alertType as string} iconType={IconTypes.MS} className="white" />
            </div>
            <h3>{title}</h3>
            <h4>{reason}</h4>
        </a>
    );
};

import {Translation} from "../state/thunky/types";
import {getTranslationText} from "./translationUtil";
import {TRANSLATION_KEYS} from "../constants";
import {dateToString} from "@medispend/common/src/utils";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const getLocaleFormattedDateTime = (date: string, languageTranslations: Translation): string => {
    const dateFormat = getTranslationText(TRANSLATION_KEYS.DATE_FORMAT, languageTranslations, DEFAULT_DATE_FORMAT);
    const timeFormat = getTranslationText(TRANSLATION_KEYS.TIME_FORMAT, languageTranslations, DEFAULT_TIME_FORMAT);

    // date-fns documentation says:
    // Starting with v2, format and parse use Unicode tokens.
    // The tokens are different from Moment.js and other libraries that opted to use custom formatting rules

    //So we need to adapt custom date format to the format consumed by the date-fns library
    const fullFormat = `${dateFormat} ${timeFormat}`
        .replace(/Y/g, "y")
        .replace(/D/g, "d")
        .replace(/h/g, "H");

    return dateToString(new Date(date), fullFormat);
};

export const getLocaleFormattedDate = (date: string, languageTranslations: Translation): string => {
    const dateFormat = getTranslationText(TRANSLATION_KEYS.DATE_FORMAT, languageTranslations, DEFAULT_DATE_FORMAT);

    // date-fns documentation says:
    // Starting with v2, format and parse use Unicode tokens.
    // The tokens are different from Moment.js and other libraries that opted to use custom formatting rules

    //So we need to adapt custom date format to the format consumed by the date-fns library
    const format = `${dateFormat}`.replace(/Y/g, "y").replace(/D/g, "d");

    return dateToString(new Date(date), format);
};

export const getLocaleFormattedDateUTC = (date: string, languageTranslations: Translation): string => {
    return getLocaleFormattedDate(date + "Z", languageTranslations);
};

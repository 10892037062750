import {FunctionComponent} from "react";
import {Observable} from "rxjs/internal/Observable";

import {MSMultiSelectProps} from "./components/MSMultiSelect";
import {MSSelectProps} from "./components/MSSelect";
import {SelectOption} from "./components/MSSelect/BaseSelect/types";
import {MSDatepickerProps} from "./components/MSDatepicker";
import {MSRangepickerProps} from "./components/MSDatepicker/RangePicker";
import {AjaxResponse} from "rxjs/ajax";

export interface ErrorState {
    response?: {message: string, data?: string[]};
    message: string;
    status: number;
}

export interface ResponseState<T = any> {
    isLoading: boolean,
    error: null | ErrorState,
    data: null | T,
    originalResponse?: AjaxResponse<T>
}

export interface AjaxRequest {
    url?: string;
    body?: Record<string, string>;
    user?: string;
    async?: boolean;
    method?: string;
    headers?: Record<string, string>;
    timeout?: number;
    password?: string;
    hasContent?: boolean;
    crossDomain?: boolean;
    withCredentials?: boolean;
    createXHR?: () => XMLHttpRequest;
    responseType?: string;
}

export type HTTPMethods = "get" | "put" | "post" | "patch" | "delete";

export enum ValueTypes {
    text = "text",
    number = "number"
}

export interface AjaxCreationMethod {
    get(url: string, headers?: Record<string, string>): Observable<ResponseState>;
    post(url: string, headers?: Record<string, string>, body?: Record<string, string>): Observable<ResponseState>;
    put(url: string, body?: Record<string, string>, headers?: Record<string, string>): Observable<ResponseState>;
    patch(url: string, body?: Record<string, string>, headers?: Record<string, string>): Observable<ResponseState>;
    delete(rl: string, headers?: Record<string, string>): Observable<ResponseState>;
}

export interface FilterOption {
    name: string;
    uiLabel?: string;
    value: FilterValue;
}
export interface DropdownOption {
    value: string | number | boolean;
    uiLabel?: string;
    name: string;
    isActive?: boolean;
    id?: number;
    shortName?: string;
    isVisible? :boolean;
    valueType?: ValueTypes;
}

export interface FilterItem<T> extends Omit<DropdownOption, "value">{
    value: T;
}

export type FilterValue = string | number | boolean | string[] | number[] | DatepickerOptions[] | null;

export interface FilterProps<T> {
    options: DropdownOption[];
    title: string;
    chosenFilterValue?: FilterItem<T>;
    onFilterChange: (filterValue: FilterItem<T>) => void;
}

type FilterComponentsProps = MSSelectProps | MSMultiSelectProps | MSDatepickerProps | MSRangepickerProps;

export interface FilterItemParams {
    title: string,
    options: DropdownOption[],
    defaultValues: FilterValue,
    onChange: (optionData: SelectOption[] | SelectOption) => void,
}

export type SortSearchParams = Omit<FilterParams, "component">

export interface FilterParams {
    options?: DropdownOption[];
    title: string;
    defaultTitle?: string;
    isMulti?: boolean,
    name: string;
    component: FunctionComponent<FilterComponentsProps>;
    defaultValue?: DropdownOption | null;
    valueType?: ValueTypes;
    id?: string;
    isDate?: boolean;
    isAdditional?: boolean;
    isDisabled?: boolean;
}

export enum ClientPropertyKey {
    ERP_ENABLED = "ERPEnabled",
    GRANTS_ENABLED = "GrantsEnabled",
    NEEDS_ASSESSMENT_ENABLED = "NeedsAssessmentEnabled",
    HCPQ_ENABLED = "HCPQualificationsEnabled",
    CONTRACTS_ENABLED = "ContractsEnabled",
    ENGAGEMENTS_ENABLED = "EngagementsEnabled",
    FMV_ENABLED = "FairMarketValueEnabled",
    ACTIVITY_REPORTS_ENABLED = "ActivityReportsEnabled",
    ACTIVITY_REPORTS_CREDIT_MEMO_ENABLED = "activityReport.creditMemo.enabled",
    INSIGHT_ENABLED = "InsightEnabled",
    MAINTENANCE_NOTIFICATION_SHOW_NUM_OF_SEC = "maintenance.notification.show.numOfSeconds",
    ENGAGED_HCP_SELECT_APPROVED_QUAL_REGIONS = "engagedHcp.selectApprovedQual.regions",
    ENGAGED_HCP_SELECT_IN_PROCESS_CONTRACTS_REGIONS = "engagedHcp.selectInProcessContract.regions",
    ENGAGED_HCP_CANCEL_NO_PAY_ENABLED = "engagedHcp.cancelNoPay.enabled",
    NEEDS_ASSESSMENT_FULLY_DISABLED = "NeedsAssessmentModuleFullyDisabled",
    CREATE_ENGAGEMENT_IN_THE_PAST = "CreateEngagementInPastEnabled",
    PENDO_ENABLED = "PendoEnabled",
    CONTRACT_TERM_ENABLED= "contracts.editor.ContractTermAutoCalculation.enabled"
}

export interface ClientProperty {
    propertyKey: ClientPropertyKey;
    propertyValue: string;
}

export type User = {
    firstName: string;
    lastName: string;
    sidebarExpanded: boolean;
}

export type UserData = {
    roles?: string[];
    emUserId?: number;
    username?: string;
    email?: string;
}

export interface Auth0Config {
    domain: string;
    clientId: string;
    redirectUri: string;
    audience: string;
    scope?: string;
}

export interface DateData {
    date: number;
    month: number;
    day?: number;
    timestamp?: number;
    dayString?: string;
    year?: number
}

export interface DatepickerOptions {
    value: number;
    uiLabel?: string;
    name: string;
}

export type GlobalModalContext = {
    showModal: (modalType: string, modalProps?: any, customComponent?: FunctionComponent) => void;
    hideModal: () => void;
    store: any;
};

export interface GlobalModalProps {
    showModal: boolean;
    hideModal: () => void;
}

export interface Version {
    appName: string;
    version: string;
    buildTime: string;
    gitCommit: string;
}

export interface ToggleOption {
    uiLabel: string;
    value: string;
    inactive?: boolean;
}

export interface BooleanToggleOption {
    uiLabel: string;
    value: boolean;
}

export interface ActionItem {
    doAction: boolean,
    elementId: string
}

export interface ShowMessageActionItem {
    showMessage: boolean,
    message: string,
}

export interface ActionResponseState {
    HIDE_ELEMENT: ActionItem[],
    LOCK_RECORD: ActionItem[],
    SHOW_MESSAGE: ShowMessageActionItem[],
}

export interface PageableEntity<T> extends PageParams {
    content: T;
}

export interface PageParams {
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    first: boolean;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    empty: boolean;
}
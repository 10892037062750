import React, {FC, MouseEvent, RefObject} from "react";

import {MSIcon} from "../MSIcon";

import "./scss/clearButton.scss";


type EventType = MouseEvent<Element, globalThis.MouseEvent>;

export const ClearButton: FC<{onClick: (e: EventType) => void, clearButtonRef?: RefObject<HTMLDivElement>}> = ({onClick, clearButtonRef}) => {
    return (<div ref={clearButtonRef || null} className="clear-button" onClick={onClick}>
        <MSIcon icon="far fa-times-circle" />
    </div>);
};

import React, {FC, useEffect, useState} from "react";

import "./index.scss";


export enum CheckboxColors {
    green = "green"
}

export interface MSCheckboxProps {
    name?: string;
    active?: boolean;
    disabled?: boolean;
    title?: string;
    variant?: CheckboxColors;
    onClick?: (active: boolean) => void;
}

export const MSCheckbox: FC<MSCheckboxProps> = (props) => {
    const {
        title = "",
        active = false,
        variant = CheckboxColors.green,
        disabled,
        name,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick = () => {}
    } = props;

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setEnabled(active);
    }, [active]);

    const handleChange = () => {
        setEnabled(!enabled);
        onClick(!enabled);
    };

    return (
        <label className="ms-checkbox">
            <input
                name={name}
                type="checkbox"
                className={`ms-checkbox-input ${variant}`}
                checked={enabled}
                disabled={disabled}
                onChange={handleChange}
            />
            <span className="ms-checkbox-title">
                {title}
            </span>
        </label>
    );
};

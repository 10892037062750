import {AUDIENCE, DOMAIN} from "../constants";

export const getAuth0Config = (clientId: string, redirectURI: string, audienceUrl:string = AUDIENCE, domainUrl: string = DOMAIN) => {
    return {
        domain: domainUrl,
        clientId: clientId,
        redirectUri: redirectURI,
        audience: audienceUrl
    };
};

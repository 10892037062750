import {uuid} from "../../../utils";


import {
    LAYOUT_ALERT,
    LAYOUT_LOADING,
    LAYOUT_PROPS_UPDATE_DYNAMIC,
    LAYOUT_VIEW_COMPACT,
    LAYOUT_VIEW_MODE,
    LAYOUT_VIEW_MODE_TOGGLE,
    LAYOUT_ALERT_ADD,
    LAYOUT_ALERT_CLEAR,
    LAYOUT_ALERT_CLEAR_ALL,
    ActionDef,
    LayoutLoadingPayload,
    LayoutAlerts
} from "./actions";

import {
    LAYOUT_LOADING_CONTENT,
    LAYOUT_LOADING_SIDENAV,
    LAYOUT_PROPS_DYNAMIC
} from "./constants";

export interface LayoutState {
    [LAYOUT_VIEW_COMPACT]: boolean;
    [LAYOUT_PROPS_DYNAMIC]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    [LAYOUT_ALERT]: LayoutAlerts;
    [LAYOUT_LOADING]: LayoutLoadingPayload;
    router?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const initialState: LayoutState = {
    [LAYOUT_VIEW_COMPACT]: false,
    [LAYOUT_PROPS_DYNAMIC]: {},
    [LAYOUT_ALERT]: {},
    [LAYOUT_LOADING]: {
        [LAYOUT_LOADING_SIDENAV]: false,
        [LAYOUT_LOADING_CONTENT]: false
    }
};

export const layoutReducer = (state: LayoutState = initialState, action: ActionDef): LayoutState => {
    let newAlertId: string;
    const newLayoutAlerts: LayoutAlerts = {};
    switch (action.type) {
        case LAYOUT_ALERT_ADD:
            newAlertId = uuid();
            return {
                ...state,
                ...{
                    [LAYOUT_ALERT]: {
                        ...state[LAYOUT_ALERT],
                        [newAlertId]: {
                            "level": "info",
                            ...action.payload,
                            "id": newAlertId
                        }
                    }
                }
            };
        case LAYOUT_ALERT_CLEAR:
            Object.keys(state[LAYOUT_ALERT])
                .filter((alertId: string): boolean => alertId !== action.payload)
                .forEach((alertId: string): void => {
                    newLayoutAlerts[alertId] = state[LAYOUT_ALERT][alertId];
                });
            return {...state, ...{[LAYOUT_ALERT]: newLayoutAlerts}};
        case LAYOUT_ALERT_CLEAR_ALL:
            return {...state, ...{[LAYOUT_ALERT]: {}}};
        case LAYOUT_LOADING:
            return {
                ...state,
                ...{
                    [LAYOUT_LOADING]: {
                        ...state[LAYOUT_LOADING],
                        ...(action.payload as Record<string, boolean>)
                    }
                }
            };
        case LAYOUT_VIEW_MODE:
            return {...state, ...{[LAYOUT_VIEW_COMPACT]: action[LAYOUT_VIEW_COMPACT]}};
        case LAYOUT_VIEW_MODE_TOGGLE:
            return {...state, ...{[LAYOUT_VIEW_COMPACT]: !state[LAYOUT_VIEW_COMPACT]}};
        case LAYOUT_PROPS_UPDATE_DYNAMIC:
            return {
                ...state,
                ...{
                    [LAYOUT_PROPS_DYNAMIC]: {
                        ...state[LAYOUT_PROPS_DYNAMIC],
                        ...(action.payload as any) // eslint-disable-line @typescript-eslint/no-explicit-any
                    }
                }
            };
        default:
            return state;
    }
};

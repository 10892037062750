import React, {FC} from "react";

import {IconFolders, IconTypes, MSIcon} from "../MSIcon";
import classNames from "classnames";

interface MSLabelProps {
    isPencilDisplayed: boolean;
    fieldLabel: string;
    isRequired: boolean;
    error?: string;
}


export const MSLabel: FC<MSLabelProps> = ({isPencilDisplayed, fieldLabel, isRequired, error}) => {
    return (
        <label className={classNames("form-label", {"has-error": error?.length})}>
            {isPencilDisplayed && (<MSIcon icon="edit" iconType={IconTypes.MS} iconFolder={IconFolders.UTILS}
                className="button-icon" />)}
            {fieldLabel}{isRequired && <span className="required-sign">*</span>}</label>
    );
};

import React, {forwardRef, MouseEvent, Ref, useMemo} from "react";
import {MSButton} from "../MSButton";
import {MSIcon} from "../MSIcon";
import {MSMultiSelect} from "../MSMultiSelect";
import {DropdownOption, FilterParams} from "../../types";
import {MultiSelectOption} from "../MSSelect/BaseSelect/types";


type EventType = MouseEvent<Element, globalThis.MouseEvent>

export interface AddFilterButtonProps {
    uiLabel: string,
    availableFilters: Array<FilterParams>,
    additionalFilters: any,
    onFilterAdd: (optionData: MultiSelectOption[]) => void
}

export const AddFilter = (props: AddFilterButtonProps): JSX.Element => {
    const {uiLabel, availableFilters, additionalFilters, onFilterAdd} = props;

    const defaultFiltersOptions = useMemo(() => (additionalFilters: any) => {
        const matchedOptions = additionalFilters.filter((filter: any) => availableFilters.find(availableFilter => availableFilter.name === filter.name));
        return matchedOptions.map((o: any) => o.name);
    }, [availableFilters]);

    const options = useMemo(() => (filterOptions: DropdownOption[]) => (
        filterOptions.map(o => ({uiLabel: o.uiLabel, value: o.value, name: o.name}))
    ), []);

    const FilterButton = useMemo(
        () =>
            forwardRef(({onClick}: {onClick: (e: EventType) => void}, ref: Ref<HTMLDivElement>) => {
                return (
                    <div ref={ref}>
                        <MSButton
                            disabled={false}
                            variant="white"
                            className="ms-select-button"
                            onClick={(e: EventType) => {
                                e.preventDefault();
                                onClick(e);
                            }}
                        >
                            <div className="ms-table-filter-add">
                                <MSIcon icon="fas fa-plus-circle" />
                                <span>{uiLabel}</span>
                            </div>
                        </MSButton>
                    </div>
                );
            }),
        [uiLabel]
    );

    return (
        <MSMultiSelect
            defaultValues={defaultFiltersOptions(additionalFilters)}
            variant="white"
            menuType="checkbox"
            options={options(additionalFilters)}
            onChange={onFilterAdd} title="Add filter" customDropdownButton={FilterButton}
        />
    );
};

import React, {FC} from "react";
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";

import {ScreenSort} from "../types";


export interface TableHeaderProps {
    columnName: string,
    label: string,

    //If sortOptions are null - Header won't show cursor Pointer over it
    sortOptions?: ScreenSort,
    setSort?: (sortOptions: ScreenSort, columnName: string) => void,
    isTooltipVisible?: boolean
}

export const TableHeader: FC<TableHeaderProps> = ({columnName, label, sortOptions, setSort, isTooltipVisible = true}) => {
    let sortArrow = null;
    let onSortHandler = () => {/**/};
    if (sortOptions){
        const sortValue = sortOptions && sortOptions[columnName];
        const sortParams = Object.keys(sortOptions).filter((sortName: string) => sortOptions[sortName]);
        const index = sortParams.findIndex((sortName: string) => sortName === columnName);
        const renderTooltip = (props: TooltipProps) => (
            <Tooltip id="button-tooltip" {...props}>
                Sort order
            </Tooltip>
        );
        sortArrow = sortValue && <>
            <span className={`fa fa-sort-${sortValue === "asc" ? "up" : "down"}`} />
            {isTooltipVisible && sortParams.length > 1 && (
                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={renderTooltip}
                >
                    <span className="sort-order">{` ${index + 1}`}</span>
                </OverlayTrigger>
            )}
        </>;
        onSortHandler = () => {
            setSort({
                ...sortOptions, [columnName]: sortValue === "asc" ? "desc" : (sortValue === "desc" ? null : "asc")
            }, columnName);
        };
    }
    if (!sortArrow){
        sortArrow = <span style={{width: "8.13px", height: "13px"}} />;
    }
    return (
        <div className={`column-header ${sortOptions ? "column-header-sortable" : ""}`} onClick={onSortHandler}>
            {label}
            {sortArrow}
        </div>
    );
};

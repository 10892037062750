import {useMemo} from "react";
import {useLocation, useHistory} from "react-router";
import {FilterOption} from "@medispend/common/src/types";
import {ScreenSort} from "@medispend/admin-common/src/types";
import {PaginationProps} from "@medispend/common/src/components/MSTable/Pagination";
import {DateFilterOption} from "../Dashboard/constants";

interface UseTableQueryParams {
    defaultSort: ScreenSort;
    defaultFilters: (FilterOption | DateFilterOption)[];
    defaultSearchText: string;
    defaultPagination?: Pick<PaginationProps, "pageSize" | "page">;
}

export function useTableQueryParams(props: UseTableQueryParams) {
    const {search} = useLocation();
    const history = useHistory();

    const query = useMemo(() => new URLSearchParams(search), [search]);
    const filtersValues = props.defaultFilters.map(filter => ({...filter, value: query.get(filter.name) || filter.value}));
    const searchText = query.get("searchQuery") || props.defaultSearchText;
    const sort = query.get("sort") ? convertSortFromQueryStringToObject(query.get("sort")) : props.defaultSort;
    const pageSize = Number(query.get("size")) || props.defaultPagination?.pageSize;
    const page = Number(query.get("page")) || props.defaultPagination?.page;

    const queryString = `?page=${page}&size=${pageSize}${convertSortFromObjectToRequestString(sort)}${convertSearchTextToRequestString(searchText)}${convertFiltersToRequestString(filtersValues)}`;

    function updateQueryParams() {
        history.replace({
            search: query.toString()
        });
    }

    function processFilter(filter: FilterOption) {
        if (Array.isArray(filter.value)) {
            if (!filter.value.length) {
                query.delete(filter.name);
            } else {
                query.set(filter.name, filter.value.join(","));
            }
        } else {
            if (!filter.value) {
                query.delete(filter.name);
            } else {
                query.set(filter.name, String(filter.value));
            }
        }

    }

    function handleFilterChange(filter: FilterOption | FilterOption[]) {
        if (Array.isArray(filter)) {
            filter.forEach(processFilter);
        } else {
            processFilter(filter);
        }
        resetPage();
        updateQueryParams();
    }

    function handlePageChange(page: number) {
        query.set("page", String(page));
        updateQueryParams();
    }

    function resetPage() {
        query.set("page", "0");
    }

    function handleSortChange(sort: ScreenSort) {
        const sortValue = convertSortFromObjectToQueryParamString(sort);
        if (sortValue) {
            query.set("sort", sortValue);
        } else {
            query.delete("sort");
        }
        resetPage();
        updateQueryParams();
    }

    function handlePageSizeChange(size: number) {
        query.set("size", String(size));
        resetPage();
        updateQueryParams();
    }

    function convertSortFromQueryStringToObject(sort: string): ScreenSort {
        return sort.split(";").filter(Boolean).reduce<{[key: string]: string}>((prev, current) => {
            const [key, value] = current.split(",");
            prev[key] = value;
            return prev;
        }, {});
    }
    function convertSortFromObjectToQueryParamString(sort: ScreenSort) {
        return Object.entries(sort).filter(([, value]) => Boolean(value)).reduce((prev, [key, value]) => {
            const result = prev += `${key},${value};`;
            return result;
        }, "");
    }

    function convertSortFromObjectToRequestString(sort: ScreenSort) {
        return Object.entries(sort).filter(([, value]) => Boolean(value)).reduce((prev, [key, value]) => {
            const result = prev + `&sort=${key},${value}`;
            return result;
        }, "");
    }

    function convertSearchTextToRequestString(text: string): string {
        return text ? `&searchByName=${text}` : text;
    }

    function convertFiltersToRequestString(filters: (FilterOption | DateFilterOption)[]) {
        const nonNullableFilters = filters.filter(filter =>
            filter.value !== null &&
            filter.value !== undefined &&
            filter.value !== ""
        );
        if (!nonNullableFilters.length) return "";

        return nonNullableFilters.reduce((prev, current) => {
            if ("parseQueryParams" in current && current.parseQueryParams) {
                const str = current.parseQueryParams(query);
                if (str) {
                    prev += `&${str}`;
                }
            } else {
                prev += `&${current.name}=${current.value}`;
            }
            return prev;
        }, "");
    }

    function handleFiltersClear() {
        query.delete("page");
        query.delete("sort");
        query.delete("size");
        query.delete("total");
        query.delete("searchQuery");
        filtersValues.forEach(filter => query.delete(filter.name));
        updateQueryParams();
    }

    function deleteFilter(name: string) {
        query.delete(name);
        updateQueryParams();
    }

    return {
        filtersValues,
        searchText,
        sort,
        pagination: {
            pageSize,
            page
        },
        queryString,
        handleFilterChange,
        handleSortChange,
        handlePageChange,
        handlePageSizeChange,
        handleFiltersClear,
        deleteFilter
    };

}

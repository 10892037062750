import React from "react";

import {UserToggleDropdownOption} from "@medispend/common/src/components/MSLayout";


interface MenuItem {
    name: string;
    options: UserToggleDropdownOption[]
}

export const userToggleBlocks = (logOutHandler: () => void): MenuItem[] => {
    const items = [
        {
            title: "Log Out",
            icon: <img
                className="dropdown-item-icon"
                src="../../../media/img/icons/exit.svg"
                alt="Log Out"
            />,
            path: "",
            block: "logOut",
            isVisible: true,
            action: logOutHandler
        }
    ];
    const logoutBlockItem = items.filter(option => option.block === "logOut");
    const blocks = [];
    logoutBlockItem.length > 0 ? blocks.push({name: "LOGOUT", options: logoutBlockItem}) : null;

    return blocks;
};

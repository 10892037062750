import {BASE_URL} from "../constants";

export const initPendoHelper = (user, configurations) => {
    if (configurations.pendoApiKey) {
        (function (apiKey) {
            (function (p, e, n, d, o) {
                let v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
                for (w = 0, x = v.length; w < x; ++w) (function (m) {
                    o[m] = o[m] || function () {
                        o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                    };
                })(v[w]);
                y = e.createElement(n);
                y.async = !0;
                y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(window, document, "script", "pendo");
        })(configurations.pendoApiKey);
        if (user) {
            window.pendo.initialize({
                visitor: {
                    id: user.impersonator ? user.impersonator.email : user.username,
                    email: user.impersonator ? user.impersonator.email : user.email,
                    full_name: user.impersonator ? user.impersonator.userMetadata.name : user.firstName + " " + user.lastName,
                    functional_areas: user.impersonator ? "" : user.user.functionalAreas.map(f => f.uiLabel),
                    business_units: user.impersonator ? "" : user.user.businessUnits.map(f => f.uiLabel),
                    regions: user.impersonator ? "" : user.user.regions.map(f => f.uiLabel),
                    roles: user.impersonator ? user.impersonatorRoles : user.roles
                },
                account: {
                    id: configurations.clientName,
                    instance: BASE_URL.split(".")[2]
                }
            });
        }
    }
};

export default initPendoHelper;

export const DAYS_MAP = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTH_MAP = ["January", "February", "March", "April", "May",
    "June", "July", "August", "September", "October", "November", "December"];
export const SHORT_DAYS_MAP = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const ONE_DAY = 86400000;
export const CURRENT_YEAR = new Date().getFullYear();
export const YEAR_RANGE_LIST_OPTIONS = Array(14).fill(CURRENT_YEAR - 6)
    .map((element, index) => {
        const year = element + index;
        return {uiLabel: year, value: year};
    });

export const TODAY_TIMESTAMP = Date.now() - (Date.now() % ONE_DAY) + (new Date().getTimezoneOffset() * 1000 * 60);

export const SHORT_ISO_FORMAT = "yyyy-MM-dd";

export const DROPDOWN_DATE_FORMAT = "yyyy-MM-dd";

import React from "react";
import {AppState} from "../state/reducers/types";
import {ClientPropertyKey} from "@medispend/common/src/types";
import {ColumnData} from "../types";
import {Alert} from "@medispend/dashboard/src/Dashboard/Alert";
import {MSSelect} from "@medispend/common/src/components/MSSelect";


export const INITIAL_STATE: AppState = {
    insights: {
        defaultDashboardId: 10,
        error: null,
        loading: false
    },
    sdLayout: {
        LAYOUT_ALERT: {},
        LAYOUT_LOADING: {
            LAYOUT_LOADING_CONTENT: false,
            LAYOUT_LOADING_SIDENAV: false
        },
        LAYOUT_PROPS_DYNAMIC: {
            LAYOUT_VIEW_COMPACT: false
        },
        LAYOUT_VIEW_COMPACT: false
    },
    system: {
        error: null,
        language: "en",
        languageTranslations: {
            "grants.taskList": ["Task List"],
            "grants.title": ["Grants"],
            "grants.searchEdit": ["Search/Edit"],
            "grants.add": ["Add"]
        },
        loading: false
    }
};

export const CLIENT_PROPERTIES = [
    {propertyKey: ClientPropertyKey.ERP_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.GRANTS_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.NEEDS_ASSESSMENT_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.HCPQ_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.CONTRACTS_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.ENGAGEMENTS_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.FMV_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.ACTIVITY_REPORTS_ENABLED, propertyValue: "true"},
    {propertyKey: ClientPropertyKey.INSIGHT_ENABLED, propertyValue: "true"}
];

export const TABLE_COLUMNS = [
    {
        id: "alert",
        accessor: "alert",
        Cell: function getAlert(data: ColumnData) {
            const {row: {original}} = data;
            const {uiName, reason, summary, linksToEntity} = original;
            return (
                <Alert
                    title={summary}
                    reason={reason}
                    alertType={uiName}
                    link={linksToEntity}
                />
            );
        }
    },
    {
        id: "close",
        accessor: "alert",
        Cell: function getCloseButton(data: ColumnData) {
            const {row: {original}} = data;
            const {isDismissed} = original;
            return (
                !isDismissed ? (
                    <button data-testid="dismiss-button" onClick={() => jest.fn()}>
                        <img src="" width={17} height={17} alt="Close" />
                    </button>
                ) : null
            );
        }
    }
];

export const AVAILABLE_FILTERS = [
    {
        title: "Alert period",
        name: "ageDays",
        component: MSSelect,
        options: [
            {value: null, uiLabel: "All", name: "ageDays"},
            {value: 7, uiLabel: "7 days", name: "ageDays"},
            {value: 14, uiLabel: "14 days", name: "ageDays"},
            {value: 30, uiLabel: "30 days", name: "ageDays"},
            {value: 60, uiLabel: "60 days", name: "ageDays"}
        ]
    },
    {
        title: "Status",
        name: "isDismissed",
        component: MSSelect,
        options: [
            {id: 1, value: false, uiLabel: "Active Alerts", name: "isDismissed", shortName: "active"},
            {id: 2, value: true, uiLabel: "Removed Alerts (last 60 days)", name: "isDismissed", shortName: "deactivated"}
        ]
    }
];

export const FILTERS_VALUES = [
    {value: "Ascending", uiLabel: "Ascending", name: "sorting"},
    {value: null, uiLabel: "All", name: "ageDays"},
    {value: false, uiLabel: "Active Alerts", name: "isDismissed"}
];

export const ADDITIONAL_FILTERS = [
    {value: "test", uiLabel: "test", name: "test"},
    {value: "test02", uiLabel: "test 2", name: "test02"}
];

import {MSMultiSelect} from "@medispend/common/src/components/MSMultiSelect";
import {DropdownOption, FilterOption, FilterParams} from "@medispend/common/src/types";
import {BRListFilters, RequestBodyFilters} from "../common/types";
import {ScreenSort} from "@medispend/admin-common/src/types";
import {RangePicker} from "@medispend/common/src/components/MSDatepicker/RangePicker";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";
import {BusinessRulesMetadata, ColumnType, DateFilter, Rule} from "../types";
import {SelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";

//This interface may be transfered as main filter interface in future if we decide to make all tables work with query params
export interface DateFilterOption extends DropdownOption {
    parseQueryParams?: (query: URLSearchParams) => string;
}

export const SEARCH_PARAMS = {
    title: "Search By",
    name: "search",
    defaultValue: {value: "searchQuery", uiLabel: "Rule Name", name: "Search By"}
};

export const STATUS_OPTIONS: DropdownOption[] = [
    {id: 2, value: "DRAFT", uiLabel: "DRAFT", name: "DRAFT"},
    {id: 4, value: "TEST", uiLabel: "TEST", name: "TEST"}
];
export const STATUS_FILTER_OPTIONS: DropdownOption[] = [
    {id: 1, value: "DISABLED", uiLabel: "DISABLED", name: "DISABLED"},
    {id: 2, value: "DRAFT", uiLabel: "DRAFT", name: "DRAFT"},
    {id: 3, value: "ENABLED", uiLabel: "ENABLED", name: "ENABLED"},
    {id: 4, value: "TEST", uiLabel: "TEST", name: "TEST"}
];

export const RULE_TYPE_OPTIONS: DropdownOption[] = [
    {id: 1, value: "CUSTOM", uiLabel: "CUSTOM", name: "CUSTOM"},
    {id: 2, value: "STANDARD", uiLabel: "STANDARD", name: "STANDARD"}
];

export const RULE_SETTINGS_OPTIONS: DropdownOption[] = [
    {value: "VIEW", uiLabel: "View", name: "View"},
    ...(isMedispendSubDom ? [
        {value: "COPY", uiLabel: "Copy", name: "Copy"},
        {value: "ENABLE", uiLabel: "Enable", name: "Enable"},
        {value: "DISABLE", uiLabel: "Disable", name: "Disable"}] : [])
];

export const formattedByNameOptions = (option: {name: string, displayName: string, isDefault: boolean}[]): SelectOption[] => {
    return option ? option.map(property =>
        ({name: property.name, value: property.name, uiLabel: property.displayName, isDefault: property.isDefault})).filter(element => !element.isDefault) : [];};

export const formattedByIdOptions = (option: {id: number, name: string}[]): SelectOption[] => option ? option.map(property =>
    ({name: property.name, value: property.id, uiLabel: property.name})) : [];

export const getTableFilters = (options: BusinessRulesMetadata, query: URLSearchParams): Array<FilterParams> => {
    const formattedByNameOptions = (option: {name: string, displayName: string}[]) => option ? option.map(property =>
        ({name: property.name, value: property.name, uiLabel: property.displayName})) : [];
    const formattedByIdOptions = (option: {id: number, name: string}[]) => option ? option.map(property =>
        ({name: property.name, value: property.id, uiLabel: property.name})) : [];
    const filters: FilterParams[] = [
        ...(isMedispendSubDom ? [{
            title: "Client",
            name: "clientName",
            component: MSMultiSelect,
            isMulti: true,
            options: formattedByNameOptions(options?.clients)
        }] : []),
        {
            title: "Rule Type",
            name: "ruleType",
            component: MSMultiSelect,
            isMulti: true,
            options: RULE_TYPE_OPTIONS
        },
        {
            title: "Status",
            name: "status",
            component: MSMultiSelect,
            isMulti: true,
            options: STATUS_FILTER_OPTIONS
        },
        {
            title: "Process",
            name: "processId",
            component: MSMultiSelect,
            isMulti: true,
            options: formattedByIdOptions(options?.processes)
        }
    ];

    if (query.get("expirationDate")) {
        filters.push(DATE_FILTERS.expirationDate);
    }

    if (query.get("effectiveDate")) {
        filters.push(DATE_FILTERS.effectiveDate);
    }


    return filters;
};

export const DEFAULT_BR_FILTERS: FilterOption[] = [
    ...(isMedispendSubDom ? [{value: null, uiLabel: "client", name: "clientName"}] : []),
    {value: null, uiLabel: "ruleType", name: "ruleType"},
    {value: null, uiLabel: "status", name: "status"},
    {value: null, uiLabel: "process", name: "processId"}
];

export const INIT_BR_FILTERS: FilterOption[] = [
    ...(isMedispendSubDom ? [{value: ["medispend"], uiLabel: "client", name: "clientName"}] : []),
    {value: null, uiLabel: "ruleType", name: "ruleType"},
    {value: null, uiLabel: "status", name: "status"},
    {value: null, uiLabel: "process", name: "processId"}
];

export const DEFAULT_REQUEST_BODY: RequestBodyFilters<BRListFilters> = {
    filters: {
        ...(isMedispendSubDom ? {clientName: null} : {}),
        ruleType: null,
        status: null,
        process: null
    },
    searchQuery: ""
};

export const INIT_REQUEST_BODY: RequestBodyFilters<BRListFilters> = {
    filters: {
        ...(isMedispendSubDom ? {clientName: ["medispend"]} : {}),
        ruleType: null,
        status: null,
        process: null
    },
    searchQuery: ""
};

export const ADDITIONAL_FILTERS: DateFilterOption[] = [
    {
        uiLabel: "Effective Date",
        value: "effectiveDate",
        name: "effectiveDate",
        parseQueryParams: (query: URLSearchParams): string => {
            const [startDate, endDate] = query.get("effectiveDate")?.split(",") || [];
            const result = [];
            if (startDate) {
                result.push("effectiveDateStart=" + startDate);
            }
            if (endDate) {
                result.push("effectiveDateEnd=" + endDate);
            }

            return result.join("&");
        }
    },
    {
        uiLabel: "Expiration Date",
        value: "expirationDate",
        name: "expirationDate",
        parseQueryParams: (query: URLSearchParams): string => {
            const [startDate, endDate] = query.get("expirationDate")?.split(",") || [];
            const result = [];
            if (startDate) {
                result.push("expirationDateDateStart=" + startDate);
            }
            if (endDate) {
                result.push("expirationDateDateEnd=" + endDate);
            }

            return result.join("&");
        }
    }
];

export const DEFAULT_SORT: ScreenSort = {
    ...(isMedispendSubDom ? {clientName: "asc"} : {}),
    ruleType: null,
    process: null,
    name: "asc",
    status: isMedispendSubDom ? null : "asc",
    effectiveDate: null,
    expiryDate: null,
    ruleId: null
};

export const INIT_SORT: ScreenSort = {
    ...(isMedispendSubDom ? {clientName: null} : {}),
    ruleType: null,
    process: null,
    name: "asc",
    status: isMedispendSubDom ? null : "asc",
    effectiveDate: null,
    expiryDate: null,
    ruleId: null
};

export const DATE_FILTERS: DateFilter = {
    expirationDate: {
        title: "Expiration date",
        name: "expirationDate",
        component: RangePicker,
        options: [
            {value: null, uiLabel: "Start Date", name: "expiryDateStart"},
            {value: null, uiLabel: "End Date", name: "expiryDateEnd"}
        ],
        isDate: true,
        isAdditional: true
    },
    effectiveDate: {
        title: "Effective date",
        name: "effectiveDate",
        component: RangePicker,
        options: [
            {value: null, uiLabel: "Start Date", name: "effectiveDateStart"},
            {value: null, uiLabel: "End Date", name: "effectiveDateEnd"}
        ],
        isDate: true,
        isAdditional: true
    }
};

export const INIT_COMMENTS: Rule = {name: "Comments", value: "", type: ColumnType.COMMENTS};

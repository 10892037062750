import {ENV_CONFIG} from "@medispend/common/src/constants/authentication";

export enum AppsList {
    CFF = "cff",
    BR = "br",
    GLOBAL_ADMIN = "global-admin",
    WORKFLOW = "workflow"
}

export const isLocalMode = ENV_CONFIG.BUILD_ENV === "local";
export const isProductionMode = ENV_CONFIG.BUILD_ENV !== "local";

export const {PORT, BUILD_ENV, LOCATION, LOCAL_CFF_URL, LOCAL_BR_URL, LOCAL_GLOBAL_ADMIN_URL, TS_URL, EM_URL} = ENV_CONFIG;

export const AUTH0_USER_CACHE_KEY = "AUTH0_USER";
export const GLOBAL_ADMIN_CLIENTS_CACHE_KEY = "GLOBAL_ADMIN_CLIENTS";
export const CACHE_TIMEOUT_SEC = 600;

export const AUTH0_APP_METADATA_CLAIM = "http://medispend/app_metadata";

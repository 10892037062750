import classNames from "classnames";
import React, {FunctionComponent} from "react";

import {DropdownOption, FilterItem, FilterParams, SortSearchParams} from "../../types";
import {hasPageSize, PageSize, PageSizeProps} from "./PageSize";
import {TextSearch} from "./TextSearch";
import {MSSelect} from "../MSSelect";
import {ButtonColors} from "../MSButton/types";


export interface TableToolsProps {
    showTextSearch?: boolean;
    showSearchSwitcher?: boolean;
    showSearchName?: boolean;
    textSearchPlaceholder?: string;
    textSearchByPlaceholder?: string;
    pageSizeOpts?: PageSizeProps;
    showSorting?: boolean;
    sort: DropdownOption;
    sortParams: SortSearchParams;
    searchParams?: Omit<FilterParams, "component">;
    searchType?: DropdownOption;
    onToggleSort?: (sortParams: FilterItem<string>) => void;
    onSearchTextChange?: (search: string) => void;
    onFilterChange: (filterValue: DropdownOption) => void;
    searchValue?: string;
    clearSearchWithFilters?: boolean;
}

export const TableTools: FunctionComponent<TableToolsProps> = (props) => {
    const {
        pageSizeOpts,
        showTextSearch = true,
        showSearchSwitcher,
        showSearchName,
        textSearchPlaceholder,
        textSearchByPlaceholder,
        showSorting,
        sort,
        sortParams,
        searchParams,
        searchType,
        onToggleSort,
        onSearchTextChange,
        onFilterChange,
        searchValue,
        clearSearchWithFilters = false
    } = props;

    const hasPageSizeOpts = hasPageSize(pageSizeOpts);

    return (
        <div className={classNames("ms-table-tools", {"has-page-size-opts": hasPageSizeOpts})}>
            <div className="search-block">
                {showTextSearch && (
                    <TextSearch
                        showSearchSwitcher={showSearchSwitcher}
                        showSearchName={showSearchName}
                        searchParams={searchParams}
                        searchType={searchType}
                        onTextChange={onSearchTextChange}
                        onFilterChange={onFilterChange}
                        textSearchPlaceholder={textSearchPlaceholder}
                        textSearchByPlaceholder={textSearchByPlaceholder}
                        searchValue={searchValue}
                        clearSearchWithFilters={clearSearchWithFilters}
                    />
                )}
            </div>
            <div id="additional-tools" />
            <div className="ms-table-tools-right">
                {showSorting && (
                    <MSSelect
                        options={sortParams.options}
                        title={sortParams.title || "Sort By"}
                        defaultValue={sort.value}
                        size="md"
                        variant={ButtonColors.grey800}
                        onChange={onToggleSort}
                    />
                )}
                {hasPageSizeOpts && <PageSize
                    pageSizeOptions={pageSizeOpts.pageSizeOptions}
                    pageSize={pageSizeOpts.pageSize}
                    showLabel={pageSizeOpts.showLabel}
                    rowsLabel={pageSizeOpts.rowsLabel}
                    onPageSizeChange={pageSizeOpts.onPageSizeChange}
                />}
            </div>
        </div>
    );
};

import React from "react";

import "./scss/style.scss";

export interface FormWrapperProps {
    title?: string;
    controls?: JSX.Element
}

export const FormWrapper: React.FC<FormWrapperProps> = (props) => {
    const {title = "", children, controls = null} = props;

    return (<div className="create-rule-container">
        <div className="form-container">
            <div className="create-rule-header">
                <h2>{title}</h2>
            </div>
            {children}
        </div>
        {controls}
    </div>);
};

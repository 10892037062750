import {BASE_URL} from "../constants";

export const redirectToOldUI = (path: string): void => {
    window.location.href = BASE_URL + path;
};

export const prepareURLSeachParams = (params: Record<string, string | number>): string => {
    const urlSearchParams = new URLSearchParams();

    Object.keys(params).forEach((paramKey) => {
        if (params[paramKey]) {
            urlSearchParams.append(paramKey, params[paramKey].toString());
        }
    });
    const preparedParams = urlSearchParams.toString();

    return preparedParams ? `?${preparedParams}` : "";
};

import {useEffect} from "react";
import {zip} from "rxjs";
import {mapActuatorJson} from "@medispend/common/src/utils/version";
import {BR_API_NAME, BR_STORAGE_KEY} from "@medispend/common/src/components/MSVersionModal/constants";
import {getAjax} from "../services";

export const useGetVersionData = (): void => {
    useEffect(() => {
        zip(getAjax("/api/v1/actuator/info")).subscribe(([response]) => {
            localStorage.setItem(BR_STORAGE_KEY, JSON.stringify(mapActuatorJson(response.data, BR_API_NAME)));
        });
    }, []);
};

export const TRANSLATION_KEYS = {
    DATE_FORMAT: "common.dateFormat",
    TIME_FORMAT: "common.timeFormat",

    GRANTS_TITLE: "grants.title",
    GRANTS_TASK_LIST: "grants.taskList",
    GRANTS_SEARCH_EDIT: "grants.searchEdit",
    GRANTS_APPLY: "grants.applyForGrant",
    GRANTS_SEARCH_EXTERNAL: "grants.searchExternal",
    GRANTS_ADD: "grants.add",

    CREATE_NEW_BUTTON: "common.button.createNew",
    CREATE_NEW_MENU_NA: "common.createNewMenu.needsAssessment",
    CREATE_NEW_MENU_SEARCH_CUSTOMER_MASTER: "common.createNewMenu.searchCustomerMaster",
    CREATE_NEW_MENU_CONTRACT: "common.createNewMenu.contract",
    CREATE_NEW_MENU_ENGAGEMENT: "common.createNewMenu.engagement",
    CREATE_NEW_MENU_ADD_FMW_RATE: "common.createNewMenu.addFMVRate",
    CREATE_NEW_MENU_IMPORT_FMV_RATES: "common.createNewMenu.importFMVRates",

    NAVIGATION_FMV_CALCULATOR: "common.navigation.fmvCalculator",
    NAVIGATION_NA: "common.navigation.needsAssessment",
    NAVIGATION_QUALIFICATIONS: "common.navigation.qualifications",
    NAVIGATION_CONTRACTS: "common.navigation.contracts",
    NAVIGATION_ENGAGEMENTS: "common.navigation.engagements",
    NAVIGATION_ACTIVITY_PAYMENTS: "common.navigation.activityPayments",
    NAVIGATION_GRANTS_MANAGER: "common.navigation.grantsManager",
    NAVIGATION_INSIGHTS: "common.navigation.insights",
    NAVIGATION_EXPAND_SIDE_BAR: "common.navigation.expandSideBar",
    NAVIGATION_COLLAPSE_SIDE_BAR: "common.navigation.collapseSideBar",

    NAVIGATION_HELP: "common.navigation.help",
    NAVIGATION_HELP_ABOUT: "common.navigation.helpAbout",
    NAVIGATION_USER_MENU_PERSONAL: "common.navigationUserMenu.personal",
    NAVIGATION_USER_MENU_CHANGE_PASSWORD: "common.navigationUserMenu.changePassword",
    NAVIGATION_USER_MENU_BULK_REASSIGNMENT: "common.navigationUserMenu.bulkReassignment",
    NAVIGATION_USER_MENU_AUTO_APPROVALS: "common.navigationUserMenu.autoApprovals",
    NAVIGATION_USER_MENU_ADMIN: "common.navigationUserMenu.admin",
    NAVIGATION_USER_MENU_SEARCH_FMV_RATES: "common.navigationUserMenu.searchFMVRates",
    NAVIGATION_USER_MENU_VIEW_FMV_IMPORT_HISTORY: "common.navigationUserMenu.viewFMVImportHistory",
    NAVIGATION_USER_MENU_LOAD_REMITTANCE_FILE: "common.navigationUserMenu.loadRemittanceFile",
    NAVIGATION_USER_MENU_MANAGE_PAYMENT_INFORMATION: "common.navigationUserMenu.managePaymentInformation",
    NAVIGATION_USER_MENU_MANAGE_VENDOR_INFORMATION: "common.navigationUserMenu.manageVendorInformation",
    NAVIGATION_USER_MENU_MANAGE_USER_ATTRIBUTES: "common.navigationUserMenu.manageUserAttributes",
    NAVIGATION_USER_MENU_MANAGE_COMMITTEES: "common.navigationUserMenu.manageCommittees",
    NAVIGATION_USER_MENU_MANAGE_COMPANY_ADDRESS: "common.navigationUserMenu.manageCompanyAddress",
    NAVIGATION_USER_MENU_MANAGE_COMPANY_LOGO: "common.navigationUserMenu.manageCompanyLogo",
    NAVIGATION_USER_MENU_LOG_OUT: "common.navigationUserMenu.logOut",

    NAVIGATION_USER_MENU_MEDISPEND_ADMIN: "common.navigationUserMenu.medispendAdmin",
    NAVIGATION_USER_MENU_USER_CLOAKING: "common.navigationUserMenu.userCloaking",
    NAVIGATION_USER_MENU_CLIENT_PROPERTIES: "common.navigationUserMenu.clientProperties",
    NAVIGATION_USER_MENU_PICKLIST_ITEMS: "common.navigationUserMenu.picklistItems",
    NAVIGATION_USER_MENU_CONFIGURE_INSIGHT: "common.navigationUserMenu.configureInsight",

    //WORKFLOW
    WORKFLOW_BUTTON_SUBMIT_RECORD: "common.workflow.button.submitRecord",
    WORKFLOW_BUTTON_APPROVE_RECORD: "common.workflow.button.approveRecord",
    WORKFLOW_BUTTON_RETURN_RECORD: "common.workflow.button.returnRecord",

    // EM-20596 - these are synonyms
    WORKFLOW_BUTTON_CLOSE_RECORD: "common.workflow.button.closeRecord",
    WORKFLOW_BUTTON_CANCEL_WITH_PAY_RECORD: "common.workflow.button.cancelRecordWithPay",
    WORKFLOW_BUTTON_CANCEL_NO_PAY_RECORD: "common.workflow.button.cancelRecordNoPay",

    WORKFLOW_BUTTON_START_ASSIGNMENT: "common.workflow.button.startAssignment",
    WORKFLOW_BUTTON_START_CLOSURE: "common.workflow.button.startClosure",
    WORKFLOW_BUTTON_START_CANCEL: "common.workflow.button.startCancel",

    WORKFLOW_BUTTON_RETURN_TO_REQUESTOR: "common.workflow.button.returnToRequestor",
    WORKFLOW_BUTTON_START_APPROVAL: "common.workflow.button.startApproval",
    WORKFLOW_BUTTON_RESUBMIT: "common.workflow.button.reSubmit",
    WORKFLOW_BUTTON_START_RESUBMIT: "common.workflow.button.startReSubmit",
    WORKFLOW_BUTTON_CANCEL: "common.workflow.button.cancel",

    WORKFLOW_RETURN_TO_ENGAGEMENT: "common.workflow.returnToEngagement",
    WORKFLOW_RETURN_TO_CONTRACT: "common.workflow.returnToContract",
    WORKFLOW_RETURN_TO_NEEDS_ASSESSMENT: "common.workflow.returnToNeedsAssessment",
    WORKFLOW_RETURN_TO_HCP: "common.workflow.returnToHcp",
    WORKFLOW_RETURN_TO_GRANT: "common.workflow.returnToGrant",
    WORKFLOW_RETURN_TO_ENGAGEMENT_HCP: "common.workflow.returnToEngagementHcp",
    WORKFLOW_RETURN_TO_ACTIVITY_REPORT: "common.workflow.returnToActivityReport",

    WORKFLOW_TITLE: "common.workflow.title",
    WORKFLOW_TITLE_ENGAGEMENT: "common.workflow.title.engagement",
    WORKFLOW_TITLE_CONTRACT: "common.workflow.title.contract",
    WORKFLOW_TITLE_NEEDS_ASSESSMENT: "common.workflow.title.needsAssessment",
    WORKFLOW_TITLE_HCP: "common.workflow.title.hcp",
    WORKFLOW_TITLE_GRANT: "common.workflow.title.grant",
    WORKFLOW_TITLE_ENGAGEMENT_HCP: "common.workflow.title.engagementHcp",
    WORKFLOW_TITLE_ACTIVITY_REPORT: "common.workflow.title.activityReport",
    WORKFLOW_RECORD_TITLE_ACTIVITY_REPORT: "common.workflow.recordTitle.activityReport",

    WORKFLOW_STATUS_DRAFT: "common.workflow.status.draft",
    WORKFLOW_STATUS_IN_REVIEW: "common.workflow.status.inReview",
    WORKFLOW_STATUS_APPROVED: "common.workflow.status.approved",

    WORKFLOW_STATUS_CLOSED: "common.workflow.status.closed",
    WORKFLOW_STATUS_CANCEL_WITH_PAY: "common.workflow.status.cancelWithPay",
    WORKFLOW_STATUS_CANCEL_NO_PAY: "common.workflow.status.cancelNoPay",

    WORKFLOW_STATUS_RETURNED: "common.workflow.status.returned",
    WORKFLOW_STATUS_SENT_TO_CONTRACT_SYSTEM: "common.contractStatus.sentToContractSystem",
    WORKFLOW_STATUS_MIGRATION: "common.workflow.status.migration",

    WORKFLOW_NOTES_LABEL: "common.workflow.notesLabel",
    WORKFLOW_COMPLETED_BY: "common.workflow.completedBy",
    WORKFLOW_ASSIGNEES: "common.workflow.assignees",
    WORKFLOW_NOTES_PLACEHOLDER: "common.workflow.notesPlaceholder",

    //Workflow Activity Names
    WORKFLOW_ASSIGN_REVIEWER_FOR_RECORD: "common.workflow.assignReviewerForRecord",
    WORKFLOW_RECORD_CREATED: "common.workflow.recordCreated",
    WORKFLOW_APPROVE_RECORD: "common.workflow.approveRecord",
    WORKFLOW_RETURN_TO_REQUESTER: "common.workflow.returnToRequester",

    WORKFLOW_CLOSE_RECORD: "common.workflow.closeRecord",
    WORKFLOW_CANCEL_WITH_PAY_RECORD: "common.workflow.cancelWithPayRecord",
    WORKFLOW_CANCEL_NO_PAY_RECORD: "common.workflow.cancelNoPayRecord",

    WORKFLOW_AUTO_APPROVAL: "common.workflow.autoApproval",
    WORKFLOW_MIGRATED_FROM_LEGACY_WORKFLOW: "common.workflow.migratedFromLegacyWorkflow",
    WORKFLOW_AUTO_CLOSE: "common.workflow.autoClose",
    WORKFLOW_REVIEW_STEP: "common.workflow.reviewStep",
    WORKFLOW_CHECK_SYNC_EXTERNAL_SYSTEM: "common.workflow.checkSyncExternalSystem",
    WORKFLOW_SYNC_EXTERNAL_SYSTEM: "common.workflow.syncExternalSystem",
    WORKFLOW_EXTERNAL_SYNC_RESPONSE: "common.workflow.externalSyncResponse",
    WORKFLOW_REVIEW_RECORD: "common.workflow.reviewRecord",
    WORKFLOW_PLEASE_SELECT_REVIEWER: "common.workflow.pleaseSelectReviewer",
    WORKFLOW_PLEASE_SELECT_CONTRACT_REQUESTER: "common.workflow.pleaseSelectContractRequester",

    WORKFLOW_SELECT_ASSIGNEE: "common.workflow.selectAssignee",
    WORKFLOW_SELECT_CONTRACT_REQUESTER: "common.workflow.selectContractRequester",
    WORKFLOW_ASSIGN_TO: "common.workflow.assignTo",
    WORKFLOW_ASSIGN_TO_REQUESTERS: "common.workflow.assignToRequesters",
    WORKFLOW_RE_SUBMIT_RECORD: "common.workflow.reSubmitRecord",

    WORKFLOW_ALERT_MESSAGE_NOTES_CANCEL_WITH_PAY: "common.workflow.alertMessage.notes.cancelWithPay",
    WORKFLOW_ALERT_MESSAGE_NOTES_CANCEL_NO_PAY: "common.workflow.alertMessage.notes.cancelNoPay",

    PAGINATION_SHOWING_LABEL: "common.pagination.showingLabel",
    PAGINATION_OF_LABEL: "common.pagination.ofLabel",
    PAGINATION_ENTRIES_LABEL: "common.pagination.entriesLabel",
    PAGINATION_SHOW_LABEL: "common.pagination.showLabel",
    PAGINATION_ROWS_LABEL: "common.pagination.rowsLabel",
    SORTING_SORT_BY: "common.sort.sortBy",
    SORTING_ASCENDING: "common.sort.ascending",
    SORTING_DESCENDING: "common.sort.descending",

    DASHBOARD_HOME: "common.dashboard.home",
    DASHBOARD_WELCOME: "common.dashboard.welcome",
    DASHBOARD_MY_TASKS: "common.dashboard.myTasks",
    DASHBOARD_PENDING_MY_REVIEW: "common.dashboard.pendingMyReview",
    DASHBOARD_RETURNED_TO_ME: "common.dashboard.returnedToMe",
    DASHBOARD_MY_RECORD_IN_PROGRESS: "common.dashboard.myRecordsInProgress",
    DASHBOARD_RECORDS_IN_REVIEW: "common.dashboard.recordsInReview",
    DASHBOARD_PENDING_WORKFLOW_SUBMISSION: "common.dashboard.pendingWorkflowSubmission",
    DASHBOARD_YOU_HAVE_ALERTS: "common.dashboard.youHaveAlerts",
    DASHBOARD_VIEW_ALL_ALERTS: "common.dashboard.viewAllAlerts",
    DASHBOARD_FILTER_TYPE: "common.dashboard.filter.type",
    DASHBOARD_FILTER_STATUS: "common.dashboard.filter.status",
    DASHBOARD_FILTER_MODULE: "common.dashboard.filter.module",
    DASHBOARD_FILTER_DAYS: "common.dashboard.filter.days",
    DASHBOARD_SEARCH_BY: "common.dashboard.searchBy",
    DASHBOARD_TEXT_SEARCH: "common.dashboard.textSearch",
    DASHBOARD_ID_SEARCH: "common.dashboard.idSearch",

    DASHBOARD_MODULE_NEEDS_ASSESSMENTS: "common.dashboard.moduleNeedsAssessments",
    DASHBOARD_MODULE_HCP: "common.dashboard.moduleHCP",
    DASHBOARD_MODULE_CONTRACTS: "common.dashboard.moduleContracts",
    DASHBOARD_MODULE_ENGAGEMENTS: "common.dashboard.moduleEngagements",
    DASHBOARD_MODULE_ENGAGEMENTS_HCP: "common.dashboard.moduleEngagementsHCP",
    DASHBOARD_MODULE_ACTIVITY_AND_PAYMENTS: "common.dashboard.moduleActivityAndPayments",
    DASHBOARD_MODULE_CREDIT_MEMO: "common.dashboard.moduleCreditMemo",

    DASHBOARD_STATUS_OPEN: "common.dashboard.statusOpen",
    DASHBOARD_STATUS_IN_REVIEW: "common.dashboard.statusInReview",
    DASHBOARD_FILTER_ALL: "common.dashboard.filterAll",
    DASHBOARD_RECORD_STATUS_DRAFT: "common.dashboard.recordStatus.draft",
    DASHBOARD_RECORD_STATUS_RELEASED: "common.dashboard.recordStatus.released",
    DASHBOARD_RECORD_STATUS_IN_REVIEW: "common.dashboard.recordStatus.inReview",
    DASHBOARD_RECORD_STATUS_SENT_TO_CONTRACT_SYSTEM: "common.dashboard.recordStatus.sentToContractSystem",
    DASHBOARD_STATUS_CONDITIONALLY_APPROVED: "common.dashboard.statusConditionallyApproved",
    DASHBOARD_ADDITIONAL_STATUS_CONTRACT_PENDING: "common.dashboard.additionalStatus.contractPending",
    DASHBOARD_ADDITIONAL_STATUS_CONTRACT_OPEN: "common.dashboard.additionalStatus.contractOpen",
    DASHBOARD_ADDITIONAL_STATUS_CONTRACT_IN_REVIEW: "common.dashboard.additionalStatus.contractInReview",

    DASHBOARD_ALERTS_EXPIRING_ON: "common.alerts.expiringOn",
    DASHBOARD_ALERTS_STARTING_ON: "common.alerts.startingOn",
    DASHBOARD_ALERTS_ENGAGEMENT_COMPLETED_ON: "common.alerts.engagementCompletedOn",
    DASHBOARD_ALERTS_NO_AR: "common.alerts.noAr",
    DASHBOARD_COMMON_ALERTS: "common.alerts",
    DASHBOARD_ALERTS_TYPE_EXPIRING_NEEDS_ASSESSMENT: "common.alerts.typeExpiringNeedsAssessment",
    DASHBOARD_ALERTS_TYPE_EXPIRING_HCPQ_QUALIFICATIONS: "common.alerts.typeExpiringHcpQualifications",
    DASHBOARD_ALERTS_TYPE_EXPIRING_CONTRACTS: "common.alerts.typeExpiringContracts",
    DASHBOARD_ALERTS_TYPE_UPCOMING_ENGAGEMENTS: "common.alerts.typeUpcomingEngagements",
    DASHBOARD_ALERTS_TYPE_AGING_ENGAGED_HCPS: "common.alerts.typeAgingEngagedHcps",
    DASHBOARD_ALERTS_PERIOD: "common.alerts.alertPeriod",
    DASHBOARD_ALERTS_STATUS_ACTIVE: "common.alerts.alertStatusActive",
    DASHBOARD_ALERTS_STATUS_REMOVED: "common.alerts.alertStatusRemoved",

    MY_TASKS_EXPORT_DETAILS: "common.myTasks.exportDetails",
    MY_TASKS_RECORD_SUBMITTED_ON: "common.myTasks.recordSubmittedOn",

    MY_TASKS_ASSIGNED: "common.myTasks.assigned",
    MY_TASKS_TYPE_RETURNED_TO_ME: "common.myTasks.typeReturnedToMe",
    MY_TASKS_TYPE_PENDING_MY_REVIEW: "common.myTasks.typePendingMyReview",

    RECORDS_IN_PROGRESS_LAST_UPDATED: "common.myRecordsInProgress.lastUpdated",
    RECORDS_IN_PROGRESS_TYPE_PENDING_SUBMISSION: "common.myRecordsInProgress.typePendingSubmission",
    RECORDS_IN_PROGRESS_TYPE_UNDER_REVIEW: "common.myRecordsInProgress.typeUnderReview",
    RECORDS_IN_PROGRESS_RECORD_CREATED_ON: "common.myRecordsInProgress.recordCreatedOn",
    RECORDS_IN_PROGRESS_RECORD_PENDING_CMS_SINCE: "common.myRecordsInProgress.recordPendingCmsSince",
    RECORDS_IN_PROGRESS_RECORD_PENDING_SINCE: "common.myRecordsInProgress.recordPendingSince"
};

export const DEFAULT_LANGUAGE = {
    languageName: "English",
    locale: "en"
};

import {Translation} from "../state/thunky/types";

export const getTranslationText = (key: string, currentLanguageTranslations: Translation, defaultValue = ""): string => {
    if (key && currentLanguageTranslations && currentLanguageTranslations[key]) {
        return (currentLanguageTranslations[key] as string[])[0];
    }
    return defaultValue;
};

export const getTranslationTextWithParams = (key: string, params: Array<string>, currentLanguageTranslations: Translation, defaultValue = ""): string => {
    let translatedText = getTranslationText(key, currentLanguageTranslations, defaultValue);
    params.forEach((param, index) => {
        translatedText = translatedText.replace(`{${index}}`, param);
    });

    return translatedText;
};

export const formatTextWithDate = (text: string, date: string): string => {
    return `\u202B${text} \u202A${date}`;
};

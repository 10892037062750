import React, {FunctionComponent} from "react";
import "./index.scss";
import generatedGitInfo from "@medispend/unity/src/utils/git/generatedGitInfo.json";

export const MSFooter: FunctionComponent = () => {
    const privacyUrl = "https://www.medispend.com/data-privacy-policy/";
    const year = +generatedGitInfo.buildTime.split("-")[0];

    return <footer className="ms-footer">
        <div className="ms-footer-content">
            <span>Copyright &copy; {year} MMIS, Inc. </span>
            <span>All Rights reserved | </span>
            <a href={privacyUrl} target="_blank" rel="noopener noreferrer">Privacy</a>
        </div>
    </footer>;
};

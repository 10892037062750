import React, { FC, MouseEvent, FormEvent } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";

import { ButtonColors, ButtonSizes, ButtonTypes } from "./types";

import "./scss/index.scss";

export interface MSButtonProps {
    title?: string;
    className?: string;
    disabled?: boolean;
    type?: keyof typeof ButtonTypes;
    variant?: (typeof ButtonColors)[keyof typeof ButtonColors];
    size?: keyof typeof ButtonSizes;
    children?: string | JSX.Element;
    onClick: (e: MouseEvent | FormEvent<HTMLFormElement>) => void;
    href?: string;
    download?: boolean;
}

export const MSButton = React.forwardRef<HTMLButtonElement, MSButtonProps>((props, ref) => {
    const {
        title = "",
        className = "",
        disabled,
        type = ButtonTypes.default,
        variant = ButtonColors.green,
        size = ButtonSizes.md,
        children,
        onClick,
        href,
        download,
    } = props;

    return (
        <Button
            ref={ref}
            disabled={disabled}
            variant={variant}
            className={classNames("ms-button", size, type, className)}
            onClick={onClick}
            href={href}
            // @ts-ignore  have to ignore as react-bootstrap doesn't know we can pass it to href element
            download={download}
        >
            {children}
            {title && (
                <div
                    className={classNames({
                        indent: Boolean(children),
                    })}
                >
                    {title}
                </div>
            )}
        </Button>
    );
});

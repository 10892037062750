import React, {ReactNode, FC} from "react";
import {ReactNodeArray} from "prop-types";
import {Modal} from "react-bootstrap";

import "./scss/index.scss";
import {MSIcon} from "../MSIcon";

export interface MSModalProps {
    show?: boolean;
    onClose?: () => void;
    headerTitle?: string | ReactNode;
    body?: ReactNode | ReactNodeArray;
    footer?: ReactNode | ReactNodeArray;
    backdropClassName?: string
    dialogClassName?: string
    overlay?: boolean;
    backdrop?: boolean;
}

export const MSModal: FC<MSModalProps> = (props) => {
    const {show, onClose, headerTitle, body, footer, dialogClassName = "", backdropClassName = "", overlay = false, backdrop = true} = props;
    return <Modal backdrop={backdrop} dialogClassName={dialogClassName} show={show} onHide={onClose} backdropClassName={backdropClassName}>
        {
            headerTitle &&
            <div className="ms-modal-header">
                <span className="ms-modal-title">{headerTitle}</span>
                <span className="ms-modal-close" data-testid="modal-close" onClick={onClose}>
                    <MSIcon icon="times-circle" />
                </span>
            </div>
        }
        {
            body && <div className="ms-model-body">{body}</div>
        }
        {
            footer && <Modal.Footer>{footer}</Modal.Footer>
        }
        {overlay && <div className="overlay" />}
    </Modal>;
};

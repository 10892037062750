import classNames from "classnames";
import React, {FunctionComponent, ReactElement} from "react";
import FontAwesome from "react-fontawesome";

import "./scss/_MSIcon.scss";


export enum IconTypes {
    FA="fa",
    MS="ms",
    LOGO="logo"
}

export enum IconFolders {
    APPS="apps",
    UTILS="utils",
    WORKFLOW="workflow"
}

export interface MSIconProps extends React.HTMLAttributes<HTMLElement> {
    label?: string;
    icon: string;
    iconType?: IconTypes;
    iconFolder?: IconFolders;
}

export const MSIcon: FunctionComponent<MSIconProps> = (props: MSIconProps) => {
    const defaultIconType = "fa";
    const {icon, iconType = defaultIconType, iconFolder = "apps", className, ...passThroughProps} = props;

    // logos
    const createLogo = (): ReactElement => <span
        className={classNames("ms-icon ms-icon-logos", className, icon)}
        {...passThroughProps} />;

    const createMSIcon = (): ReactElement => <span
        className={classNames(`ms-icon ms-icon-${iconFolder}`, className, icon)}
        {...passThroughProps}
    />;

    // font-awesome icons
    const createFAIcon = (): ReactElement => <FontAwesome
        name={icon || ""}
        className={classNames("ms-icon", className)}
        {...passThroughProps}
    />;

    const iconMaps: Record<string, () => ReactElement> = {
        logo: createLogo,
        ms: createMSIcon,
        fa: createFAIcon
    };

    return iconMaps[iconType]();
};

import React, {FunctionComponent, useState} from "react";
import generatedGitInfo from "@medispend/unity/src/utils/git/generatedGitInfo.json";
import {MSModal} from "@medispend/common/src/components/MSModal";
import "@medispend/common/src/components/MSModal/scss/index.scss";
import "./scss/index.scss";
import {GlobalModalProps, Version} from "../../types";
import {BR_STORAGE_KEY, CFF_STORAGE_KEY, EM_STORAGE_KEY, WF_STORAGE_KEY} from "./constants";

export const MSVersionModal: FunctionComponent<GlobalModalProps> = (props) => {
    const {showModal, hideModal} = props;
    const [show, setShow] = useState(showModal);
    const {href} = window.location;

    const getFromLocalStorage = (key: string): Version => JSON.parse(localStorage.getItem(key));

    const versions: Version[] = [generatedGitInfo];

    const populateVersions = () => {
        if (href.includes(".em")) {
            versions.push(getFromLocalStorage(EM_STORAGE_KEY), getFromLocalStorage(WF_STORAGE_KEY));
        }

        if (href.includes(".cff")) {
            versions.push(getFromLocalStorage(CFF_STORAGE_KEY));
        }

        if (href.includes(".br")) {
            versions.push(getFromLocalStorage(BR_STORAGE_KEY));
        }

        return versions;
    };

    const headerTitle = "Version Info";

    const modalBody = populateVersions()?.map((version, index) => (
        <div className={`version-modal-body version-modal-body--${index}`} key={index}>
            <div className="version-modal-app">
                <span className="version-modal-key">Name:</span>
                <span className="version-modal-value">{version?.appName}</span>
            </div>
            <div className="version-modal-version">
                <span className="version-modal-key">Version:</span>
                <span className="version-modal-value">{version?.version}</span>
            </div>
            <div className="version-modal-build">
                <span className="version-modal-key">Build Time:</span>
                <span className="version-modal-value">{version?.buildTime}</span>
            </div>
            <div className="version-modal-commit">
                <span className="version-modal-key">Commit:</span>
                <span className="version-modal-value">{version?.gitCommit}</span>
            </div>
        </div>
    ));

    const modalFooter = <div className="version-modal-footer"></div>;

    const toggleCloseModal = () => {
        setShow(false);
        hideModal();
    };

    return (
        <>
            <MSModal
                show={show}
                headerTitle={headerTitle}
                body={modalBody}
                footer={modalFooter}
                onClose={toggleCloseModal}
                backdropClassName="modal-backdrop back-drop-fixed"
            />
        </>
    );
};

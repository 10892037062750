import {MSVersionModal} from "@medispend/common/src/components/MSVersionModal/index";
import React, {useState, createContext, useContext, FunctionComponent} from "react";

import {GlobalModalContext} from "../../types";
import {MODAL_TYPES} from "./constants";
import {MSConfirmationModal} from "./MSConfirmationModal";

const initState: GlobalModalContext = {
    showModal: () => {/**/},
    hideModal: () => {/**/},
    store: {}
};

const MODAL_COMPONENTS: any = {
    [MODAL_TYPES.CONFIRM_MODAL]: MSConfirmationModal,
    [MODAL_TYPES.VERSION_MODAL]: MSVersionModal
};

//TODO TAKE it to types
interface StoreProps {
    modalType: string;
    modalProps: any;
    customComponent?: FunctionComponent
}

const GlobalModalContext = createContext(initState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);


export const MSGlobalModal: React.FC = ({children}) => {
    const [store, setStore] = useState<StoreProps>();
    const {modalType, modalProps, customComponent} = store || {};

    const showModal = (modalType: string, modalProps: any = {}, customComponent?: FunctionComponent) => {
        setStore({
            ...store,
            modalType,
            modalProps,
            customComponent
        });
    };

    const hideModal = () => {
        setStore({
            ...store,
            modalType: null,
            customComponent: null,
            modalProps: {}
        });
    };

    const renderComponent = () => {
        const ModalComponent = (modalType === MODAL_TYPES.CUSTOM_MODAL ? customComponent : MODAL_COMPONENTS[modalType]);

        if (!modalType || !ModalComponent) {
            return null;
        }
        return <ModalComponent id="global-modal" {...modalProps} />;
    };
    return (
        <GlobalModalContext.Provider value={{store, showModal, hideModal}}>
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    );
};

import React, {useEffect} from "react";
import ReactDOM from "react-dom";

import {AdminLayout} from "@medispend/admin-common/src/AdminLayout";
import {AppsList} from "@medispend/admin-common/src/constants";

import {App} from "./App";

import "./scss/style.scss";
import {Auth0Config, Auth0options} from "@medispend/br/src/constants/settings";
import {AppWithAuth0} from "@medispend/common/src/authentication/auth0/components/AppWithAuth0";
import {MSGlobalModal} from "@medispend/common/src/components/MSGlobalModal";


const DashboardRoot = () => {
    useEffect(() => {
        document.title = "@medispend/br";
    }, []);
    return (<AppWithAuth0 auth0Config={Auth0Config}>
        <MSGlobalModal>
            <AdminLayout render={() => (<App />)} app={AppsList.BR} auth0options={Auth0options} />
        </MSGlobalModal>
    </AppWithAuth0>);
};

ReactDOM.render(<DashboardRoot />, document.getElementById("br-root"));

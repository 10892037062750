import { getRolesFromAuth0User } from "./auth0Util";
import { userHasAnyRole } from "@medispend/common/src/utils";
import {  PRODUCTS, USER_ROLE } from "../constants/adminconstants";
import { EM_LINK, TS_LINK } from "../env";
import { User } from "@auth0/auth0-react";

export const getAdminProducts = (user: User): Record<string, string>[] => {
    const userRoles = getRolesFromAuth0User(user);

    const isTsProductAvailable: boolean = userHasAnyRole([USER_ROLE.TS_SYSTEM_ADMIN, USER_ROLE.TS_USER], userRoles);
    const isEMProductAvailable: boolean = userHasAnyRole([USER_ROLE.EM_SYSTEM_ADMIN, USER_ROLE.EM_USER], userRoles);

    const products: Record<string, string>[] = [];
    if (isTsProductAvailable) {
        products.push({
            ...PRODUCTS.TS,
            link: TS_LINK
        });
    }
    if (isEMProductAvailable) {
        products.push({
            ...PRODUCTS.EM,
            link: EM_LINK
        });
    }
    return products;
}

import React, {FC, useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import * as _ from "lodash";

import {copyRule, getBusinessRulesMetadata, getRuleById, saveRule, updateRule} from "../../services/businessRules";
import {BusinessRuleCreate, FormValues} from "./BusinessRuleCreate";
import {BusinessRuleEdit} from "./BusinessRuleEdit";
import {formattedByIdOptions, formattedByNameOptions} from "../constants";
import {
    removeMedispendClient,
    ruleHeadersValid,
    setRuleHeaders,
    setRulesForSave,
    setRulesFromDmn
} from "../../helpers/businessRules";
import {BusinessRuleParams, ErrorPosition, Position, Rule, RuleType, Status} from "../../types";
import notification, {MessagesType} from "@medispend/common/src/components/MSNotifications";
import {
    ERROR_COLUMN_NOT_SELECTED,
    ERROR_INPUT_UNAVAILABLE,
    ERROR_UNEXPECTED,
    SUCCESS_RULE_CREATED,
    SUCCESS_RULE_EDITED,
    SUCCESS_RULE_EDITED_NOT_DRAFT
} from "./BusinessRuleEdit/constants";
import {ResponseState} from "@medispend/common/src/types";

export const BusinessRule: FC = () => {
    const [showCreateRule, setShowCreateRule] = useState(true);
    const [showEditRule, setShowEditRule] = useState(false);
    const [businessRuleCreateData, setBusinessRuleCreateData] = useState<FormValues>(null);
    const [metadata, setMetadata] = useState(null);
    const [editTopError, setEditTopError] = useState([]);
    const [editBotError, setEditBotError] = useState([]);
    const [isSaveTriggered, setIsSaveTriggered] = useState(false);
    const [isDraftEdit, setIsDraftEdit] = useState(true);
    const [uuid, setUuid] = useState("");
    const [isCopy, setIsCopy] = useState(false);
    const [isReadOnly, setReadOnly] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const isCopyRule = () => {
        return _.endsWith(location.pathname, "/copy");
    };
    const {id, clientName = "", ruleType = ""} = useParams<BusinessRuleParams>();

    useEffect(() => {
        if (id) {
            if (isCopyRule()) {
                //Set subdomain(client) to "medispend", because only Standard Rules can be copied
                getRuleById(+id, "medispend", "STANDARD").subscribe((response) => {
                    const newFormData = handleRuleLoading(response, "medispend");
                    setIsCopy(true);
                    newFormData.ruleType = RuleType.CUSTOM;
                    newFormData.client = "";
                    setBusinessRuleCreateData(newFormData);
                });
            } else {
                getRuleById(+id, clientName, ruleType).subscribe((response) => {
                    setBusinessRuleCreateData(handleRuleLoading(response, clientName));
                    const {ruleType, status} = response.data;
                    setReadOnly(clientName !== "medispend" && ruleType === "STANDARD" && _.includes(["ENABLED", "DISABLED"], status));
                });
            }
        }
    }, [id]);

    const handleRuleLoading = (response: ResponseState, clientName: string) => {
        const {
            name, effectiveDate, expiryDate, contextId,
            application, process, ruleType, status, dmnObject, description, uuid, version
        } = response.data;
        const rulesData = setRulesFromDmn(dmnObject);
        const ruleHeaders = setRuleHeaders(dmnObject);
        const versionRepresentation = _.split(version, ".");
        setUuid(uuid || "");
        setIsDraftEdit(status === Status.DRAFT || status === Status.TEST);
        return {
            contextId, ruleName: name, effectiveDate, expiryDate, ruleDescription: description,
            status, ruleType, application: application.id, process: process.id, rulesData, ruleHeaders,
            hitPolicy: dmnObject?.hitPolicy, client: clientName, additionalInputIds: dmnObject.additionalInputIds,
            version: Number(versionRepresentation[0]), minorVersion: Number(versionRepresentation[1])
        };
    };

    const setErrorMessage = (position: ErrorPosition, errorMessage: string) => {
        const newError = _.cloneDeep(position === Position.TOP ? editTopError : editBotError);
        !newError.some(error => error === errorMessage) && newError.push(errorMessage);
        position === Position.TOP ? setEditTopError(newError) : setEditBotError(newError);
    };

    const removeErrorMessage = (position: ErrorPosition, errorMessage: string) => {
        const newError = _.cloneDeep(position === Position.TOP ? editTopError : editBotError);
        const index = newError.findIndex(error => error === errorMessage);
        index !== -1 && newError.splice(index, 1);
        position === Position.TOP ? setEditTopError(newError) : setEditBotError(newError);
    };

    const clearError = (position: ErrorPosition) => {
        position === Position.TOP ? setEditTopError(prevError => prevError.filter(error => error === ERROR_INPUT_UNAVAILABLE)) : setEditBotError([]);
    };

    const isShowCreateRule = showCreateRule && !showEditRule;

    const isShowEditRule = showEditRule && !showCreateRule;

    useEffect(() => {
        getBusinessRulesMetadata().subscribe(response => {
            const data = response?.data;
            data.clients = removeMedispendClient(data.clients);
            setMetadata(response?.data);
        });
    }, [isCopy]);

    const onApprove = (data: FormValues) => {
        setBusinessRuleCreateData(data);
        setShowCreateRule(false);
        setShowEditRule(true);
    };

    const onGoBack = (rules: Rule[], ruleRows: Rule[][], hitPolicy: string, trigger: string) => {
        if (!clientName) {
            setBusinessRuleCreateData(prevData => ({
                ...{...prevData}, rulesData: ruleRows, ruleHeaders: rules, contextId: trigger, hitPolicy
            }));
        }
        setEditTopError([]);
        setEditBotError([]);
        setIsSaveTriggered(false);
        setShowCreateRule(true);
        setShowEditRule(false);
    };

    const onCancel = () => {
        history.push("/" + history.location.search);
    };

    const setError = (response: ResponseState, isEdit?: boolean, status?: Status) => {
        if (response?.error) {
            if (response?.error?.response?.data){
                setEditBotError(response?.error?.response?.data);
            }
            else {
                const message = response?.error?.response?.message;
                message ? setErrorMessage(Position.BOT, message) : setErrorMessage(Position.TOP, ERROR_UNEXPECTED);
            }
        }
        else {
            isEdit ? notification(status === Status.ENABLED || status === Status.DISABLED ? SUCCESS_RULE_EDITED_NOT_DRAFT : SUCCESS_RULE_EDITED, MessagesType.SUCCESS)
                : notification(SUCCESS_RULE_CREATED, MessagesType.SUCCESS);
            history.push("/" + history.location.search);
        }
    };


    const onSave = (formData: any) => {
        clearError(Position.BOT);
        setIsSaveTriggered(true);
        const {rules} = formData;
        const {status} = businessRuleCreateData;
        const selectedClient = businessRuleCreateData?.client || clientName;
        if (!ruleHeadersValid(rules) && status !== Status.DRAFT){
            setErrorMessage(Position.TOP, ERROR_COLUMN_NOT_SELECTED);
            return;
        }
        else {
            removeErrorMessage(Position.TOP, ERROR_COLUMN_NOT_SELECTED);
        }
        const newRule = setRulesForSave(formData, businessRuleCreateData, selectedClient, uuid);
        if (isCopy) {
            copyRule(newRule).subscribe((response) => {
                setError(response);
            });
            return;
        }
        if (id){
            updateRule(newRule, id).subscribe((response) => {
                setError(response, true, status as Status);
            });
            return;
        }
        if (newRule && newRule?.rule?.contextId){
            saveRule(newRule).subscribe((response) => {
                setError(response);
            });
        }
    };
    return (<div>
        {isShowCreateRule && <BusinessRuleCreate onCancel={onCancel}
            onApprove={onApprove}
            processes={formattedByIdOptions(metadata?.processes)}
            applications={formattedByIdOptions(metadata?.applications)}
            clients={formattedByNameOptions(metadata?.clients)}
            businessRuleCreateData={businessRuleCreateData}
            isEdit={isDraftEdit}
            isCopy={isCopy}
            isReadOnly={isReadOnly}
        />}
        {isShowEditRule && <BusinessRuleEdit onGoBack={onGoBack}
            onCancel={onCancel}
            onSave={onSave}
            businessRuleCreateData={businessRuleCreateData}
            isEdit={isDraftEdit}
            isCopy={isCopy}
            topError={editTopError}
            botError={editBotError}
            setErrorMessage={setErrorMessage}
            removeErrorMessage={removeErrorMessage}
            clearError={clearError}
            isSaveTriggered={isSaveTriggered}
            isReadOnly={isReadOnly}
        />}
    </div>);
};

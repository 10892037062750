import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useHistory} from "react-router";
import {forkJoin} from "rxjs";

import {MSTable} from "@medispend/common/src/components/MSTable";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {getDate, userHasAnyRole} from "@medispend/common/src/utils";
import {FilterItem, FilterOption, FilterParams, ResponseState} from "@medispend/common/src/types";
import {
    ADDITIONAL_FILTERS,
    DATE_FILTERS,
    DEFAULT_BR_FILTERS,
    DEFAULT_SORT,
    getTableFilters,
    INIT_BR_FILTERS,
    INIT_SORT,
    SEARCH_PARAMS
} from "./constants";
import {DEFAULT_PAGE_SIZE_OPTS, DEFAULT_PAGINATION} from "@medispend/common/src/constants";
import {getBusinessRulesMetadata, updateRuleStatus} from "../services/businessRules";
import {getBusinessRulesListData} from "../helpers/businessRules";
import {getAjax} from "../services";
import {BUSINESS_RULES_LIST_URL} from "../services/constants";
import {MultiSelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";
import {ScreenSort} from "@medispend/admin-common/src/types";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";

import "./scss/style.scss";
import {useGetVersionData} from "../hooks/useGetVersionData";
import {getBrColumns} from "./BusinessRulesTable";
import {RuleType, Status} from "../types";
import notification, {MessagesType} from "@medispend/common/src/components/MSNotifications";
import {ERROR_UNEXPECTED, SUCCESS_RULE_PREFIX} from "./BusinessRule/BusinessRuleEdit/constants";
import _ from "lodash";
import {getRolesFromAuth0User} from "@medispend/admin-common/src/utils/auth0Util";
import {USER_ROLE} from "../constants";
import {useAuth0} from "@auth0/auth0-react";
import {useTableQueryParams} from "../hooks/useTableQueryParams";

export const Dashboard: FunctionComponent = () => {
    useGetVersionData();
    const {search} = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [defaultFilters, setDefaultFilters] = useState([...INIT_BR_FILTERS, ...ADDITIONAL_FILTERS]);
    const [defaultSort, setDefaultSort] = useState(INIT_SORT);
    const {
        filtersValues,
        sort,
        pagination,
        searchText,
        queryString,
        handleFilterChange,
        handleFiltersClear,
        handleSortChange,
        handlePageChange,
        handlePageSizeChange,
        deleteFilter
    } = useTableQueryParams({
        defaultFilters,
        defaultSearchText: "",
        defaultSort,
        defaultPagination: DEFAULT_PAGINATION
    });

    const [total, setTotal] = useState(DEFAULT_PAGINATION.pageSize);
    const [isLoading, setIsLoading] = useState(false);
    const [businessRulesList, setBusinessRulesList] = useState([]);

    const [tableFilters, setTableFilters] = useState<FilterParams[]>(getTableFilters(null, query));
    const [initialFilters, setInitialFilters] = useState<FilterParams[]>(null);
    const [isDataUpdated, setIsDataUpdated] = useState(false);

    const history = useHistory();

    const {user} = useAuth0();
    const userRoles = getRolesFromAuth0User(user);

    useEffect(() => {
        setIsLoading(true);
        const requests = [getAjax(`${BUSINESS_RULES_LIST_URL}${queryString}`)];
        if (!initialFilters) {
            requests.push(getBusinessRulesMetadata());
        }
        const subscribtion = forkJoin(requests).subscribe((response: [brList: ResponseState, metadata: ResponseState]) => {
            const [brList, metadata] = response;
            setBusinessRulesList(getBusinessRulesListData(brList.data));
            setTotal(brList.data.page.totalElements);
            if (metadata) {
                const tableFilters = getTableFilters(metadata?.data, query);
                setTableFilters(tableFilters);
                setInitialFilters(tableFilters);
            }
            setIsLoading(false);
        });

        return function cleanup() {
            subscribtion.unsubscribe();
        };
    }, [queryString, isDataUpdated, initialFilters]);


    const onCreateRule = () => {
        history.push(`/rules` + history.location.search);
    };

    const tablePagination = useMemo(() => {
        const onPageChange = (page: number) => {
            handlePageChange(page);
        };
        return {...pagination, total, onPageChange};
    }, [pagination, total, handlePageChange]);

    const tablePageSize = useMemo(() => {
        const onPageSizeChange = (size: number) => {
            handlePageSizeChange(size);
        };

        return {pageSizeOptions: DEFAULT_PAGE_SIZE_OPTS.pageSizeOptions, pageSize: pagination.pageSize, onPageSizeChange};
    }, [pagination, handlePageSizeChange]);

    const onFilterClear = useCallback(() => {
        handleFiltersClear();
        setTableFilters(initialFilters);
        setDefaultFilters(DEFAULT_BR_FILTERS);
        setDefaultSort(DEFAULT_SORT);
    }, [initialFilters, handleFiltersClear]);

    const onFilterAdd = (filterData: MultiSelectOption[]) => {
        const [filters, deletedFilters] = _.partition(tableFilters, filter => !filter?.isAdditional || filterData.some((option) => option.value === filter.name));
        if (deletedFilters.length) {
            deleteFilter(deletedFilters[0].name);
        }
        const uniqFilter = filterData.filter(elem => !filters.some(data => data.name === elem.value));
        uniqFilter[0]?.value && filters.push(DATE_FILTERS[`${uniqFilter[0].value}`]);
        setTableFilters(filters);
    };

    const onToggleSort = useCallback((sortValue: ScreenSort) => {
        handleSortChange(sortValue);
    }, [handleSortChange]);

    const getFilterByName = (filterValue: any) => {
        switch (filterValue.name) {
            case "effectiveDate":
            case "expirationDate": {
                const [startDate, endDate] = filterValue.value;
                return {name: filterValue.name, value: [getDate(startDate.value), getDate(endDate.value)]};
            }
            default: {
                return filterValue;
            }
        }
    };

    const onFilterChange = useCallback((filterValue: FilterOption) => {
        const filter = getFilterByName(filterValue);
        handleFilterChange(filter);
    }, [handleFilterChange]);

    const onRuleChange = (id: number, clientName: string, ruleType: string, action?: string) => {
        let path = `/rules/${id}/${clientName}/${ruleType}`;
        if (action) {
            path = path + "/" + action;
        }
        history.push(path + history.location.search);
    };

    const onRuleCopy = (id: number) => {
        const path = `/rules/${id}/copy`;
        history.push(path + history.location.search);
    };

    const onRuleStatusChange = (id: number, subdomain: string, status: Status, ruleType: RuleType) => {
        updateRuleStatus(id, subdomain, status, ruleType).subscribe((response) => {
            if (response?.error) {
                notification(ERROR_UNEXPECTED, MessagesType.ERROR);
            } else {
                notification(SUCCESS_RULE_PREFIX + _.toLower(status), MessagesType.SUCCESS);
                isDataUpdated ? setIsDataUpdated(false) : setIsDataUpdated(true);
            }
        });
    };

    return (<div>
        <div className="header-wrapper">
            <h1 className="header">Business rules</h1>
            {isMedispendSubDom && userHasAnyRole([USER_ROLE.BR_MEDISPEND_ADMIN], userRoles) &&
                <MSButton onClick={onCreateRule} size="sm" className="create-rule-button">
                    <>
                        <span className="create-rule-label-icon" />
                        <span className="create-rule-label">Create Rule</span>
                    </>
                </MSButton>}
        </div>
        <MSTable
            columns={getBrColumns(sort, onToggleSort, onRuleChange, onRuleStatusChange, onRuleCopy, userRoles)}
            data={businessRulesList}
            showFooter
            showHeader
            showSearchFilter
            showPagination
            showFilters
            showSearchName
            onFilterChange={onFilterChange}
            searchType={SEARCH_PARAMS.defaultValue as FilterItem<string>}
            filtersValues={filtersValues}
            availableFilters={tableFilters}
            showSorting={false}
            pagination={tablePagination}
            pageSizeOpts={tablePageSize}
            onFilterAdd={onFilterAdd}
            onFilterClear={onFilterClear}
            loading={isLoading}
            additionalFilters={ADDITIONAL_FILTERS}
            clearSearchWithFilters
            searchValue={searchText}
        />
    </div>);
};

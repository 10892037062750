import {LAYOUT_LOADING_CONTENT, LAYOUT_LOADING_SIDENAV} from "./constants";
import {ReactElement} from "react";

export const LAYOUT_VIEW_MODE = "LAYOUT_VIEW_MODE";
export const LAYOUT_VIEW_MODE_TOGGLE = "LAYOUT_VIEW_MODE_TOGGLE";
export const LAYOUT_VIEW_COMPACT = "LAYOUT_VIEW_COMPACT";
export const LAYOUT_PROPS_UPDATE_DYNAMIC = "LAYOUT_PROPS_UPDATE_DYNAMIC";
export const LAYOUT_LOADING = "LAYOUT_LOADING";
export const LAYOUT_ALERT = "LAYOUT_ALERT";
export const LAYOUT_ALERT_ADD = "LAYOUT_ALERT_ADD";
export const LAYOUT_ALERT_CLEAR = "LAYOUT_ALERT_CLEAR";
export const LAYOUT_ALERT_CLEAR_ALL = "LAYOUT_ALERT_CLEAR_ALL";

export type LayoutAlertType = {
    options?: Record<string, string | number>;

    id: string;
    level: string;
    message: string | ReactElement;
}

export interface LayoutAlerts {
    [key: string]: LayoutAlertType;
}

export interface LayoutLoadingPayload {
    [LAYOUT_LOADING_CONTENT]?: boolean;
    [LAYOUT_LOADING_SIDENAV]?: boolean;
}

export interface ActionDef {
    id?: string;
    type: string;
    [LAYOUT_VIEW_COMPACT]?: boolean;
    payload?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type ActionHandler = () => void;

export interface ActionHandlerWithPayload {
    (payload: LayoutLoadingPayload | LayoutAlertType | string): void;
}

export const layoutViewFull = (): ActionDef => ({
    [LAYOUT_VIEW_COMPACT]: false,
    type: LAYOUT_VIEW_MODE
});

export const layoutViewCompact = (): ActionDef => ({
    [LAYOUT_VIEW_COMPACT]: true,
    type: LAYOUT_VIEW_MODE
});

export const layoutViewToggle = (): ActionDef => ({
    type: LAYOUT_VIEW_MODE_TOGGLE
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const layoutPropsUpdateDynamic = (payload: any): ActionDef => ({
    payload,
    type: LAYOUT_PROPS_UPDATE_DYNAMIC
});

export const layoutSetLoading = (payload: LayoutLoadingPayload): ActionDef => ({
    payload,
    type: LAYOUT_LOADING
});

export const layoutAlertAdd = (payload: LayoutAlertType): ActionDef => ({
    payload,
    type: LAYOUT_ALERT_ADD
});

export const layoutAlertClear = (payload: string): ActionDef => {
    return {
        payload,
        type: LAYOUT_ALERT_CLEAR
    };
};

export const layoutAlertClearAll = (): ActionDef => {
    return {
        type: LAYOUT_ALERT_CLEAR_ALL
    };
};

import React, {MouseEvent, useCallback, useRef, useState} from "react";
import classNames from "classnames";

import {MSButton} from "../MSButton";
import {DatepickerOptions} from "../../types";
import {MSDatepicker} from "./index";
import {IconFolders, IconTypes, MSIcon} from "../MSIcon";
import {useOnClickOutside} from "../hooks/clickOutside";
import {ButtonColors, ButtonSizes, ButtonTypes} from "../MSButton/types";
import {MultiSelectOption} from "../MSSelect/BaseSelect/types";
import {getDateStringFromTimestamp} from "../../utils";

import "./scss/RangeDatepicker.scss";


export interface MSRangepickerProps {
    options: DatepickerOptions[]
    size: ButtonSizes,
    buttonType: ButtonTypes,
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    title?: string;
    onChange: (optionData: MultiSelectOption[]) => void;
}
type EventType = MouseEvent<Element, globalThis.MouseEvent>

export const RangePicker = (props: MSRangepickerProps): JSX.Element => {
    const {options, size = ButtonSizes.md,
        buttonType = ButtonTypes.pill, variant = ButtonColors.grey200,
        activeVariant = ButtonColors.grey800, title = "", onChange} = props;
    const modalRef: React.RefObject<HTMLDivElement> = useRef();
    const [initStartDate, initEndDate] = options;
    const [startDate, setStartDate] = useState(initStartDate);
    const [endDate, setEndDate] = useState(initEndDate);
    const [show, setShow] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    useOnClickOutside(modalRef, () => closeModal());

    const closeModal = useCallback(() => {
        setShow(false);
    }, [show]);

    const SelectButton = ({onClick, startDate, endDate}: {onClick: (e: EventType) => void, startDate: number, endDate: number}) => {
        const formatDate = () => {
            const getDate = (date: number) => date ? getDateStringFromTimestamp(date) : "--/--/--";
            return `${title}: ${getDate(+ startDate)} - ${getDate(+ endDate)}`;
        };

        return (
            <div>
                <MSButton
                    size={size}
                    type={buttonType}
                    variant={(selectedStartDate || selectedEndDate) ? activeVariant : variant }
                    className="ms-select-button"
                    onClick={(e: EventType) => {
                        e.preventDefault();
                        onClick(e);
                    }}>
                    <div className="ms-button-title">
                        {formatDate()}
                        <MSIcon
                            icon="caret-down"
                            iconType={IconTypes.MS}
                            iconFolder={IconFolders.UTILS}
                            className={classNames("ms-caret-down", {
                                active: (selectedStartDate || selectedEndDate)
                            })}
                        />
                    </div>
                </MSButton>
            </div>
        );
    };

    const setStartDateObject = (date: number) => {
        if (date >= endDate.value) {
            setEndDate({...endDate, value: null});
        }
        setStartDate({...startDate, value: date});
    };

    const setEndDateObject = (date: number) => {
        setEndDate({...endDate, value: date});
    };

    const onApprove = () => {
        setSelectedStartDate(+ startDate.value);
        setSelectedEndDate(+ endDate.value);
        onChange([{value: startDate.value, name: initStartDate.name}, {value: endDate.value, name: initEndDate.name}]);
        setShow(false);
    };

    const onCancel = () => {
        setShow(false);
    };

    return (
        <div className="ms-range-picker-container" ref={modalRef}>
            <SelectButton onClick={() => setShow(!show)}
                startDate={selectedStartDate}
                endDate={selectedEndDate} />
            {show && <div className="ms-range-picker">
                <div className="datepicker-fields">
                    <MSDatepicker onChange={setStartDateObject} options={startDate} /> - <MSDatepicker
                        onChange={setEndDateObject} options={endDate}
                        startLimit={startDate.value} />
                </div>
                <div className="datepicker-footer">
                    <MSButton onClick={onCancel} variant={ButtonColors.grey100} className="cancel-button">Cancel</MSButton>
                    <MSButton onClick={onApprove}>Apply</MSButton>
                </div>
            </div>}
        </div>
    );
};

/**
 * Return a random number
 *
 * @param min
 * @param max
 * @param _amount
 * @param _unique
 * @return {number}
 */

const createRandom = (min: number, max: number, _amount?: any, _unique?: boolean): any => {
    const randomSeed = (_min: number, _max: number) => Math.floor(Math.random() * (_max - _min + 1)) + _min;
    const unique = typeof (_unique) === "boolean" ? _unique : true;

    let amount = 1;
    if (Array.isArray(_amount) && _amount.length === 2) {
        amount = randomSeed(_amount[0] > 1 ? _amount[0] : 2, _amount[1]);
    } else if (_amount) {
        amount = _amount;
    }

    const results: Array<number> = [];
    while (results.length < amount) {
        const num = randomSeed(min, max);
        if ((unique && results.indexOf(num) === -1) || !unique) {
            results.push(num);
        }
    }
    return amount === 1 ? results[0] : results;
};

const random = (min: number, max: number): number => createRandom(min, max);
const randomGroup = (min: number, max: number, _amount?: any, _unique?: boolean): Array<number> =>
    createRandom(min, max, _amount, _unique);

export {
    random,
    randomGroup
};

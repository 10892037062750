import React, {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";
import {Location} from "history";

import {IconTypes, MSIcon} from "../MSIcon";
import {LayoutLoadingPayload} from "./state";
import {User} from "../../types";
import {UserToggleDropdownBlock, UserToggleDropdownOption} from "./types";
import { useGlobalModalContext } from "../MSGlobalModal";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";
import { getTranslationText } from "@medispend/em-common/src/utils";
import { TRANSLATION_KEYS } from "@medispend/em-common/src/constants";

export interface LayoutHeaderProps {
    brand?: string;
    products?: Record<string, string | ReactElement>[];
    helps?: Record<string, string | ReactElement>[];
    helpTitle?: string;
    logo?: string;
    user?: User;
    defaultLanguage?: string;
    onLanguageChange?: (language: string) => void;
    languages?: Record<string, string>[];
    userToggleBlocks?: UserToggleDropdownBlock[];

    compactView?: boolean;
    layoutLoading?: LayoutLoadingPayload;
    location?: Location;
    onViewModeCompact?: () => void;
    onViewModeFull?: () => void;
    viewModeCompact?: () => void;
    viewModeFull?: () => void;
}

export const LayoutHeader: FunctionComponent<PropsWithChildren<LayoutHeaderProps>> = (props) => {
    const {
        brand = "Engagement Manager",
        logo = "../../../media/img/logos/medispend-em.svg",
        defaultLanguage = "en",
        onLanguageChange = () => {},
        languages = [
            {
                name: "English",
                lang: "en"
            },
            {
                name: "Français",
                lang: "fr"
            },
            {
                name: "Deutsch",
                lang: "de"
            }
        ],
        products = [],
        helps = [],
        helpTitle = "Help",
        user = {} as User,
        userToggleBlocks = []
    } = props;

    const [language, setLanguage] = useState<string>(defaultLanguage);
    useEffect(() => {
        setLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const languagesByName: Record<string, string> = {};
    languages.forEach((lng) => {
        languagesByName[lng.lang] = lng.name;
    });

    const switchLanguage = (lang: string) => {
        onLanguageChange(lang);
        setLanguage(lang);
    };

    const {showModal: showGlobalModal, hideModal: hideGlobalModal} = useGlobalModalContext();

    const ProductMenuToggle = () => (
        <img src="../../../media/img/icons/apps.svg" alt="product-menu-toggle" width={20} height={20} />
    );

    const LanguageToggle = () => (
        <span data-testid="language-icon">
            <MSIcon icon="globe" /> {languagesByName[language]}
        </span>
    );

    const UserToggle = () => {
        // TODO: check to see if we ever need avatar/image for user
        const hasName = user?.firstName || user?.lastName;
        const displayName = hasName ? (user?.firstName + (user?.lastName ? " " + user.lastName : "")) : " ";
        const userImage = hasName ? (
            <span className="avatar">
                {(user.firstName ?? "")[0].toUpperCase()}{(user.lastName ?? "")[0].toUpperCase()}
            </span>
        ) : <span className="fa fa-user"/>;

        return (
            <span data-testid="user-title" className="ms-header-user-toggle">
                {userImage} {displayName}
            </span>
        );
    };

    const userToggleDropdown = (block: UserToggleDropdownBlock, index: number) => {
        return (<div key={block.name}>
            <div>
                {block.name !== "LOGOUT" ? <span className="user-toggle-block">{block.name}</span> : null}
                {block.options.map((option: UserToggleDropdownOption) => {
                    return <NavDropdown.Item key={option.title}
                        href={option.path} onClick={(e) => {
                            if (option.action) {
                                e.preventDefault();
                                option.action();
                            }
                        }
                        }>
                        {option.icon} {option.title}
                    </NavDropdown.Item>;
                })}
            </div>
            {((userToggleBlocks.length - 1) > index) ? <NavDropdown.Divider /> : null}
        </div>);
    };

    const modalToggle = () => {
        showGlobalModal(MODAL_TYPES.VERSION_MODAL, {
            showModal: true,
            hideModal: hideGlobalModal
        });
    };

    return (
        <div data-testid="layout-header" className="ms-header">
            <Navbar>
                <Nav className="ms-header-left-nav">
                    {!!products.length && (
                        <NavDropdown
                            id="ms-header-products-list"
                            title={<ProductMenuToggle />}
                        >
                            {products.map(({link, name}, idx) => (
                                <NavDropdown.Item key={idx} href={link as string}>{name}</NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    )}
                    <Navbar.Brand>
                        <Link to="/" title={brand} className="nav-link">
                            <img src={logo} alt={brand} />
                        </Link>
                    </Navbar.Brand>
                </Nav>
                <Nav className="ms-header-right-nav">
                    <NavDropdown
                        id="ms-header-help-toggle"
                        title={<><img src="../../../media/img/favicon/menu-question.png" alt="help-icon" /> {helpTitle}</>}
                    >
                        {
                            helps.map(({name, path}, idx) => (
                                <NavDropdown.Item key={idx} href={path as string} target="_blank">
                                    <MSIcon icon="book" /> {name}
                                </NavDropdown.Item>
                            ))
                        }
                        <NavDropdown.Item onClick={modalToggle} className="ms-header-help-about">
                            <MSIcon icon="info" iconType={IconTypes.FA} className="fa-info-circle ms-header-help-about-icon" />
                            <span className="ms-header-help-about-label">
                                {getTranslationText(TRANSLATION_KEYS.NAVIGATION_HELP, null, "Help About")}
                            </span>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                        id="ms-header-language-toggle"
                        title={<LanguageToggle />}
                    >
                        {languages.map(({lang, name}, idx) => (
                            <NavDropdown.Item key={idx} href="" onClick={() => switchLanguage(lang)}>
                                <MSIcon icon={language === lang ? "check-circle-o" : "circle-o"} /> {name}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                    <NavDropdown
                        id="ms-header-user-toggle"
                        title={<UserToggle />}
                    >
                        {userToggleBlocks.length > 0 && userToggleBlocks.map(userToggleDropdown)}
                    </NavDropdown>
                </Nav>
            </Navbar>
        </div>
    );
};

import {EM_URL, isLocalMode, LOCAL_CFF_URL, LOCAL_BR_URL, LOCATION, TS_URL, BUILD_ENV, LOCAL_GLOBAL_ADMIN_URL} from "./constants";

const hostname = window.location.hostname;
export const subdomain = hostname.split(".")[0];

export const isMedispendSubDom = subdomain === "medispend";

export const locationPart = `${LOCATION ? LOCATION + "." : ""}`;

const getUrlParts = (project: string): {projectPart: string} => {
    const isBrLocal = project === "br" && subdomain === "localhost";
    const projectPart = `https://${isBrLocal ? "medispend" : subdomain}.${project}`;
    return {projectPart};
};

export const getBaseUrl = (project: string): string => {
    const {projectPart} = getUrlParts(project);

    const apiPart = `${projectPart}-api.${locationPart}medispend.com`;
    let result = "";

    if (!isLocalMode) {
        result = apiPart;
    } else {
        const urls: {[key: string]: string} = {
            br: LOCAL_BR_URL,
            cff: LOCAL_CFF_URL,
            ["global-admin"]: LOCAL_GLOBAL_ADMIN_URL
        };

        result = urls[project] || "";
    }

    console.log("getBaseUrl(", project, ") - result: ", result);

    return result;
};

const getLinkToApp = (project: string): string => {
    const {projectPart} = getUrlParts(project);

    let fullLink = `${projectPart}.${locationPart}medispend.com`;

    //Below is a hack for only IMP when we create TS and EM URL which needs to be replaced in future
    if (BUILD_ENV === "imp" && (project === "ts" || project === "em")) {
        fullLink = `${projectPart}.imp.medispend.com`;
    }

    if (project === "ts") {
        fullLink = fullLink.replace(".ts", "");
    }

    return fullLink;
};

const getLocalLinkToApp = (url: string): string => url?.includes(subdomain) ? url : url?.replace("medispend", subdomain);

export const CFF_LINK = isLocalMode ? LOCAL_CFF_URL : getLinkToApp("cff");
export const BR_LINK = isLocalMode ? LOCAL_BR_URL : getLinkToApp("br");
export const GLOBAL_ADMIN_LINK = isLocalMode ? LOCAL_GLOBAL_ADMIN_URL : getLinkToApp("global-admin");
export const GA_CURRENCY_CONVERSION_PATHNAME = "currency-conversion-rate";
export const GLOBAL_ADMIN_CURRENCY_CONVERSION_RATE_LINK = isLocalMode
    ? GLOBAL_ADMIN_LINK + GA_CURRENCY_CONVERSION_PATHNAME
    : GLOBAL_ADMIN_LINK + `/#/${GA_CURRENCY_CONVERSION_PATHNAME}`;

export const GA_CLIENT_MAPPING_PATHNAME = "client-mapping";
export const GLOBAL_ADMIN_CLIENT_MAPPING_LINK = isLocalMode
    ? GLOBAL_ADMIN_LINK + GA_CLIENT_MAPPING_PATHNAME
    : GLOBAL_ADMIN_LINK + `/#/${GA_CLIENT_MAPPING_PATHNAME}`;

export const TS_LINK = isLocalMode ? getLocalLinkToApp(TS_URL) : getLinkToApp("ts");
export const EM_LINK = isLocalMode ? getLocalLinkToApp(EM_URL) : getLinkToApp("em");

console.log("env.ts() - isLocalMode: ", isLocalMode, ", BR_LINK: ", BR_LINK, ", TS_LINK: ", TS_LINK, ", EM_LINK: ", EM_LINK);

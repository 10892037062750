import {mergeMap, Observable, of} from "rxjs";

import {ResponseState} from "@medispend/common/src/types";
import {createUpdateAjax, getAjax} from "./ajax";
import {BUSINESS_RULES_LIST_URL, BUSINESS_RULES_METADATA_URL, BUSINESS_RULES_TRIGGERS_URL} from "./constants";
import {RuleObject, RuleType, Status} from "../types";
import {isMedispendSubDom, subdomain} from "@medispend/admin-common/src/env";


export const getBusinessRulesList = (url: string): Observable<ResponseState> => {
    return getAjax(`${url}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        }));
};

export const getBusinessRulesMetadata = (): Observable<ResponseState> => {
    return getAjax(`${BUSINESS_RULES_METADATA_URL}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        }));
};

export const getTriggersData = (processId: string | number): Observable<ResponseState> => {
    return getAjax(`${BUSINESS_RULES_TRIGGERS_URL}/process/${processId}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        }));
};

export const getTriggersContextData = (id: number, client: string): Observable<ResponseState> => {
    const clientParameter = client ? `?client=${client}` : "";
    return getAjax(`${BUSINESS_RULES_TRIGGERS_URL}/${id}${clientParameter}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        }));
};

export const getRuleById = (id: number, name: string, type: string = null): Observable<ResponseState> => {
    const isClientSubdomain = name.toLowerCase() === subdomain.toLowerCase();
    const clientNameParameter = isMedispendSubDom && isClientSubdomain ? "" : `?clientName=${name}`;
    const parameterSign = clientNameParameter.trim().length > 0 ? "&" : "?";
    const ruleTypeParameter = type ? `${parameterSign}ruleType=${type}` : "";
    return getAjax(`${BUSINESS_RULES_LIST_URL}/${id}${clientNameParameter}${ruleTypeParameter}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        })
    );
};

export const saveRule = (rule: RuleObject): Observable<ResponseState> => {
    return createUpdateAjax("post", `${BUSINESS_RULES_LIST_URL}`, rule, false).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        })
    );
};

export const updateRule = (rule: RuleObject, id: string): Observable<ResponseState> => {
    return createUpdateAjax("put", `${BUSINESS_RULES_LIST_URL}/${id}`, rule, false).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        })
    );
};

export const copyRule = (rule: RuleObject): Observable<ResponseState> => {
    return createUpdateAjax("post", `${BUSINESS_RULES_LIST_URL}/copy`, rule, false).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        })
    );
};

export const updateRuleStatus = (id: number, client: string, status: Status, ruleType: RuleType): Observable<ResponseState> => {
    return createUpdateAjax("patch",
        `${BUSINESS_RULES_LIST_URL}/${id}/${client}?status=${status.toUpperCase()}&ruleType=${ruleType.toUpperCase()}`).pipe(
        mergeMap((response: ResponseState) => {
            return of(response);
        })
    );
};

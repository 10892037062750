import classNames from "classnames";
import React, {FunctionComponent, ReactNode} from "react";
import Button from "react-bootstrap/Button";
import * as UltimatePagination from "ultimate-pagination";

import {hasPageSize, PageSize, PageSizeProps} from "./PageSize";

export interface PaginationProps {
    total: number;
    page: number;
    pageSize: number;

    pageSizeOpts?: PageSizeProps;
    onPageChange: (page: number) => void;
    recordLabel?: string;
    ofLabel?: string;
    showingLabel?: string;
    mode?: "COMPACT" | "FULL" | string;
}

const calculatePages = (total: number, pageSize: number): number => {
    return total % pageSize === 0 ? (total / pageSize) : (Math.floor(total / pageSize) + 1);
};

const getSafePage = (page: number, total: number) => {
    if (Number.isNaN(page)) {
        return 0;
    }

    if (page < 0) {
        return 0;
    }
    if (page > total) {
        return total;
    }

    return page;
};

export const Pagination: FunctionComponent<PaginationProps> = (props) => {
    const {
        ofLabel = "of",
        onPageChange,
        page,
        pageSize,
        pageSizeOpts: {
            pageSizeOptions,
            pageSize: pageListSize,
            onPageSizeChange
        },
        pageSizeOpts,
        recordLabel = "entries",
        showingLabel = "Showing",
        total,
        mode = "FULL"
    } = props;

    const hasPageSizeOpts = hasPageSize(pageSizeOpts);
    const pages = calculatePages(total, pageSize);
    const canNext = (page + 1) < pages;
    const canPrevious = page > 0;
    const isFirst: boolean = page === 0;
    const isLast: boolean = page === pages - 1;
    const totalRecords = total;
    const currentFromRecords = isFirst ? 1 : (pageSize * page) + 1;
    const currentRecords = isLast ? total : pageSize * (page + 1);
    const isFullMode = mode === "FULL";

    const changePage = (currentPage: number) => {
        const safePage = getSafePage(currentPage, total);
        if (safePage !== page) {
            onPageChange && onPageChange(safePage);
        }
    };

    const paginationItems: ReactNode[] = [];
    UltimatePagination.getPaginationModel({
        boundaryPagesRange: 1,
        currentPage: Math.min(page, pages),
        siblingPagesRange: 2,
        totalPages: pages || 0
    }).forEach((item: any, idx: number) => {
        const isCurrentPage = page === (item.value - 1);
        switch (item.type) {
            case UltimatePagination.ITEM_TYPES.PREVIOUS_PAGE_LINK:
                paginationItems.push(
                    <Button
                        key="prev-page"
                        className="ms-table-prev-next"
                        disabled={!canPrevious}
                        variant="light"
                        onClick={(): void => canPrevious && changePage(page - 1)}
                    >
                        <img src="../../../media/img/icons/backward.svg" width={10} height={11} alt="Previous" />
                    </Button>
                );
                break;
            case UltimatePagination.ITEM_TYPES.ELLIPSIS:
                paginationItems.push(
                    <Button
                        key={`ellipsis${idx}`}
                        className="ms-table-pagination-ellipsis ms-table-compact"
                        disabled={!canPrevious}
                        variant="light"
                        onClick={(): void => changePage(item.value)}
                    >
                        ...
                    </Button>
                );
                break;
            case UltimatePagination.ITEM_TYPES.PAGE:
                paginationItems.push(
                    <Button
                        key={`page-${item.value}`}
                        className={classNames({"ms-table-pagination-current": isCurrentPage})}
                        active={isCurrentPage}
                        variant="light"
                        onClick={(): void => isCurrentPage ? null : changePage(item.value - 1)}
                    >
                        {item.value}
                    </Button>
                );
                break;
            case UltimatePagination.ITEM_TYPES.NEXT_PAGE_LINK:
                paginationItems.push(
                    <Button
                        key="next-page"
                        className="ms-table-prev-next"
                        disabled={!canNext}
                        variant="light"
                        onClick={(): void => canNext && changePage(page + 1)}
                    >
                        <img src="../../../media/img/icons/forward.svg" width={10} height={11} alt="Next" />
                    </Button>
                );
                break;
        }
    });

    return (
        <div className={classNames("ms-table-pagination-container", {"has-page-size-opts": hasPageSizeOpts})}>
            {isFullMode && (
                <div className="ms-table-pagination-metadata">
                    {showingLabel} {currentFromRecords} - {currentRecords} {ofLabel} {totalRecords} {recordLabel}
                </div>
            )}
            {isFullMode && hasPageSizeOpts && <PageSize pageSizeOptions={pageSizeOptions}
                pageSize={pageListSize}
                onPageSizeChange={onPageSizeChange}
                showLabel={pageSizeOpts.showLabel}
                rowsLabel={pageSizeOpts.rowsLabel}
            />}
            <div className="ms-table-pagination">
                {paginationItems}
            </div>
        </div>
    );
};

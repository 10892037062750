import {ScreenSort} from "@medispend/admin-common/src/types";
import {Column} from "react-table";
import Dropdown from "react-bootstrap/Dropdown";

import {TableHeader} from "@medispend/admin-common/src/components/TableHeader";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import React from "react";
import {RULE_SETTINGS_OPTIONS} from "./constants";
import {DropdownOption} from "@medispend/common/src/types";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";
import * as _ from "lodash";
import {RuleType, Status} from "../types";
import {userHasAnyRole} from "@medispend/common/src/utils";
import {USER_ROLE} from "../constants";

export const getBrColumns = (sortOptions: ScreenSort,
    setSort: (sortOptions: ScreenSort) => void,
    onRuleChange: (id: number, name: string, type: string, action?: string) => void,
    onRuleStatusChange: (id: number, subdomain: string, status: Status, ruleType: RuleType) => void,
    onRuleCopy: (id: number) => void,
    userRoles:Array<string>): Array<Column<Record<string, unknown>>> => [
    {
        id: "clientName",
        Header: <TableHeader columnName="clientName" label="Client" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "clientName"
    },
    {
        id: "ruleType",
        Header: <TableHeader columnName="ruleType" label="Rule Type" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "ruleType"
    },
    {
        id: "name",
        Header: <TableHeader columnName="name" label="Rule Name" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "name"
    },
    {
        id: "status",
        Header: <TableHeader columnName="status" label="Status" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "status"
    },
    {
        id: "process",
        Header: <TableHeader columnName="process" label="Process" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "process"
    },
    {
        id: "effectiveDate",
        Header: <TableHeader columnName="effectiveDate" label="Effective Date" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "effectiveDate"
    },
    {
        id: "expiryDate",
        Header: <TableHeader columnName="expiryDate" label="Expiration Date" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "expiryDate"
    },
    {
        id: "id",
        Header: <TableHeader columnName="uuid" label="Rule ID" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "uuid"
    },
    {
        id: "version",
        Header: <TableHeader columnName="version" label="Version" sortOptions={sortOptions} setSort={setSort} isTooltipVisible={false} />,
        accessor: "version"
    },
    {
        id: "checkbox-table-column",
        width: 5,
        Cell: function getButton(cell: any): JSX.Element {
            const {row: {original}} = cell;
            const {id, client, status, ruleType} = original;
            const filteredOptions = RULE_SETTINGS_OPTIONS.filter(element => (isOptionVisible(status, ruleType, element, userRoles)));
            return (<Dropdown className="ellipsis-dropdown" drop="down">
                <Dropdown.Toggle>
                    <div className="row-options">
                        <MSIcon icon="fas fa-ellipsis-v" />
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>{
                    filteredOptions.map((option, index) => {
                        switch (option.value) {
                            case "VIEW": {
                                return (<Dropdown.Item key={`dropdown-${index}`}
                                    onClick={() => onRuleChange(id, client.name, ruleType.toUpperCase())}>{option.name}</Dropdown.Item>);
                            }
                            case "COPY" : {
                                return (<Dropdown.Item key={`dropdown-${index}`}
                                    onClick={() => onRuleCopy(id)}>{option.name}</Dropdown.Item>);
                            }
                            case "ENABLE" : {
                                return (<Dropdown.Item key={`dropdown-${index}`}
                                    onClick={() => onRuleStatusChange(id, client.name, Status.ENABLED, ruleType)}>{option.name}</Dropdown.Item>);
                            }
                            case "DISABLE" : {
                                return (<Dropdown.Item key={`dropdown-${index}`}
                                    onClick={() => onRuleStatusChange(id, client.name, Status.DISABLED, ruleType)}>{option.name}</Dropdown.Item>);
                            }
                            default: {
                                return (<Dropdown.Item key={`dropdown-${index}`}>{option.name}</Dropdown.Item>);
                            }
                        }
                    })
                }
                </Dropdown.Menu>
            </Dropdown>);
        }
    }];

const isOptionVisible = (status: string, ruleType: string, option: DropdownOption, userRoles: Array<string>) => {
    switch (option.value) {
        case "VIEW": {
            return true;
        }
        case "COPY": {
            return isMedispendSubDom && userHasAnyRole([USER_ROLE.BR_MEDISPEND_ADMIN], userRoles) && ruleType === "Standard" && _.includes(["Enabled", "Disabled"], status);
        }
        case "ENABLE": {
            return isMedispendSubDom && userHasAnyRole([USER_ROLE.BR_MEDISPEND_ADMIN], userRoles) && _.includes(["Test", "Disabled"], status);
        }
        case "DISABLE": {
            return isMedispendSubDom && userHasAnyRole([USER_ROLE.BR_MEDISPEND_ADMIN], userRoles) && _.includes(["Test", "Enabled"], status);
        }
        default: {
            return false;
        }
    }
};

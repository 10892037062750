import React, {FunctionComponent, ReactNode} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface LayoutBreadcrumbItemProps {
    label: string | ReactNode;
    active?: boolean;
    link?: string;
    action?: string;
}

interface LayoutBreadcrumbsProps {
    items: LayoutBreadcrumbItemProps[];
    home?: boolean;
    homeTitle?: string;
}

export const LayoutBreadcrumbs: FunctionComponent<LayoutBreadcrumbsProps> = (props) => {
    const {items = [], home = true, homeTitle = "Home"} = props;
    const divider = <li className="divider">
        <img src="../../../media/img/icons/forward.svg" width={10} height={10} alt="" />
    </li>;
    const renderBreadcrumb = ({label, active, link}: LayoutBreadcrumbItemProps, idx?: number) => (
        <React.Fragment key={link}>
            <li className={classNames({active})}>
                <Link to={link} title={typeof label === "string" ? label : ""}>{label}</Link>
            </li>
            {idx < (items.length - 1) ? (
                divider
            ) : null}
        </React.Fragment>
    );

    return Array.isArray(items) && items.length ? (
        <ul className="ms-layout-breadcrumbs">
            {home && renderBreadcrumb({
                label: homeTitle,
                active: false,
                link: "/"
            })}
            {divider}
            {items.map(renderBreadcrumb)}
        </ul>
    ) : null;
};

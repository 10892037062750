import React, {ChangeEvent, FC} from "react";

import {getDateStringFromValue} from "../../utils";
import {MSIcon} from "../MSIcon";

import "./scss/DateField.scss";


export interface DateField {
    onClick: () => void,
    value: number | string,
    uiLabel: string,
    onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void,
    name?: string,
    isDisabled: boolean
}

export const DateField: FC<DateField> = (props) => {
    const {onClick, value, uiLabel, onChangeValue, onClear, name, isDisabled} = props;
    const dateString = getDateStringFromValue(value);
    return (
        <div className="date-field">
            <input
                data-testid="search-input"
                type="text"
                className="form-control"
                name={name}
                placeholder={uiLabel}
                onChange={onChangeValue}
                value={dateString}
                onClick={onClick}
                disabled={isDisabled}
            />
            {dateString && (<div className="ms-sort-clear" onClick={onClear}>
                <MSIcon icon="far fa-times" />
            </div>)}
        </div>);
};
